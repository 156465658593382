import db from "../Db";
import { addTableWithProgressForPhotoconc, handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import b64toBlob from "b64-to-blob";
import { GetPhotoconcById } from "../Get/Photoconc";
import LoaderState from "./../../Components/Global/Object/LoaderState";

var _ = require("lodash");

export async function UpdatePhotoconc_MDF(headers, _change_loader_state) {
  const _photoconcCount = await GetApiData("PHOTOCONCCount", headers);

  if (_photoconcCount.Message !== undefined) {
    return _photoconcCount;
  }

  LoaderState.setNombreTotalPhotos(_photoconcCount);
  _change_loader_state();

  const promise = new Promise((res, rej) => {
    if (_photoconcCount === 0) res("");

    addTableWithProgressForPhotoconc("PHOTOCONC", _photoconcCount, 50, headers, (getProgress, getPhotoconc) => {
      if (getPhotoconc.Message !== undefined) {
        return res(getPhotoconc);
      }

      _.forEach(getPhotoconc, (photoconc) => {
        var blob = "";
        if (photoconc.PHOTO !== "") blob = b64toBlob(photoconc.PHOTO, "image/png");

        const photoconc_a_mettre_a_jour = {
          IDPHOTOCONC: photoconc.IDPHOTOCONC,
          PHOTO: blob,
          DATEAJOUT: photoconc.DATEAJOUT,
          PRINCIPAL: photoconc.PRINCIPAL ? 1 : 0,
          COCLEUNIK: photoconc.COCLEUNIK,
          NOTES: photoconc.NOTES,
          I0CLEUNIK: photoconc.I0CLEUNIK,
          SEMELLE_ETAT: photoconc.SEMELLE_ETAT,
          MONUMENT_ETAT: photoconc.MONUMENT_ETAT,
          CONSTAT_TRAVAUX: photoconc.CONSTAT_TRAVAUX,
          ABANDON_1: photoconc.ABANDON_1,
          ABANDON_2: photoconc.ABANDON_2,
        };

        GetPhotoconcById(photoconc_a_mettre_a_jour.IDPHOTOCONC).then((exist) => {
          if (exist === undefined) {
            db.open()
              .then(() => {
                db.PHOTOCONC.add(photoconc_a_mettre_a_jour)
                  .catch((err) => {
                    rej(handleError(err));
                  })
                  .then(() => {
                    LoaderState.setNombrePhotos(getProgress < _photoconcCount ? getProgress : _photoconcCount);
                    _change_loader_state();
                  });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          } else {
            _.set(photoconc_a_mettre_a_jour, "id", exist.id);
            if (exist.AGENT !== undefined) {
              _.set(photoconc_a_mettre_a_jour, "AGENT", exist.AGENT);
            }

            if (exist.MONUMENT_DERRIERE !== undefined) {
              _.set(photoconc_a_mettre_a_jour, "MONUMENT_DERRIERE", exist.MONUMENT_DERRIERE);
            }

            if (exist.MONUMENT_DEVANT !== undefined) {
              _.set(photoconc_a_mettre_a_jour, "MONUMENT_DEVANT", exist.MONUMENT_DEVANT);
            }

            if (exist.MONUMENT_DROITE !== undefined) {
              _.set(photoconc_a_mettre_a_jour, "MONUMENT_DROITE", exist.MONUMENT_DROITE);
            }

            if (exist.MONUMENT_ETAT !== undefined && photoconc.MONUMENT_ETAT === "") {
              _.set(photoconc_a_mettre_a_jour, "MONUMENT_ETAT", exist.MONUMENT_ETAT);
            }

            if (exist.MONUMENT_GAUCHE !== undefined) {
              _.set(photoconc_a_mettre_a_jour, "MONUMENT_GAUCHE", exist.MONUMENT_GAUCHE);
            }

            if (exist.NOTES !== undefined && photoconc.NOTES === "") {
              _.set(photoconc_a_mettre_a_jour, "NOTES", exist.NOTES);
            }

            if (exist.SEMELLE_ETAT !== undefined && photoconc.SEMELLE_ETAT === "") {
              _.set(photoconc_a_mettre_a_jour, "SEMELLE_ETAT", exist.SEMELLE_ETAT);
            }

            if (exist.SIGNATURE_AGENT !== undefined) {
              _.set(photoconc_a_mettre_a_jour, "SIGNATURE_AGENT", exist.SIGNATURE_AGENT);
            }

            if (exist.SIGNATURE_SOCIETE !== undefined) {
              _.set(photoconc_a_mettre_a_jour, "SIGNATURE_SOCIETE", exist.SIGNATURE_SOCIETE);
            }

            if (exist.SOCIETE !== undefined) {
              _.set(photoconc_a_mettre_a_jour, "SOCIETE", exist.SOCIETE);
            }

            db.open()
              .then(() => {
                db.PHOTOCONC.put(photoconc_a_mettre_a_jour)
                  .then(() => {
                    LoaderState.setNombrePhotos(getProgress < _photoconcCount ? getProgress : _photoconcCount);
                    _change_loader_state();
                  })
                  .catch((err) => {
                    rej(handleError(err));
                  });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          }

          if (getProgress >= _photoconcCount) res("");
        });
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Modification de ${_photoconcCount} photos de concession`);

    return val;
  });
}
