import db from "../Db";
import { addTableWithProgress, handleError } from "../Function";
import { GetApiData } from "../Get/ApiData";
import { GetLieuById } from "../Get/Lieu";
import LieuHistoAcces from "./../../Components/Global/Object/LieuHistoAcces";

var _ = require("lodash");

export async function UpdateLieu_MDF(headers) {
  const _lieuCount = await GetApiData("LIEUCount", headers);

  if (_lieuCount.Message !== undefined) {
    return _lieuCount;
  }

  const promise = new Promise((res, rej) => {
    if (_lieuCount === 0) res("");

    addTableWithProgress("LIEU", _lieuCount, 2500, headers, (getProgress, getLieu) => {
      if (getLieu.Message !== undefined) {
        return res(getLieu);
      }

      _.forEach(getLieu, (lieu) => {
        const lieu_a_mettre_a_jour = {
          LICLEUNIK: lieu.LICLEUNIK,
          EMPLACEMENT: {
            TYPECAR: lieu.TYPECAR.trim(),
            CARRE: lieu.CARRE.trim(),
            TYPERG: lieu.TYPERG.trim(),
            RANG: lieu.RANG.trim(),
            TYPEFOS: lieu.TYPEFOS.trim(),
            TOMBE: lieu.TOMBE.trim(),
            ETAT: lieu.ETAT, // 1 = libre - 2 = occupe
            GELE: lieu.GELE,
            H1: lieu.H1,
            V1: lieu.V1,
            H2: lieu.H2,
            V2: lieu.V2,
            H3: lieu.H3,
            V3: lieu.V3,
            H4: lieu.H4,
            V4: lieu.V4,
            POSITIONSTELE: lieu.POSITIONSTELE,
            MONUMENTPRESENT: lieu.MONUMENTPRESENT,
            PASSE_PIED: lieu.PASSE_PIED,
          },
          COCLEUNIK: lieu.COCLEUNIK,
          CIMEL: lieu.CIMEL,
          TOMBEL: lieu.TOMBEL,
          NOMBREL: lieu.NOMBREL,
          DUREEL: lieu.DUREEL,
          POSNUM: lieu.POSNUM,
          SITUATION: lieu.SITUATION,
          SITUATION_ALL_1: lieu.SITUATION_ALL.split("/")[0] !== undefined && lieu.SITUATION_ALL.split("/")[0] !== "" ? parseInt(lieu.SITUATION_ALL.split("/")[0]) : 0,
          SITUATION_ALL_2: lieu.SITUATION_ALL.split("/")[1] !== undefined ? parseInt(lieu.SITUATION_ALL.split("/")[1]) : 0,
          SITUATION_ALL_3: lieu.SITUATION_ALL.split("/")[2] !== undefined ? parseInt(lieu.SITUATION_ALL.split("/")[2]) : 0,
          SITUATION_ALL_4: lieu.SITUATION_ALL.split("/")[3] !== undefined ? parseInt(lieu.SITUATION_ALL.split("/")[3]) : 0,
          TAILLEPOLICEDULIEU: lieu.TAILLEPOLICEDULIEU,
          AFFMULTILIGNE: lieu.AFFMULTILIGNE,
          LIEU_MULTIPLE_PRINC_VIRTUELLE: lieu.LIEU_MULTIPLE_PRINC_VIRTUELLE,
          IDTHANATOMORPHOSE: lieu.IDTHANATOMORPHOSE,
          IDETAT_TERRAIN: lieu.IDETAT_TERRAIN,
          IDACCESSIBILITE: lieu.IDACCESSIBILITE,
        };

        LieuHistoAcces.setLieu(lieu_a_mettre_a_jour);

        GetLieuById(lieu_a_mettre_a_jour.LICLEUNIK).then((exist) => {
          if (exist === undefined) {
            db.open()
              .then(() => {
                db.LIEU.add(lieu_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          } else {
            _.set(lieu_a_mettre_a_jour, "id", exist.id);

            db.open()
              .then(() => {
                db.LIEU.put(lieu_a_mettre_a_jour).catch((err) => {
                  rej(handleError(err));
                });
              })
              .catch((err) => {
                rej(handleError(err));
              });
          }

          if (getProgress >= _lieuCount) res("");
        });
      });
    });
  });

  return await promise.then((val) => {
    console.log(`Modification de ${_lieuCount} lieux`);

    return val;
  });
}
