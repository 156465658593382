import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Button, Card, CardContent, Dialog, DialogContent, DialogTitle, Grid, IconButton, Paper, Typography, withStyles } from "@material-ui/core";
import { GetZoneById, GetZoneFromParent } from "./../../../Helpers/Get/Zone";
import { IsOracle } from "./../../../Helpers/Test/IsOracle";
import { GetLieuById, GetLieuByZocleunik } from "./../../../Helpers/Get/Lieu";
import { GetCendreById } from "./../../../Helpers/Get/Cendre";
import { GetCouleurEmpl } from "../../../Helpers/Get/Cimetier";
import * as d3 from "d3";
import { GetConcessById } from "../../../Helpers/Get/Concess";
import { Close } from "@material-ui/icons";
import CListeEquipementCendre from "../CListeEquipementCendre";
import { GetDefuntByLicleunik } from "./../../../Helpers/Get/Defunt";
import MARKER from "../../../Images/marker_red.png";

var _ = require("lodash");

const styles = {
  grid: {
    flex: 1,
    flexDirection: "column",
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    paddingTop: 16,
  },
  card: {
    alignItems: "center",
    display: "flex",
    height: 100,
    position: "absolute",
    width: 250,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
};

const StyleTypography = withStyles({
  root: {
    textDecoration: "underline",
    "&:hover": {
      color: "#FF732F",
    },
  },
})(Typography);

class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

class CCSousPlanEquipement extends Component {
  constructor(props) {
    super(props);

    this.SVG = undefined;
    this.PREV_LICLEUNIK = 0;

    this.state = {
      ISORACLE: false,
      ZONE: "",
      CENDRE: "",
      DISPLAYCARD: false,
      LIEU: "",
      DEFUNT: [],
      CONCESS: "",
      MOUSECOORD: [],
      emplacement_libre_dialog: false,
      REDIRECT: null,
      reverse: false,
    };

    this._svg_element = React.createRef();
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ ISORACLE: oracle });
    });

    if (this.props.reverse !== undefined) this.setState({ reverse: this.props.reverse });

    if (this.props.id_lieu !== undefined) {
      GetLieuById(this.props.id_lieu).then((lieu) => {
        this.setState({ LIEU: lieu });
      });
    }

    if (this.props.zocleunik !== undefined) {
      GetZoneById(this.props.zocleunik).then((zone) => {
        GetCendreById(zone.TYPEZONE).then((cendre) => {
          this.setState({ ZONE: zone, CENDRE: cendre }, () => {
            if (zone.IMAGESP !== "") this._create_svg();
          });
        });
      });
    }
  };

  _create_svg = () => {
    this.SVG = d3.select(this._svg_element.current).style("width", "100%").style("height", "auto").style("max-width", "1024px");
    this.SVG.select("g").remove();

    const g = this.SVG.append("g");

    g.append("image")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 800)
      .attr("height", 600)
      .attr("xlink:href", window.URL.createObjectURL(this.state.ZONE.IMAGESP))
      .style("image-rendering", "-webkit-optimize-contrast");

    setTimeout(() => {
      this._svg_element.current.scrollIntoView();
    }, 500);

    this._get_polygons();
  };

  _get_polygons = () => {
    var polygon = [];

    GetZoneFromParent(this.state.ZONE.ZOCLEUNIK).then((zone) => {
      _.forEach(zone, (z, i) => {
        let X = z.COORDONNEES.X1 * (800 / 640);
        let Y = z.COORDONNEES.Y1 * (600 / 480);
        let W = z.COORDONNEES.X2 * (800 / 640) - z.COORDONNEES.X1 * (800 / 640);
        let H = z.COORDONNEES.Y2 * (600 / 480) - z.COORDONNEES.Y1 * (600 / 480);

        this.SVG.append("rect").attr("cursor", "pointer").attr("x", X).attr("y", Y).attr("width", W).attr("height", H).attr("fill", "transparent").attr("zocleunik", z.ZOCLEUNIK).attr("equipement", 1);

        if (z.ZOCLEUNIK === this.state.LIEU.SITUATION) {
          let XCenter = X + W / 2;
          let YCenter = Y + H / 2;

          var iamge = this.SVG.append("image")
            .attr("x", XCenter - 11)
            .attr("y", YCenter - 40)
            .attr("width", 22)
            .attr("height", 40)
            .attr("cursor", "pointer")

            .attr("xlink:href", MARKER);

          iamge.on("click", (d, i, p) => {
            d3.event.preventDefault();

            this.setState({ ZONE: z }, () => {
              if (z.IMAGESP !== "") this._create_svg();
            });
          });
        }
      });

      var rect = this.SVG.selectAll("rect");
      rect.on("click", (d, i, p) => {
        d3.event.preventDefault();

        const currentZone = _.find(zone, ["ZOCLEUNIK", parseInt(d3.select(p[i]).attr("zocleunik"))]);
        this.setState({ ZONE: currentZone }, () => {
          if (currentZone.IMAGESP !== "") this._create_svg();
        });
      });
    });

    GetLieuByZocleunik(this.state.ZONE.ZOCLEUNIK).then((lieu) => {
      _.forEach(lieu, (l, i) => {
        GetCouleurEmpl(l).then((couleur) => {
          var TOMBE = l.EMPLACEMENT.TOMBE;

          while (TOMBE.charAt(0) === "0") {
            TOMBE = TOMBE.slice(1);
          }

          var x = [],
            y = [];

          var X1 = l.EMPLACEMENT.H1 * (800 / 640);
          var X2 = l.EMPLACEMENT.H2 * (800 / 640);
          var X3 = l.EMPLACEMENT.H3 * (800 / 640);
          var X4 = l.EMPLACEMENT.H4 * (800 / 640);
          var Y1 = l.EMPLACEMENT.V1 * (600 / 480);
          var Y2 = l.EMPLACEMENT.V2 * (600 / 480);
          var Y3 = l.EMPLACEMENT.V3 * (600 / 480);
          var Y4 = l.EMPLACEMENT.V4 * (600 / 480);

          X1 = X1 * 1000;
          X2 = X2 * 1000;
          X3 = X3 * 1000;
          X4 = X4 * 1000;

          Y1 = Y1 * 1000;
          Y2 = Y2 * 1000;
          Y3 = Y3 * 1000;
          Y4 = Y4 * 1000;

          let P1 = new Point(X1, Y1);
          let P2 = new Point(X2, Y2);
          let P3 = new Point(X3, Y3);
          let P4 = new Point(X4, Y4);

          let points = { P1, P2, P3, P4 };
          for (var index in points) {
            let point = points[index];

            x.push(point.x);
            y.push(point.y);
          }

          let XMin = Math.min.apply(null, x);
          let XMax = Math.max.apply(null, x);
          let YMin = Math.min.apply(null, y);
          let YMax = Math.max.apply(null, y);

          let border = 2;

          let taillePolice = 6;
          if (this.state.ISORACLE === 0) {
            if (l.TAILLEPOLICEDULIEU === 2) {
              taillePolice = 7;
            }

            if (l.TAILLEPOLICEDULIEU === 3) {
              taillePolice = 8;
            }
          } else {
            taillePolice = l.TAILLEPOLICEDULIEU;
          }
          var tailleTexte = taillePolice.toFixed(2);
          if (tailleTexte < 1) {
            tailleTexte = 1;
          }

          var text = {};
          let textX, textY;
          let tspanY = [],
            tspanText = [];

          if (TOMBE !== "") {
            textX = (XMax - XMin) / 2 + XMin;
            textY = (YMax - YMin) / 2 + YMin + (parseFloat(tailleTexte) * 1000) / 2;

            text.X = (textX / 1000).toFixed(3);
            text.Y = (textY / 1000).toFixed(3);
            text.SIZE = tailleTexte;
            text.TOMBE = TOMBE;

            if (l.POSNUM === 6) {
              textY = (YMax - YMin) / 2 + YMin;

              text.TRANSFORM = { Y: (textY / 1000).toFixed(3) };
            } else {
              if (TOMBE.includes("-") && l.AFFMULTILIGNE === 1) {
                var polyHeight = YMax / 1000 - YMin / 1000;
                var tombeArr = TOMBE.split("-");
                var textHeight = parseFloat(tailleTexte) * tombeArr.length;

                for (var j = 0; j < tombeArr.length; j++) {
                  var ty = P1.y / 1000 + (textHeight / tombeArr.length) * j + (polyHeight - textHeight) / 2 + parseFloat(tailleTexte);

                  tspanY.push(ty.toFixed(3));
                  tspanText.push(tombeArr[j]);
                }

                text.TSPAN = { Y: tspanY, TEXT: tspanText };
              }
            }
          }

          polygon.push({
            NUMCIME: l.CIMEL,
            POINTS: {
              P1: {
                X: (P1.x / 1000).toFixed(3),
                Y: (P1.y / 1000).toFixed(3),
              },
              P2: {
                X: (P2.x / 1000).toFixed(3),
                Y: (P2.y / 1000).toFixed(3),
              },
              P3: {
                X: (P3.x / 1000).toFixed(3),
                Y: (P3.y / 1000).toFixed(3),
              },
              P4: {
                X: (P4.x / 1000).toFixed(3),
                Y: (P4.y / 1000).toFixed(3),
              },
              BORDER: border,
            },
            LICLEUNIK: l.LICLEUNIK,
            COULEUR: couleur,
            TEXT: text,
            GELE: l.EMPLACEMENT.GELE,
            MONUMENTPRESENT: l.EMPLACEMENT.MONUMENTPRESENT,
            PASSE_PIED: l.EMPLACEMENT.PASSE_PIED,
            POSITIONSTELE: l.EMPLACEMENT.POSITIONSTELE,
          });

          if (i === lieu.length - 1) {
            const g = this.SVG.select("g");

            this._draw_polygons(g, polygon);
          }
        });
      });
    });
  };

  _draw_polygons = (g, polygon) => {
    let id_lieu = this.props.id_lieu !== undefined ? this.props.id_lieu : !isNaN(parseInt(this.props.type)) ? this.props.type : undefined;

    _.forEach(polygon, (p) => {
      const sub_g = g.append("g").attr("cursor", "pointer").attr("licleunik", p.LICLEUNIK).style("user-select", "none");

      sub_g
        .append("polygon")
        .attr("stroke", "#A0A0A0")
        .attr("stroke-width", p.POINTS.BORDER)
        .attr("fill", id_lieu !== undefined && p.LICLEUNIK === parseInt(id_lieu) ? "red" : p.COULEUR)
        .attr("points", `${p.POINTS.P1.X},${p.POINTS.P1.Y} ${p.POINTS.P2.X},${p.POINTS.P2.Y} ${p.POINTS.P3.X},${p.POINTS.P3.Y} ${p.POINTS.P4.X},${p.POINTS.P4.Y}`);

      if (p.GELE === true) {
        sub_g
          .append("line")
          .attr("x1", p.POINTS.P1.X)
          .attr("y1", p.POINTS.P1.Y)
          .attr("x2", p.POINTS.P3.X)
          .attr("y2", p.POINTS.P3.Y)
          .attr("stroke", "red")
          .attr("stroke-width", p.POINTS.BORDER / 2);

        sub_g
          .append("line")
          .attr("x1", p.POINTS.P2.X)
          .attr("y1", p.POINTS.P2.Y)
          .attr("x2", p.POINTS.P4.X)
          .attr("y2", p.POINTS.P4.Y)
          .attr("stroke", "red")
          .attr("stroke-width", p.POINTS.BORDER / 2);
      }

      if (p.MONUMENTPRESENT === true) {
        var angleDeg = (Math.atan2(parseFloat(p.POINTS.P2.Y) - parseFloat(p.POINTS.P1.Y), parseFloat(p.POINTS.P2.X) - parseFloat(p.POINTS.P1.X)) * 180) / Math.PI;

        sub_g
          .append("polygon")
          .attr("fill", "red")
          .attr(
            "points",
            `${parseFloat(p.POINTS.P1.X) - 0.2},${parseFloat(p.POINTS.P1.Y) - 0.2} ${parseFloat(p.POINTS.P1.X) + 0.5},${parseFloat(p.POINTS.P1.Y) - 0.2} ${parseFloat(p.POINTS.P1.X) - 0.2},${
              parseFloat(p.POINTS.P1.Y) + 0.5
            }`
          )
          .attr("transform", `rotate(${parseInt(angleDeg)} ${p.POINTS.P1.X} ${p.POINTS.P1.Y})`);
      }

      if (p.PASSE_PIED === true) {
        sub_g
          .append("polygon")
          .attr("stroke", "red")
          .attr("stroke-width", p.POINTS.BORDER)
          .attr("fill", "transparent")
          .attr("points", `${p.POINTS.P1.X},${p.POINTS.P1.Y} ${p.POINTS.P2.X},${p.POINTS.P2.Y} ${p.POINTS.P3.X},${p.POINTS.P3.Y} ${p.POINTS.P4.X},${p.POINTS.P4.Y}`);
      }

      if (p.POSITIONSTELE === 2) {
        sub_g
          .append("line")
          .attr("x1", parseFloat(p.POINTS.P1.X) + 0.2)
          .attr("y1", parseFloat(p.POINTS.P1.Y) + 0.2)
          .attr("x2", parseFloat(p.POINTS.P2.X) - 0.2)
          .attr("y2", parseFloat(p.POINTS.P2.Y) + 0.2)
          .attr("stroke", "black")
          .attr("stroke-width", p.POINTS.BORDER);
      }

      if (p.POSITIONSTELE === 3) {
        sub_g
          .append("line")
          .attr("x1", parseFloat(p.POINTS.P3.X) - 0.2)
          .attr("y1", parseFloat(p.POINTS.P3.Y) - 0.2)
          .attr("x2", parseFloat(p.POINTS.P4.X) + 0.2)
          .attr("y2", parseFloat(p.POINTS.P4.Y) - 0.2)
          .attr("stroke", "black")
          .attr("stroke-width", p.POINTS.BORDER);
      }

      if (p.POSITIONSTELE === 4) {
        sub_g
          .append("line")
          .attr("x1", parseFloat(p.POINTS.P1.X) + 0.2)
          .attr("y1", parseFloat(p.POINTS.P1.Y) + 0.2)
          .attr("x2", parseFloat(p.POINTS.P4.X) + 0.2)
          .attr("y2", parseFloat(p.POINTS.P4.Y) - 0.2)
          .attr("stroke", "black")
          .attr("stroke-width", p.POINTS.BORDER);
      }

      if (p.POSITIONSTELE === 5) {
        sub_g
          .append("line")
          .attr("x1", parseFloat(p.POINTS.P2.X) - 0.2)
          .attr("y1", parseFloat(p.POINTS.P2.Y) + 0.2)
          .attr("x2", parseFloat(p.POINTS.P3.X) - 0.2)
          .attr("y2", parseFloat(p.POINTS.P3.Y) - 0.2)
          .attr("stroke", "black")
          .attr("stroke-width", p.POINTS.BORDER);
      }

      if (p.POSITIONSTELE === 6) {
        sub_g
          .append("polygon")
          .attr("stroke", "black")
          .attr("stroke-width", p.POINTS.BORDER)
          .attr("fill", "transparent")
          .attr(
            "points",
            `${parseFloat(p.POINTS.P1.X) + 0.2},${parseFloat(p.POINTS.P1.Y) + 0.2} ${parseFloat(p.POINTS.P2.X) - 0.2},${parseFloat(p.POINTS.P2.Y) + 0.2} ${parseFloat(p.POINTS.P3.X) - 0.2},${
              parseFloat(p.POINTS.P3.Y) - 0.2
            } ${parseFloat(p.POINTS.P4.X) + 0.2},${parseFloat(p.POINTS.P4.Y) - 0.2}`
          );
      }

      if (p.TEXT.X !== undefined) {
        if (p.TEXT.TRANSFORM !== undefined) {
          sub_g
            .append("text")
            .attr("x", parseFloat(p.TEXT.X))
            .attr("y", parseFloat(p.TEXT.Y))
            .attr("text-anchor", "middle")
            .attr("font-family", "Verdana")
            .attr("font-weight", "bold")
            .attr("lengthAdjust", "spacing")
            .attr("font-size", p.TEXT.SIZE)
            .attr("transform", "rotate(90 " + parseFloat(p.TEXT.X) + " " + parseFloat(p.TEXT.TRANSFORM.Y) + ")")
            .text(p.TEXT.TOMBE);
        } else {
          if (p.TEXT.TSPAN !== undefined) {
            var text = sub_g
              .append("text")
              .attr("x", parseFloat(p.TEXT.X))
              .attr("y", parseFloat(p.TEXT.Y))
              .attr("text-anchor", "middle")
              .attr("font-family", "Verdana")
              .attr("font-weight", "bold")
              .attr("lengthAdjust", "spacing")
              .attr("font-size", p.TEXT.SIZE);

            for (var i = 0; i < p.TEXT.TSPAN.Y.length; i++) {
              text.append("tspan").attr("x", parseFloat(p.TEXT.X)).attr("y", parseFloat(p.TEXT.TSPAN.Y[i])).text(p.TEXT.TSPAN.TEXT[i]);
            }
          } else {
            sub_g
              .append("text")
              .attr("x", parseFloat(p.TEXT.X))
              .attr("y", parseFloat(p.TEXT.Y))
              .attr("text-anchor", "middle")
              .attr("font-family", "Verdana")
              .attr("font-weight", "bold")
              .attr("lengthAdjust", "spacing")
              .attr("font-size", p.TEXT.SIZE)
              .text(p.TEXT.TOMBE);
          }
        }
      }
    });

    g.on("click", (d, i, p) => {
      this.setState({ DISPLAYCARD: false });
    });

    if (id_lieu !== undefined) {
      let LICLEUNIK = parseInt(id_lieu);

      setTimeout(() => {
        let polygon = d3.select("g[licleunik='" + LICLEUNIK + "']").node();

        if (polygon !== null) {
          polygon = polygon.getBoundingClientRect();

          let pos = [polygon.x + polygon.width / 2, polygon.y + polygon.height / 2];

          let rect = this.SVG.node().getBoundingClientRect();

          if (pos[0] > rect.right - 250) {
            pos[0] = pos[0] - 250;
          }

          if (pos[1] > rect.height - 100) {
            pos[1] = pos[1] - 100;
          }

          GetLieuById(LICLEUNIK).then((lieu) => {
            this.PREV_LICLEUNIK = LICLEUNIK;

            if (LICLEUNIK !== 0) {
              GetDefuntByLicleunik(LICLEUNIK).then((defunts) => {
                GetConcessById(lieu.COCLEUNIK).then((concess) => {
                  this.setState({
                    LIEU: lieu,
                    DEFUNT: defunts,
                    CONCESS: concess,
                    MOUSECOORD: pos,
                    DISPLAYCARD: true,
                  });
                });
              });
            }
          });
        }
      }, 1000);
    }

    g.selectAll("g").on("click", (d, i, p) => {
      let pos = [d3.event.pageX, d3.event.pageY];
      let rect = this.SVG.node().getBoundingClientRect();

      if (pos[0] > rect.right - 250) {
        pos[0] = pos[0] - 250;
      }

      if (pos[1] > rect.height - 100) {
        pos[1] = pos[1] - 100;
      }

      if (this.PREV_LICLEUNIK !== 0) {
        GetLieuById(this.PREV_LICLEUNIK).then((lieu) => {
          GetCouleurEmpl(lieu).then((couleur) => {
            this.SVG.select("g").select(`g[licleunik='${this.PREV_LICLEUNIK}']`).select("polygon").style("fill", couleur);

            d3.select(p[i]).select("polygon").style("fill", "red");

            let LICLEUNIK = d3.select(p[i]).attr("licleunik");
            this.PREV_LICLEUNIK = parseInt(LICLEUNIK);

            if (LICLEUNIK !== 0) {
              GetLieuById(LICLEUNIK).then((lieu) => {
                GetDefuntByLicleunik(LICLEUNIK).then((defunts) => {
                  GetConcessById(lieu.COCLEUNIK).then((concess) => {
                    this.setState({
                      LIEU: lieu,
                      DEFUNT: defunts,
                      CONCESS: concess,
                      MOUSECOORD: pos,
                      DISPLAYCARD: true,
                    });
                  });
                });
              });
            }
          });
        });
      } else {
        let LICLEUNIK = d3.select(p[i]).attr("licleunik");
        GetLieuById(LICLEUNIK).then((lieu) => {
          GetCouleurEmpl(lieu).then((couleur) => {
            this.PREV_LICLEUNIK = parseInt(LICLEUNIK);

            d3.select(p[i]).select("polygon").style("fill", "red");

            if (LICLEUNIK !== 0) {
              GetLieuById(LICLEUNIK).then((lieu) => {
                GetDefuntByLicleunik(LICLEUNIK).then((defunts) => {
                  GetConcessById(lieu.COCLEUNIK).then((concess) => {
                    this.setState({
                      LIEU: lieu,
                      DEFUNT: defunts,
                      CONCESS: concess,
                      MOUSECOORD: pos,
                      DISPLAYCARD: true,
                    });
                  });
                });
              });
            }
          });
        });
      }
    });
  };

  _handle_click_card = (event) => {
    event.preventDefault();

    if (this.state.DEFUNT.length === 0 || this.state.LIEU.COCLEUNIK !== 0) {
      if (this.props.type === "photos") {
        if (this.state.CONCESS !== undefined) {
          this.setState({
            REDIRECT: `/modifier/photographies/${this.state.CONCESS.COCLEUNIK}`,
          });
        } else {
          this.setState({ emplacement_libre_dialog: true });
        }
      } else {
        if (this.state.CONCESS !== undefined) {
          if (this.state.reverse) {
            this.setState({
              REDIRECT: `/consulter/fiche-concession/${this.state.CONCESS.COCLEUNIK}/r`,
            });
          } else {
            this.setState({
              REDIRECT: `/consulter/fiche-concession/${this.state.CONCESS.COCLEUNIK}`,
            });
          }
        } else {
          this.setState({
            REDIRECT: `/consulter/concessions/ajout/${this.state.LIEU.LICLEUNIK}`,
          });
        }
      }
    }
  };

  _handle_click_bouton_retour = (event) => {
    event.preventDefault();

    GetZoneById(this.state.ZONE.ZOCLEUNIK_PARENT).then((zone) => {
      this.setState({ ZONE: zone, DISPLAYCARD: false }, () => {
        if (zone.IMAGESP !== "") this._create_svg();
      });
    });
  };

  _cree_chaine_emplacement = () => {
    var empl = "";

    if (this.state.CONCESS !== undefined) {
      empl = this.state.CONCESS.EMPLACEMENT_LIEU;
    } else {
      empl = _.filter([
        _.filter([this.state.LIEU.EMPLACEMENT.TYPECAR, this.state.LIEU.EMPLACEMENT.CARRE]).join(" "),
        _.filter([this.state.LIEU.EMPLACEMENT.TYPERG, this.state.LIEU.EMPLACEMENT.RANG]).join(" "),
        _.filter([this.state.LIEU.EMPLACEMENT.TYPEFOS, this.state.LIEU.EMPLACEMENT.TOMBE]).join(" "),
      ]).join(" - ");
    }

    return empl;
  };

  _cree_chaine_date = (date) => {
    if (date !== "") {
      if (date.length === 4) {
        return date;
      } else {
        let c_date = date.split("T")[0].split("-");

        return new Date(c_date[0], c_date[1] - 1, c_date[2]).toLocaleDateString("fr-FR");
      }
    } else {
      return date;
    }
  };

  render() {
    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return this.state.CENDRE !== "" && this.state.CENDRE !== undefined ? (
      <CListeEquipementCendre id_cendre={this.state.CENDRE.NUMCENDRE} changeTitle={this.props.changeTitle} closeDialog={this.props.closeDialog} />
    ) : this.state.ZONE !== "" ? (
      <Grid container alignItems="center" justify="center" style={styles.grid}>
        <Grid item xs={11} md={8} style={styles.grid_item}>
          <Paper style={styles.paper}>
            {this.state.ZONE.IMAGESP !== "" ? <svg ref={this._svg_element} width={800} height={600} preserveAspectRatio="xMinYMin meet" viewBox={`0 0 800 600`}></svg> : null}

            {this.state.LIEU !== "" && this.state.DISPLAYCARD ? (
              <div>
                <Card
                  style={{
                    ...styles.card,
                    ...{
                      position: "absolute",
                      left: this.state.MOUSECOORD[0],
                      top: this.state.MOUSECOORD[1],
                      cursor: "pointer",
                    },
                  }}
                  onClick={this._handle_click_card}
                >
                  <CardContent style={{ paddingBottom: 16 }}>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {this._cree_chaine_emplacement()}
                    </Typography>

                    <Button
                      style={{ width: 15, height: 15, minWidth: 15, position: "absolute", top: 10, right: 10 }}
                      onClick={(event) => {
                        event.stopPropagation();

                        this.setState({ DISPLAYCARD: false });
                      }}
                    >
                      &times;
                    </Button>

                    {this.state.CONCESS !== undefined ? (
                      this.state.CONCESS.FAMILLE !== "" ? (
                        <Typography variant="body2" color="textSecondary" component="p">
                          {`Famille ${this.state.CONCESS.FAMILLE}`}
                        </Typography>
                      ) : null
                    ) : this.state.DEFUNT.length > 0 ? (
                      this.state.DEFUNT.map((defunt, index) => (
                        <StyleTypography
                          key={index}
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          onClick={() =>
                            this.setState({
                              REDIRECT: `/consulter/fiche-defunt/${defunt.D0CLEUNIK}`,
                            })
                          }
                        >
                          {`${defunt.ETAT_CIVIL.NOMDEF} ${defunt.ETAT_CIVIL.PRENOMDEF}`}
                        </StyleTypography>
                      ))
                    ) : (
                      <Typography variant="body2" color="textSecondary" component="p">
                        Emplacement libre
                      </Typography>
                    )}

                    {this.state.CONCESS !== undefined ? (
                      !this.state.CONCESS.DATEEXPIRA.includes("1900-01-01") ? (
                        <Typography variant="body2" color="textSecondary" component="p">
                          {`Expire le ${this._cree_chaine_date(this.state.CONCESS.DATEEXPIRA)}`}
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="textSecondary" component="p">
                          Pas de date d'expiration
                        </Typography>
                      )
                    ) : null}
                  </CardContent>
                </Card>
              </div>
            ) : null}

            {this.state.ZONE.ZOCLEUNIK_PARENT > 0 ? (
              <Button variant="contained" color="primary" onClick={this._handle_click_bouton_retour}>
                Retour
              </Button>
            ) : null}

            <Dialog onClose={() => this.setState({ emplacement_libre_dialog: false })} open={this.state.emplacement_libre_dialog}>
              <DialogTitle onClose={() => this.setState({ emplacement_libre_dialog: false })}>
                <Typography variant="h6" component="p">
                  Attention
                </Typography>

                <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ emplacement_libre_dialog: false })}>
                  <Close />
                </IconButton>
              </DialogTitle>

              <DialogContent dividers>
                <Typography gutterBottom>Impossible d'ajouter des photographies sur un emplacement libre.</Typography>
              </DialogContent>
            </Dialog>
          </Paper>
        </Grid>
      </Grid>
    ) : null;
  }
}

export default CCSousPlanEquipement;
