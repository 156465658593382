import db from "../Db";

import { GetApiData } from "../Get/ApiData";
import { addTableWithProgress, handleError } from "../Function";
import { GetNewDateSynchro } from "../Get/GetNewDateSynchro";

var _ = require("lodash");

export async function CreateClient(headers, idtablette) {
  const _client = await GetApiData("CLIENT", headers);

  if (_client.Message !== undefined) {
    return _client;
  }

  return await db
    .open()
    .then(async () => {
      const _temporaireCount = await GetApiData("TEMPORAIRECount", headers);

      if (_temporaireCount.Message !== undefined) {
        return _temporaireCount;
      }

      if (_temporaireCount > 0) {
        const promise = new Promise((res, rej) => {
          addTableWithProgress("TEMPORAIRE", _temporaireCount, 1000, headers, (getProgress, getTemporaire) => {
            if (getTemporaire.Message !== undefined) {
              return res(getTemporaire);
            }

            let tabletteIds = [];

            _.forEach(getTemporaire, (temporaire) => {
              if (!_.find(tabletteIds, temporaire.ID_TABLETTE)) tabletteIds.push(temporaire.ID_TABLETTE);
            });

            if (getProgress >= _temporaireCount) res(tabletteIds);
          });
        });

        return promise.then((val) => {
          var code;

          do {
            code = `_${Math.random().toString(36).substr(2, 9)}`;
          } while (_.find(val, code));

          const DateSynchro = GetNewDateSynchro(headers.APIKEY, "1900-01-01");

          return DateSynchro.then((date) => {
            if (date.Message !== undefined) {
              return date;
            }

            date = date.replace(/"/g, "");

            return db.CLIENT.add({
              ORACLE: _client.ORACLE,
              IDCLIENT: _client.IDCLIENT,
              DATE_SYNCHRO: date !== undefined ? date : "1900-01-01",
              ID_TABLETTE: idtablette !== undefined ? idtablette : code,
            })
              .then(() => {
                return "";
              })
              .catch((err) => {
                return handleError(err);
              });
          });
        });
      } else {
        const DateSynchro_1 = GetNewDateSynchro(headers.APIKEY, "1900-01-01");

        return DateSynchro_1.then((date_1) => {
          if (date_1.Message !== undefined) {
            return date_1;
          }

          date_1 = date_1.replace(/"/g, "");

          return db.CLIENT.add({
            ORACLE: _client.ORACLE,
            IDCLIENT: _client.IDCLIENT,
            DATE_SYNCHRO: date_1 !== undefined ? date_1 : "1900-01-01",
            ID_TABLETTE: idtablette !== undefined ? idtablette : `_${Math.random().toString(36).substr(2, 9)}`,
          })
            .then(() => {
              return "";
            })
            .catch((err_1) => {
              return handleError(err_1);
            });
        });
      }
    })
    .catch((err) => {
      return handleError(err);
    });
}
