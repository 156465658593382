import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import {
  withWidth,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  TextField,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  InputAdornment,
  TableBody,
  Card,
  CardActions,
  CardMedia,
  Button,
  Slide,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import { PhotoCamera, Done, Mic, Close, StarRate } from "@material-ui/icons";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import { withCookies } from "react-cookie";

import DateFnsUtils from "@date-io/date-fns";
import frLocale from "date-fns/locale/fr";

import { GetClient } from "./../../Helpers/Get/Client";
import { GetIntervenById } from "./../../Helpers/Get/Interven";
import { GetAllCimetier } from "./../../Helpers/Get/Cimetier";
import { GetCendreByNumcime } from "../../Helpers/Get/Cendre";
import { GetLieuByCimel, GetLieuById } from "./../../Helpers/Get/Lieu";
import { GetSquare, GetRank, GetTomb, Cree_iso_date2, ResizeImage, Cree_date_chaine } from "../../Helpers/Function";
import { GetSocieteById } from "./../../Helpers/Get/Societe";
import { GetNatintervByIdInterven } from "../../Helpers/Get/Natinterv";
import { GetConcessById } from "../../Helpers/Get/Concess";
import { GetAllPhotoconc, GetPhotoconcByCocleunik, GetPhotoconcByI0cleunik } from "./../../Helpers/Get/Photoconc";
import { differenceObject } from "./../../Helpers/Test/Difference";
import { GetTemporaire, GetTemporaireForColor, GetTemporaireNonEnvoye } from "../../Helpers/Get/Temporaire";

import AddTemporaire from "./../../Helpers/Add/Temporaire";
import UpdateInterven from "./../../Helpers/Update/Interven";
import UpdateTemporaire from "./../../Helpers/Update/Temporaire";

import { handleError, CreeTemporaire } from "../../Helpers/Function";
import { IsOracle } from "./../../Helpers/Test/IsOracle";
import { GetParam } from "./../../Helpers/Get/Param";
import { AjoutePhotos } from "./../../Helpers/Submit/Photoconc";

import b64toBlob from "b64-to-blob";
import no_photo from "../../Images/no_photo.json";
import SignatureCanvas from "react-signature-canvas";
import { Autocomplete } from "@material-ui/lab";
import { GetAllUtil } from "./../../Helpers/Get/Util";
import { CheckUserRight } from "./../../Helpers/Get/LigneAction";
import { DialogAccessRight } from "./../Global/DialogAccessRight";
import UpdatePhotoconc from "../../Helpers/Update/Photoconc";

var _ = require("lodash");

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
  table: {
    marginTop: 24,
  },
  tablecells: {
    border: "1px solid rgba(224, 224, 224, 1)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  photoconc_card: {
    border: "1px solid grey",
    cursor: "pointer",
    flex: 1,
    margin: "auto",
    maxWidth: 160,
    minWidth: 160,
    textAlign: "center",
    width: 100,
  },
  photoconc_card_dialog: {
    flex: 1,
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    margin: "120px 50px 50px 50px",
  },
  photoconc_cardaction: {
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 3,
  },
  photoconc_cardmedia: {
    backgroundColor: "whitesmoke",
    backgroundSize: "contain",
    borderTop: "1px solid lightgrey",
    height: 0,
    paddingTop: "100%",
  },
  photoconc_cardmedia_dialog: {
    backgroundSize: "contain",
    height: 0,
    paddingTop: "60%",
  },
  submit_div: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  appBar: {
    position: "relative",
  },
  typography: {
    flex: 1,
  },
};

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition = undefined;

if (SpeechRecognition !== undefined) {
  recognition = new SpeechRecognition();

  recognition.continous = true;
  recognition.lang = "fr-FR";
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class MTravaux extends Component {
  constructor(props) {
    super(props);

    this.IDCLIENT = 0;
    this.ID_TABLETTE = "";
    this.ID_UTIL = atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL"))));
    this.PAD_AVT = {};
    this.PAD_AVT2 = {};
    this.PAD_APT = {};
    this.PAD_APT2 = {};
    this.PAD_SOC_AVT = {};
    this.PAD_SOC_AVT2 = {};
    this.PAD_SOC_APT = {};
    this.PAD_SOC_APT2 = {};
    this.autocomplete_textinput_avt = React.createRef();
    this.autocomplete_textinput_avt2 = React.createRef();
    this.autocomplete_textinput_apt = React.createRef();
    this.autocomplete_textinput_apt2 = React.createRef();
    this.textinput_soc_avt = React.createRef();
    this.textinput_soc_avt2 = React.createRef();
    this.textinput_soc_apt = React.createRef();
    this.textinput_soc_apt2 = React.createRef();
    this.TARVAUX_1_ref = React.createRef();
    this.TARVAUX_2_ref = React.createRef();
    this.TARVAUX_1B_ref = React.createRef();
    this.TARVAUX_2B_ref = React.createRef();

    this.textfield_etatlieu_avt = React.createRef();
    this.textfield_etatlieu_apt = React.createRef();
    this.textfield_monument_avt = React.createRef();
    this.textfield_monument_apt = React.createRef();
    this.textfield_semelle_avt = React.createRef();
    this.textfield_semelle_apt = React.createRef();

    this.state = {
      IS_ORACLE: false,
      PARAM: "",
      INTERVEN_SAUVEGARDE: {
        id: 0,
        I0CLEUNIK: 0,
        DEMANDEUR: {
          NOM: "",
          PRENOM: "",
        },
        INTERVENANT_NOM: "",
        DATETRAVAUX: "",
        DATETRAVAUX_FIN: "",
        INTERVENANT_TYPE: "",
        SUIVITRAV: 0,
        IDMOUVEMENT: 0,
        SOCLEUNIK: 0,
        LICLEUNIK: 0,
        COCLEUNIK: 0,
        TYPECIME: 0,
        EMPL_NATURE: "",
        EMPL_TYPE: "",
        EMPL_DEF_OU_CONCESSION: "",
        CONSTAT_AVANT_TRAVAUX: "",
        CONSTAT_APRES_TRAVAUX: "",
      },
      INTERVEN: {
        id: 0,
        I0CLEUNIK: 0,
        DEMANDEUR: {
          NOM: "",
          PRENOM: "",
        },
        INTERVENANT_NOM: "",
        DATETRAVAUX: "",
        DATETRAVAUX_FIN: "",
        INTERVENANT_TYPE: "",
        SUIVITRAV: 0,
        IDMOUVEMENT: 0,
        SOCLEUNIK: 0,
        LICLEUNIK: 0,
        COCLEUNIK: 0,
        TYPECIME: 0,
        EMPL_NATURE: "",
        EMPL_TYPE: "",
        EMPL_DEF_OU_CONCESSION: "",
        CONSTAT_AVANT_TRAVAUX: "",
        CONSTAT_APRES_TRAVAUX: "",
      },
      CIMETIER: [],
      IS_CENDRE: false,
      CENDRE: [],
      SELECTED_CENDRE: "",
      SQUARE_LIBELLE: "Carré",
      SELECTED_SQUARE: "",
      SQUARE: [],
      RANK_LIBELLE: "Rang",
      SELECTED_RANK: "",
      RANK: [],
      TOMB_LIBELLE: "Tombe",
      SELECTED_TOMB: "",
      TOMB: [],
      SOCIETE: "",
      NATINTERV: "",
      CONCESS: "",
      PHOTOCONC_SAUVEGARDE: [],
      PHOTOCONC: [],
      REDIRECT: null,
      dialog_traitement: false,
      champ_modifie: [],
      DATE_TRAVAUX: "",
      HEURE_TRAVAUX: "",
      DATE_TRAVAUX_FIN: "",
      HEURE_TRAVAUX_FIN: "",
      disabledForm: false,
      SPEECH_LISTENING_CAVT: false,
      SPEECH_LISTENING_CAPT: false,
      agentterrain: [],
      selected_agent_avt: "",
      selected_agent_apt: "",
      photoconc_pour_combo: [],
      photoconc_pour_combo2: [],
      photoconc_combo_selected_avt: "",
      photoconc_combo2_selected_avt: "",
      photoconc_combo_selected_apt: "",
      photoconc_combo2_selected_apt: "",
      cas_strasbourg: false,
      constat_avt_en_cours_de_traiement: false,
      constat_apt_en_cours_de_traiement: false,
      errorMessage: "",
      photoDialogOpen: false,
      photoDialogPhoto: null,
      droit_util_ajout_photoconc: false,
      droit_util_modif_photoconc: false,
    };

    if (props.id_travaux !== undefined) {
      GetIntervenById(props.id_travaux).then((interven) => {
        props.changeTitle(interven.EMPL_DEF_OU_CONCESSION);

        GetConcessById(interven.COCLEUNIK).then((concess) => {
          if (concess !== undefined) {
            const new_photo = {
              ABANDON_1: false,
              ABANDON_2: false,
              COCLEUNIK: concess.COCLEUNIK,
              CONSTAT_TRAVAUX: 1,
              DATEAJOUT: null,
              I0CLEUNIK: props.id_travaux,
              MONUMENT_ETAT: "",
              NOTES: "",
              PHOTO: b64toBlob(no_photo.image, "image/png"),
              PRINCIPAL: 0,
              SEMELLE_ETAT: "",
              MONUMENT_GAUCHE: "",
              MONUMENT_DROITE: "",
              MONUMENT_DEVANT: "",
              MONUMENT_DERRIERE: "",
              AGENT: "",
              SIGNATURE_AGENT: null,
              SOCIETE: "",
              SIGNATURE_SOCIETE: null,
              NO_PHOTO: true,
            };

            GetPhotoconcByI0cleunik(interven.I0CLEUNIK.toString()).then((photos) => {
              if (_.find(photos, ["CONSTAT_TRAVAUX", 1]) === undefined) {
                let np = _.cloneDeep(new_photo);
                np.CONSTAT_TRAVAUX = 1;
                photos.splice(0, 0, np);
              }

              if (_.find(photos, ["CONSTAT_TRAVAUX", 2]) === undefined) {
                let np = _.cloneDeep(new_photo);
                np.CONSTAT_TRAVAUX = 2;
                photos.splice(1, 0, np);
              }

              if (photos[0].CONSTAT_TRAVAUX === 2) {
                _.reverse(photos);
              }

              let pc = _.last(
                _.filter(photos, (pa) => {
                  return pa.CONSTAT_TRAVAUX === 1;
                })
              );

              let sc = _.last(
                _.filter(photos, (pa) => {
                  return pa.CONSTAT_TRAVAUX === 2;
                })
              );

              photos = [pc, sc];

              _.forEach(photos, (p) => {
                if (p.PHOTO === "") {
                  p.PHOTO = b64toBlob(no_photo.image, "image/png");
                  p.NO_PHOTO = true;
                }
                if (p.MONUMENT_GAUCHE === undefined) p.MONUMENT_GAUCHE = "";
                if (p.MONUMENT_DROITE === undefined) p.MONUMENT_DROITE = "";
                if (p.MONUMENT_DEVANT === undefined) p.MONUMENT_DEVANT = "";
                if (p.MONUMENT_DERRIERE === undefined) p.MONUMENT_DERRIERE = "";
                if (p.AGENT === undefined) p.AGENT = "";
                if (p.AGENT !== "") {
                  const result = _.filter(this.state.agentterrain, (at) => {
                    return `${at.NOM} ${at.PRENOM}` === p.AGENT;
                  });
                  if (result.length > 0) {
                    if (p.CONSTAT_TRAVAUX === 1) this.setState({ selected_agent_avt: result[0] });
                    if (p.CONSTAT_TRAVAUX === 2) this.setState({ selected_agent_apt: result[0] });
                  }
                }

                if (p.SIGNATURE_AGENT === undefined) p.SIGNATURE_AGENT = null;
                if (p.SIGNATURE_AGENT !== null) {
                  if (p.CONSTAT_TRAVAUX === 1) {
                    p.SIGNATURE_AGENT.arrayBuffer().then((buffer_signature_agent) => {
                      const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_agent)).toString("base64")}`;
                      if (!_.isEmpty(this.PAD_AVT)) this.PAD_AVT.fromDataURL(b64);
                      if (!_.isEmpty(this.PAD_AVT2)) this.PAD_AVT2.fromDataURL(b64);
                    });
                  }

                  if (p.CONSTAT_TRAVAUX === 2) {
                    p.SIGNATURE_AGENT.arrayBuffer().then((buffer_signature_agent) => {
                      const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_agent)).toString("base64")}`;
                      if (!_.isEmpty(this.PAD_APT)) this.PAD_APT.fromDataURL(b64);
                      if (!_.isEmpty(this.PAD_APT2)) this.PAD_APT2.fromDataURL(b64);
                    });
                  }
                }
                if (p.SOCIETE === undefined) p.SOCIETE = "";
                if (p.SIGNATURE_SOCIETE === undefined) p.SIGNATURE_SOCIETE = null;
                if (p.SIGNATURE_SOCIETE !== null) {
                  if (p.CONSTAT_TRAVAUX === 1) {
                    p.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
                      const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;
                      if (!_.isEmpty(this.PAD_SOC_AVT)) this.PAD_SOC_AVT.fromDataURL(b64);
                      if (!_.isEmpty(this.PAD_SOC_AVT2)) this.PAD_SOC_AVT2.fromDataURL(b64);
                    });
                  }

                  if (p.CONSTAT_TRAVAUX === 2) {
                    p.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
                      const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;
                      if (!_.isEmpty(this.PAD_SOC_APT)) this.PAD_SOC_APT.fromDataURL(b64);
                      if (!_.isEmpty(this.PAD_SOC_APT2)) this.PAD_SOC_APT2.fromDataURL(b64);
                    });
                  }
                }
              });

              this.setState({
                PHOTOCONC_SAUVEGARDE: photos,
                INTERVEN_SAUVEGARDE: interven,
              });
            });
          }
        });
      });
    }
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle ? true : false });
    });

    GetParam().then((param) => {
      this.setState({ PARAM: param });
    });

    GetAllUtil(1).then((agent) => {
      this.setState({
        agentterrain: agent,
        selected_agent_avt: null,
        selected_agent_apt: null,
      });
    });

    const alias = atob(this.props.cookies.get(encodeURIComponent(btoa("ALIAS"))));

    if (/^strasbourg(.*)67000$/.test(alias)) {
      const photos_to_object = [
        {
          id: 1,
          MONUMENT_ETAT: "R.A.S",
        },
        {
          id: 2,
          MONUMENT_ETAT: "Dégradé",
        },
        {
          id: 3,
          MONUMENT_ETAT: "Dangereux",
        },
        {
          id: 4,
          MONUMENT_ETAT: "Problème végétation",
        },
      ];

      const photos_to_object2 = [
        {
          id: 1,
          SEMELLE_ETAT: "Affaissement",
        },
        {
          id: 2,
          SEMELLE_ETAT: "Hauteur d'arbre",
        },
        {
          id: 3,
          SEMELLE_ETAT: "Monument penché",
        },
        {
          id: 4,
          SEMELLE_ETAT: "Végétation exubérante",
        },
        {
          id: 5,
          SEMELLE_ETAT: "Stèle descellée",
        },
      ];

      this.setState({ photoconc_pour_combo: photos_to_object, photoconc_pour_combo2: photos_to_object2, cas_strasbourg: true });
    } else {
      GetAllPhotoconc().then((photos) => {
        const photos_monument = _.uniqWith(_.compact(_.map(photos, "MONUMENT_ETAT")), _.isEqual);
        const photos_semelle = _.uniqWith(_.compact(_.map(photos, "SEMELLE_ETAT")), _.isEqual);

        let photos_to_object = [];
        for (let index = 0; index < photos_monument.length; index++) {
          photos_to_object.push({
            id: index + 1,
            MONUMENT_ETAT: photos_monument[index],
          });
        }

        let photos_to_object2 = [];
        for (let index = 0; index < photos_semelle.length; index++) {
          photos_to_object2.push({
            id: index + 1,
            SEMELLE_ETAT: photos_semelle[index],
          });
        }

        this.setState({ photoconc_pour_combo: photos_to_object, photoconc_pour_combo2: photos_to_object2 });
      });
    }

    if (this.props.id_travaux !== undefined) {
      GetClient().then((client) => {
        this.IDCLIENT = client.IDCLIENT;
        this.ID_TABLETTE = client.ID_TABLETTE;
      });

      GetAllCimetier(1).then((cimetier) => {
        this.setState({ CIMETIER: cimetier });
      });

      GetIntervenById(this.props.id_travaux).then((interven) => {
        this.props.changeTitle(interven.EMPL_DEF_OU_CONCESSION);

        GetCendreByNumcime(interven.TYPECIME).then((allcendre) => {
          _.forEach(allcendre, (cendre) => {
            if (cendre.LICLEUNIK === interven.LICLEUNIK) {
              this.setState({
                IS_CENDRE: true,
                CENDRE: allcendre,
                SELECTED_CENDRE: cendre,
              });
            }
          });
        });

        GetLieuByCimel(interven.TYPECIME, true).then((alllieu) => {
          GetLieuById(interven.LICLEUNIK).then((lieu) => {
            let square = GetSquare(alllieu);
            let rank = GetRank(alllieu, lieu.EMPLACEMENT.CARRE);
            let tomb = GetTomb(alllieu, lieu.EMPLACEMENT.CARRE, lieu.EMPLACEMENT.RANG);

            this.setState({
              SQUARE_LIBELLE: lieu.EMPLACEMENT.TYPECAR,
              SELECTED_SQUARE: lieu.EMPLACEMENT.CARRE,
              SQUARE: square,
              RANK_LIBELLE: lieu.EMPLACEMENT.TYPERG,
              SELECTED_RANK: lieu.EMPLACEMENT.RANG,
              RANK: rank,
              TOMB_LIBELLE: lieu.EMPLACEMENT.TYPEFOS,
              SELECTED_TOMB: lieu.EMPLACEMENT.TOMBE,
              TOMB: tomb,
            });
          });
        });

        GetSocieteById(interven.SOCLEUNIK).then((societe) => {
          this.setState({ SOCIETE: societe });
        });

        GetNatintervByIdInterven(interven.I0CLEUNIK).then((natinterv) => {
          this.setState({ NATINTERV: natinterv });
        });

        GetConcessById(interven.COCLEUNIK).then((concess) => {
          if (concess !== undefined) {
            const new_photo = {
              ABANDON_1: false,
              ABANDON_2: false,
              COCLEUNIK: concess.COCLEUNIK,
              CONSTAT_TRAVAUX: 1,
              DATEAJOUT: null,
              I0CLEUNIK: this.props.id_travaux,
              MONUMENT_ETAT: "",
              NOTES: "",
              PHOTO: b64toBlob(no_photo.image, "image/png"),
              PRINCIPAL: 0,
              SEMELLE_ETAT: "",
              MONUMENT_GAUCHE: "",
              MONUMENT_DROITE: "",
              MONUMENT_DEVANT: "",
              MONUMENT_DERRIERE: "",
              AGENT: "",
              SIGNATURE_AGENT: null,
              SOCIETE: "",
              SIGNATURE_SOCIETE: null,
              NO_PHOTO: true,
            };

            GetPhotoconcByI0cleunik(interven.I0CLEUNIK.toString()).then((photos) => {
              if (_.find(photos, ["CONSTAT_TRAVAUX", 1]) === undefined) {
                let np = _.cloneDeep(new_photo);
                np.CONSTAT_TRAVAUX = 1;
                photos.splice(0, 0, np);
              }

              if (_.find(photos, ["CONSTAT_TRAVAUX", 2]) === undefined) {
                let np = _.cloneDeep(new_photo);
                np.CONSTAT_TRAVAUX = 2;
                photos.splice(1, 0, np);
              }

              if (photos[0].CONSTAT_TRAVAUX === 2) {
                _.reverse(photos);
              }

              let pc = _.last(
                _.filter(photos, (pa) => {
                  return pa.CONSTAT_TRAVAUX === 1;
                })
              );

              let sc = _.last(
                _.filter(photos, (pa) => {
                  return pa.CONSTAT_TRAVAUX === 2;
                })
              );

              photos = [pc, sc];

              _.forEach(photos, (p) => {
                if (p.PHOTO === "") {
                  p.PHOTO = b64toBlob(no_photo.image, "image/png");
                  p.NO_PHOTO = true;
                }

                if (p.MONUMENT_GAUCHE === undefined) p.MONUMENT_GAUCHE = "";
                if (p.MONUMENT_DROITE === undefined) p.MONUMENT_DROITE = "";
                if (p.MONUMENT_DEVANT === undefined) p.MONUMENT_DEVANT = "";
                if (p.MONUMENT_DERRIERE === undefined) p.MONUMENT_DERRIERE = "";
                if (p.AGENT === undefined) p.AGENT = "";
                if (p.AGENT !== "") {
                  const result = _.filter(this.state.agentterrain, (at) => {
                    return `${at.NOM} ${at.PRENOM}` === p.AGENT;
                  });
                  if (result.length > 0) {
                    if (p.CONSTAT_TRAVAUX === 1) this.setState({ selected_agent_avt: result[0] });
                    if (p.CONSTAT_TRAVAUX === 2) this.setState({ selected_agent_apt: result[0] });
                  }
                }

                if (p.SIGNATURE_AGENT === undefined) p.SIGNATURE_AGENT = null;
                if (p.SIGNATURE_AGENT !== null) {
                  if (p.CONSTAT_TRAVAUX === 1) {
                    p.SIGNATURE_AGENT.arrayBuffer().then((buffer_signature_agent) => {
                      const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_agent)).toString("base64")}`;
                      if (!_.isEmpty(this.PAD_AVT)) this.PAD_AVT.fromDataURL(b64);
                      if (!_.isEmpty(this.PAD_AVT2)) this.PAD_AVT2.fromDataURL(b64);
                    });
                  }
                  if (p.CONSTAT_TRAVAUX === 2) {
                    p.SIGNATURE_AGENT.arrayBuffer().then((buffer_signature_agent) => {
                      const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_agent)).toString("base64")}`;
                      if (!_.isEmpty(this.PAD_APT)) this.PAD_APT.fromDataURL(b64);
                      if (!_.isEmpty(this.PAD_APT2)) this.PAD_APT2.fromDataURL(b64);
                    });
                  }
                }
                if (p.SOCIETE === undefined) p.SOCIETE = "";
                if (p.SIGNATURE_SOCIETE === undefined) p.SIGNATURE_SOCIETE = null;
                if (p.SIGNATURE_SOCIETE !== null) {
                  if (p.CONSTAT_TRAVAUX === 1) {
                    p.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
                      const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;
                      if (!_.isEmpty(this.PAD_SOC_AVT)) this.PAD_SOC_AVT.fromDataURL(b64);
                      if (!_.isEmpty(this.PAD_SOC_AVT2)) this.PAD_SOC_AVT2.fromDataURL(b64);
                    });
                  }
                  if (p.CONSTAT_TRAVAUX === 2) {
                    p.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
                      const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;
                      if (!_.isEmpty(this.PAD_SOC_APT)) this.PAD_SOC_APT.fromDataURL(b64);
                      if (!_.isEmpty(this.PAD_SOC_APT2)) this.PAD_SOC_APT2.fromDataURL(b64);
                    });
                  }
                }
              });

              this.setState(
                {
                  CONCESS: concess,
                  PHOTOCONC: photos,
                  photoconc_combo_selected_avt: photos[0].MONUMENT_ETAT,
                  photoconc_combo2_selected_avt: photos[0].SEMELLE_ETAT,
                  photoconc_combo_selected_apt: photos[1].MONUMENT_ETAT,
                  photoconc_combo2_selected_apt: photos[1].SEMELLE_ETAT,
                },
                () => {
                  this._check_temporaire();

                  let field = [];
                  _.forEach(this.state.PHOTOCONC, (p) => {
                    const temporaire = GetTemporaireForColor("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", p.IDPHOTOCONC);

                    temporaire.then((temp) => {
                      if (temp.length > 0) {
                        _.forEach(temp, (t) => {
                          let color = "red";

                          if (t.DATE_D_ENVOI_A_GESCIME !== null) {
                            color = "blue";
                          }

                          if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
                            color = "green";
                          }

                          if (t.DATE_TRAITE_DANS_GESCIME !== null) {
                            color = "primary";
                          }

                          var element_temp = t.TABLE_MODIFIE_JSON;
                          element_temp = _.omit(element_temp, ["id"]);

                          let sub_field = {};
                          for (var key in element_temp) {
                            sub_field[key] = color;

                            if (key === (this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC")) sub_field[key] = this.state.IS_ORACLE ? element_temp.IDEVOL_ETAT : element_temp.IDPHOTOCONC;
                          }

                          field.push(sub_field);
                        });

                        this.setState({ champ_modifie: field });
                      }
                    });
                  });

                  this.setState({
                    INTERVEN: interven,
                    DATE_TRAVAUX: interven.DATETRAVAUX,
                    HEURE_TRAVAUX: interven.DATETRAVAUX,
                    DATE_TRAVAUX_FIN: interven.DATETRAVAUX_FIN,
                    HEURE_TRAVAUX_FIN: interven.DATETRAVAUX_FIN,
                  });
                }
              );
            });
          }
        });
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.width !== prevProps.width) {
      if (prevProps.width === "sm" && this.props.width === "md") this.redrawSignature();

      if (prevProps.width === "md" && this.props.width === "sm") this.redrawSignature();
    }
  };

  redrawSignature = () => {
    _.forEach(this.state.PHOTOCONC, (p) => {
      if (p.SIGNATURE_AGENT !== null) {
        if (p.CONSTAT_TRAVAUX === 1) {
          p.SIGNATURE_AGENT.arrayBuffer().then((buffer_signature_agent) => {
            const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_agent)).toString("base64")}`;
            if (!_.isEmpty(this.PAD_AVT)) this.PAD_AVT.fromDataURL(b64);
            if (!_.isEmpty(this.PAD_AVT2)) this.PAD_AVT2.fromDataURL(b64);
          });
        }

        if (p.CONSTAT_TRAVAUX === 2) {
          p.SIGNATURE_AGENT.arrayBuffer().then((buffer_signature_agent) => {
            const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_agent)).toString("base64")}`;
            if (!_.isEmpty(this.PAD_APT)) this.PAD_APT.fromDataURL(b64);
            if (!_.isEmpty(this.PAD_APT2)) this.PAD_APT2.fromDataURL(b64);
          });
        }
      }

      if (p.SIGNATURE_SOCIETE !== null) {
        if (p.CONSTAT_TRAVAUX === 1) {
          p.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
            const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;
            if (!_.isEmpty(this.PAD_SOC_AVT)) this.PAD_SOC_AVT.fromDataURL(b64);
            if (!_.isEmpty(this.PAD_SOC_AVT2)) this.PAD_SOC_AVT2.fromDataURL(b64);
          });
        }

        if (p.CONSTAT_TRAVAUX === 2) {
          p.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
            const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;
            if (!_.isEmpty(this.PAD_SOC_APT)) this.PAD_SOC_APT.fromDataURL(b64);
            if (!_.isEmpty(this.PAD_SOC_APT2)) this.PAD_SOC_APT2.fromDataURL(b64);
          });
        }
      }
    });
  };

  _check_temporaire = () => {
    const photoconc = _.cloneDeep(this.state.PHOTOCONC);

    _.forEach(photoconc, (p) => {
      GetTemporaire("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", p.IDPHOTOCONC).then((temporaire) => {
        if (temporaire !== undefined) {
          if (temporaire.DATE_DE_CREATION !== null && temporaire.DATE_TRAITE_DANS_GESCIME === null && temporaire.TYPE_D_ACTION === 1) {
            p.CONSTAT_TRAVAUX === 1 ? this.setState({ constat_avt_en_cours_de_traiement: true }) : this.setState({ constat_apt_en_cours_de_traiement: true });
          }
        }
      });
    });
  };

  _cree_iso_date = (date) => {
    if (date !== "" && date !== null) {
      if (date.length === 4) {
        let new_date = new Date(Date.UTC(date, 0, 1, 0, 0, 0, 0));

        return new_date.toISOString().slice(0, -5);
      } else {
        if (date.includes("/")) {
          let split_date = date.split("/");

          let new_date = new Date(split_date[2], parseInt(split_date[1]) - 1, split_date[0]);
          new_date.setHours(0 - new_date.getTimezoneOffset() / 60, 0, 0);

          return new_date.toISOString().slice(0, -5);
        } else if (date.includes("1900-01-01")) {
          return null;
        } else {
          return date;
        }
      }
    } else {
      return "";
    }
  };

  _cree_iso_time = (date) => {
    var match = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/g.exec(date);

    if (parseInt(match[1]) <= 1911 && parseInt(match[2]) <= 2 && parseInt(match[3]) <= 11) {
      var minutes = parseInt(match[5]);
      minutes = minutes < 10 ? `0${minutes}` : minutes;

      var newdate = `${match[1]}-${match[2]}-${match[3]}T${match[4]}:${minutes}:${match[6]}`;

      if (`${match[4]}:${minutes}:${match[6]}` === "00:00:01") {
        return null;
      }

      return newdate;
    }

    if (`${match[4]}:${match[5]}:${match[6]}` === "00:00:01") {
      return null;
    }

    return date;
  };

  _cree_chaine_nom_societe = () => {
    let nom = "";

    if (this.state.INTERVEN.INTERVENANT_NOM !== "") {
      nom = this.state.INTERVEN.INTERVENANT_NOM;
    } else {
      if (this.state.SOCIETE !== "" && this.state.SOCIETE !== undefined) {
        nom = this.state.SOCIETE.NOMSOCIETE;
      }
    }

    return nom;
  };

  _cree_chaine_typerest = (typerest) => {
    switch (typerest) {
      case 2:
        return "Opération funéraire";
      case 3:
        return "Autre";
      default:
        return "Travaux";
    }
  };

  _handle_change_interven = (champ) => (event) => {
    if (!event instanceof Date && isNaN(event)) {
      event.preventDefault();
    }

    let INTERVEN = this.state.INTERVEN;

    switch (champ) {
      case "DATE_TRAVAUX":
      case "HEURE_TRAVAUX":
      case "DATE_TRAVAUX_FIN":
      case "HEURE_TRAVAUX_FIN":
        if (event instanceof Date && !isNaN(event)) {
          let newdate = new Date(event);

          if (newdate <= new Date(1911, 2, 11)) {
            newdate.setMinutes(newdate.getMinutes() + 9);
            newdate.setSeconds(newdate.getSeconds() + 21);
          }

          let userTimezoneOffset = newdate.getTimezoneOffset() * 60000;
          newdate = new Date(newdate.getTime() - userTimezoneOffset);

          this.setState({ [champ]: newdate.toISOString().slice(0, -5) });
        } else {
          if (event === null) {
            this.setState({ [champ]: "1900-01-01T00:00:01" });
          }
        }

        break;
      default:
        INTERVEN[champ] = event.target.value;
        break;
    }

    this.setState({ INTERVEN: INTERVEN, disabledForm: false });
  };

  _handle_change_combo_suivi = (event) => {
    let INTERVEN = this.state.INTERVEN;
    INTERVEN.SUIVITRAV = event.target.value;

    this.setState({ INTERVEN: INTERVEN, disabledForm: false });
  };

  _handle_click_photo_travaux = (type) => (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    const tableauPhotoconc = this.state.PHOTOCONC;
    let index = _.findIndex(tableauPhotoconc, ["CONSTAT_TRAVAUX", type === "PHOTO_AVT" ? 1 : 2]);
    index = index === -1 && tableauPhotoconc.length > 0 ? 1 : index === -1 ? 0 : index;
    const modifie_photo = _.cloneDeep(this.state.PHOTOCONC[index]);

    if (modifie_photo !== undefined) {
      ResizeImage(file).then((blob) => {
        modifie_photo.DATEAJOUT = new Date(Date.now()).toISOString().slice(0, -5);
        modifie_photo.PHOTO = blob;
        delete modifie_photo.NO_PHOTO;

        tableauPhotoconc[index] = modifie_photo;

        this.setState({
          PHOTOCONC: tableauPhotoconc,
          disabledForm: false,
        });
      });
    } else {
      ResizeImage(file).then((blob) => {
        const photoconc = {
          ABANDON_1: false,
          ABANDON_2: false,
          COCLEUNIK: tableauPhotoconc[index].COCLEUNIK,
          CONSTAT_TRAVAUX: type === "PHOTO_AVT" ? 1 : 2,
          DATEAJOUT: new Date(Date.now()).toISOString().slice(0, -5),
          I0CLEUNIK: tableauPhotoconc[index].I0CLEUNIK,
          MONUMENT_ETAT: tableauPhotoconc[index].MONUMENT_ETAT,
          NOTES: tableauPhotoconc[index].NOTES,
          PHOTO: blob,
          PRINCIPAL: tableauPhotoconc[index].PRINCIPAL,
          SEMELLE_ETAT: tableauPhotoconc[index].SEMELLE_ETAT,
          MONUMENT_GAUCHE: tableauPhotoconc[index].MONUMENT_GAUCHE,
          MONUMENT_DROITE: tableauPhotoconc[index].MONUMENT_DROITE,
          MONUMENT_DEVANT: tableauPhotoconc[index].MONUMENT_DEVANT,
          MONUMENT_DERRIERE: tableauPhotoconc[index].MONUMENT_DERRIERE,
          AGENT: tableauPhotoconc[index].AGENT,
          SIGNATURE_AGENT: tableauPhotoconc[index].SIGNATURE_AGENT,
          SOCIETE: tableauPhotoconc[index].SOCIETE,
          SIGNATURE_SOCIETE: tableauPhotoconc[index].SIGNATURE_SOCIETE,
        };

        tableauPhotoconc[index] = photoconc;

        this.setState({
          PHOTOCONC: tableauPhotoconc,
          disabledForm: false,
        });
      });
    }
  };

  _AjoutTemporaire = (temporaire) => {
    AddTemporaire(temporaire)
      .then(() => {
        this.setState({ REDIRECT: temporaire.TABLE_MODIFIE_JSON.I0CLEUNIK });
      })
      .catch((Err) => handleError(Err));
  };

  _MiseAJourTemporaire = (temporaire) => {
    UpdateTemporaire(temporaire)
      .then(() => {
        this.setState({ REDIRECT: temporaire.TABLE_MODIFIE_JSON.I0CLEUNIK });
      })
      .catch((Err) => handleError(Err));
  };

  _MiseAJourInterven = (INTERVEN, INTERVEN_TEMPORAIRE, MAJ_PHOTO) => {
    const modifie_interven = UpdateInterven(INTERVEN);
    modifie_interven.then(() => {
      if (MAJ_PHOTO) {
        this._MiseAJourPhotoconc(false);
      }

      GetTemporaireNonEnvoye("INTERVEN", "I0CLEUNIK", INTERVEN.I0CLEUNIK).then((temporaire) => {
        if (this.state.IS_ORACLE) {
          INTERVEN_TEMPORAIRE = _.omit(INTERVEN_TEMPORAIRE, ["EMPL_DEF_OU_CONCESSION"]);

          for (var key in INTERVEN_TEMPORAIRE) {
            if (key === "INTERVENANT_NOM") {
              INTERVEN_TEMPORAIRE.NOMCIE = INTERVEN_TEMPORAIRE[key];
              delete INTERVEN_TEMPORAIRE[key];
            }

            if (key === "DATETRAVAUX") {
              const time = INTERVEN_TEMPORAIRE[key].split("T")[1].split(":");
              time.pop();

              INTERVEN_TEMPORAIRE.HEUREARRIVEE = time.join(":");
              INTERVEN_TEMPORAIRE.DATETRAVAUX = INTERVEN_TEMPORAIRE[key].split("T")[0];
            }

            if (key === "DATETRAVAUX_FIN") {
              const time = INTERVEN_TEMPORAIRE[key].split("T")[1].split(":");
              time.pop();

              INTERVEN_TEMPORAIRE.HEUREDEPART = time.join(":");
              INTERVEN_TEMPORAIRE.DATEFINTRAVAUX = INTERVEN_TEMPORAIRE[key].split("T")[0];
              delete INTERVEN_TEMPORAIRE[key];
            }

            if (key === "SUIVITRAV") {
              INTERVEN_TEMPORAIRE.SUIVI_TRAVAUX = INTERVEN_TEMPORAIRE[key];
              delete INTERVEN_TEMPORAIRE[key];
            }

            if (key === "SOCLEUNIK") {
              INTERVEN_TEMPORAIRE.IDSOCIETE = INTERVEN_TEMPORAIRE[key];
              delete INTERVEN_TEMPORAIRE[key];
            }

            if (key === "EMPL_TYPE") {
              INTERVEN_TEMPORAIRE.TYPE_TOMBE_TXT = INTERVEN_TEMPORAIRE[key];
              delete INTERVEN_TEMPORAIRE[key];
            }
          }
        } else {
          INTERVEN_TEMPORAIRE = _.omit(INTERVEN_TEMPORAIRE, ["IDMOUVEMENT"]);

          for (var key2 in INTERVEN_TEMPORAIRE) {
            if (key2 === "DATETRAVAUX") {
              const time = INTERVEN_TEMPORAIRE[key2].split("T")[1].split(":");
              time.pop();

              INTERVEN_TEMPORAIRE.HEUREDEBUT = time.join(":");
              INTERVEN_TEMPORAIRE.DATETRAVAUX = INTERVEN_TEMPORAIRE[key2].split("T")[0];
            }

            if (key2 === "DATETRAVAUX_FIN") {
              const time = INTERVEN_TEMPORAIRE[key2].split("T")[1].split(":");
              time.pop();

              INTERVEN_TEMPORAIRE.HEUREDEPART = time.join(":");
              INTERVEN_TEMPORAIRE.DATETRAVAUX_FIN = INTERVEN_TEMPORAIRE[key2].split("T")[0];
            }
          }
        }

        if (temporaire !== undefined) {
          var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, INTERVEN_TEMPORAIRE);

          temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;

          this._MiseAJourTemporaire(temporaire);
        } else {
          const temporaire = CreeTemporaire(
            this.IDCLIENT,
            this.ID_TABLETTE,
            this.ID_UTIL,
            INTERVEN.EMPL_DEF_OU_CONCESSION ? `Modification de l'intervention : ${INTERVEN.EMPL_DEF_OU_CONCESSION}` : `Modification de l'intervention numéro : ${INTERVEN.I0CLEUNIK}`,
            "INTERVEN",
            2,
            INTERVEN_TEMPORAIRE,
            this.state.INTERVEN_SAUVEGARDE
          );

          this._AjoutTemporaire(temporaire);
        }
      });
    });
  };

  _MiseAJourPhotoconc = (withRedirect) => {
    let PHOTOCONC = _.cloneDeep(this.state.PHOTOCONC);
    let PHOTOCONC_SAUVEGARDE = _.cloneDeep(this.state.PHOTOCONC_SAUVEGARDE);

    const principal = _.filter(PHOTOCONC, "PRINCIPAL", 1)[0];

    if (principal !== undefined) {
      GetPhotoconcByCocleunik(principal.COCLEUNIK).then((allPhotos) => {
        for (let index = 0; index < allPhotos.length; index++) {
          let element = allPhotos[index];

          if (element.IDPHOTOCONC !== principal.IDPHOTOCONC) {
            element.PRINCIPAL = false;

            const modifie_photo = UpdatePhotoconc(element);
            modifie_photo
              .then(() => {
                GetTemporaireNonEnvoye("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", element.IDPHOTOCONC).then((temporaire) => {
                  if (element.PHOTO !== undefined && element.PHOTO !== "") {
                    element.PHOTO.arrayBuffer().then((buffer) => {
                      element.PHOTO = `data:image/jpeg;base64,${Buffer.from(new Uint8Array(buffer)).toString("base64")}`;

                      if (this.state.IS_ORACLE) {
                        for (var key in element) {
                          if (key === "IDPHOTOCONC") {
                            element.IDEVOL_ETAT = element[key];
                            delete element[key];
                          }

                          if (key === "DATEAJOUT") {
                            element.DATE_EVOL = element[key];
                            delete element[key];
                          }

                          if (key === "PRINCIPAL") {
                            element.REFERENTE = element[key];
                            delete element[key];
                          }

                          if (key === "NOTES") {
                            element.ETAT_EVOL = element[key];
                            delete element[key];
                          }
                        }
                      } else {
                        element = _.omit(element, ["I0CLEUNIK"]);
                      }

                      if (temporaire !== undefined) {
                        var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, element);

                        temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;

                        UpdateTemporaire(temporaire).catch((Err) => handleError(Err));
                      } else {
                        const temporaire = CreeTemporaire(
                          this.IDCLIENT,
                          this.ID_TABLETTE,
                          this.ID_UTIL,
                          `Modification de la photographie ${this.state.IS_ORACLE ? element.IDEVOL_ETAT : element.IDPHOTOCONC}.jpg`,
                          "PHOTOCONC",
                          2,
                          element,
                          _.find(this.state.PHOTOCONC_SAUVEGARDE, ["id", element.id])
                        );

                        AddTemporaire(temporaire).catch((Err) => handleError(Err));
                      }
                    });
                  }
                });
              })
              .catch((Err) => handleError(Err));
          }
        }
      });
    }

    PHOTOCONC_SAUVEGARDE = _.filter(PHOTOCONC_SAUVEGARDE, (p) => {
      return p.CONSTAT_TRAVAUX !== undefined && p.CONSTAT_TRAVAUX > 0;
    });
    PHOTOCONC_SAUVEGARDE = _.orderBy(PHOTOCONC_SAUVEGARDE, ["CONSTAT_TRAVAUX"], ["asc"]);

    AjoutePhotos(PHOTOCONC, PHOTOCONC_SAUVEGARDE, this.state.IS_ORACLE, this.IDCLIENT, this.ID_TABLETTE, this.ID_UTIL).then(() => {
      if (withRedirect) {
        this.setState({
          REDIRECT: this.state.INTERVEN.I0CLEUNIK,
        });
      }
    });
  };

  _PhotoBase64 = (PHOTOCONC) => {
    const p1 = new Promise((resolve, reject) => {
      let return_photo = [];

      PHOTOCONC = _.filter(PHOTOCONC, (p) => {
        return p.CONSTAT_TRAVAUX !== undefined && p.CONSTAT_TRAVAUX > 0;
      });
      PHOTOCONC = _.orderBy(PHOTOCONC, ["CONSTAT_TRAVAUX"], ["asc"]);

      _.forEach(PHOTOCONC, (p, i) => {
        if (p.PHOTO !== "") {
          p.PHOTO.arrayBuffer().then((buffer_photo) => {
            if (`${Buffer.from(new Uint8Array(buffer_photo)).toString("base64")}` === no_photo.image) {
              p.PHOTO = "";
            } else {
              p.PHOTO = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_photo)).toString("base64")}`;
            }

            if (p.SIGNATURE_AGENT !== null) {
              p.SIGNATURE_AGENT.arrayBuffer().then((buffer_photo2) => {
                p.SIGNATURE_AGENT = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_photo2)).toString("base64")}`;

                if (p.SIGNATURE_SOCIETE !== null) {
                  p.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_photo3) => {
                    p.SIGNATURE_SOCIETE = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_photo3)).toString("base64")}`;

                    return_photo.push(p);
                  });
                } else {
                  return_photo.push(p);
                }
              });
            } else if (p.SIGNATURE_SOCIETE !== null) {
              p.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_photo3) => {
                p.SIGNATURE_SOCIETE = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_photo3)).toString("base64")}`;

                return_photo.push(p);
              });
            } else {
              return_photo.push(p);
            }
          });
        }

        if (i === PHOTOCONC.length - 1) return resolve(return_photo);
      });
    });

    return p1.then((return_photo) => {
      return return_photo;
    });
  };

  _handle_submit_form_travaux = (event) => {
    event.preventDefault();

    if (this.state.disabledForm) return;

    this.setState({ disabledForm: true }, () => {
      let INTERVEN = this.state.INTERVEN;

      let datetravaux = new Date(this.state.DATE_TRAVAUX);
      const heuretravaux = new Date(this.state.HEURE_TRAVAUX);
      let datetravauxfin = new Date(this.state.DATE_TRAVAUX_FIN);
      const heuretravauxfin = new Date(this.state.HEURE_TRAVAUX_FIN);

      datetravaux.setHours(heuretravaux.getHours());
      datetravaux.setMinutes(heuretravaux.getMinutes());
      datetravaux.setSeconds(heuretravaux.getSeconds());

      INTERVEN.DATETRAVAUX = Cree_iso_date2(datetravaux);

      datetravauxfin.setHours(heuretravauxfin.getHours());
      datetravauxfin.setMinutes(heuretravauxfin.getMinutes());
      datetravauxfin.setSeconds(heuretravauxfin.getSeconds());
      INTERVEN.DATETRAVAUX_FIN = Cree_iso_date2(datetravauxfin);

      if (datetravauxfin > new Date(1900, 0, 1, 0, 0, 1) && datetravaux > new Date(1900, 0, 1, 0, 0, 1)) {
        if (datetravaux > datetravauxfin) {
          this.setState({ errorMessage: "La date de début ne peut pas être supérieure à la date de fin" });
        } else if (datetravauxfin < datetravaux) {
          this.setState({ errorMessage: "La date de fin ne peut pas être inférieure à la date de début" });
        } else {
          const PHOTOCONC = this.state.PHOTOCONC;

          if (this.props.id_travaux !== undefined) {
            let changed = !_.isEqual(INTERVEN, this.state.INTERVEN_SAUVEGARDE);

            const PHOTOB64 = this._PhotoBase64(_.cloneDeep(PHOTOCONC));
            const PHOTOSAUVEB64 = this._PhotoBase64(_.cloneDeep(this.state.PHOTOCONC_SAUVEGARDE));

            setTimeout(() => {
              let changed_photoconc = !_.isEqual(PHOTOB64, PHOTOSAUVEB64);

              if (changed) {
                let diff = differenceObject(INTERVEN, this.state.INTERVEN_SAUVEGARDE);
                diff = _.assign(
                  {
                    id: INTERVEN.id,
                    I0CLEUNIK: INTERVEN.I0CLEUNIK,
                  },
                  diff
                );
                this._MiseAJourInterven(INTERVEN, diff, changed_photoconc);
              }

              if (!changed && changed_photoconc) {
                this._MiseAJourPhotoconc(true);
              }
            }, 500);
          }
        }
      } else {
        const PHOTOCONC = this.state.PHOTOCONC;

        if (this.props.id_travaux !== undefined) {
          let changed = !_.isEqual(INTERVEN, this.state.INTERVEN_SAUVEGARDE);

          const PHOTOB64 = this._PhotoBase64(_.cloneDeep(PHOTOCONC));
          const PHOTOSAUVEB64 = this._PhotoBase64(_.cloneDeep(this.state.PHOTOCONC_SAUVEGARDE));

          PHOTOB64.then((photo64) => {
            PHOTOSAUVEB64.then((photosauv64) => {
              let changed_photoconc = !_.isEqual(photo64, photosauv64);

              if (changed) {
                let diff = differenceObject(INTERVEN, this.state.INTERVEN_SAUVEGARDE);

                diff = _.assign(
                  {
                    id: INTERVEN.id,
                    I0CLEUNIK: INTERVEN.I0CLEUNIK,
                  },
                  diff
                );
                this._MiseAJourInterven(INTERVEN, diff, changed_photoconc);
              }

              if (!changed && changed_photoconc) {
                this._MiseAJourPhotoconc(true);
              }
            });
          });
        }
      }
    });
  };

  _find_color = (modifie, idphotoconc) => {
    const temp = _.find(modifie, ["IDPHOTOCONC", idphotoconc]) || _.find(modifie, ["IDEVOL_ETAT", idphotoconc]);

    let color = [];
    for (var key in temp) {
      if (typeof temp[key] === "string") {
        if (!_.includes(color, temp[key])) color.push(temp[key]);
      }
    }

    if (_.includes(color, "red")) return "red";
    if (_.includes(color, "blue")) return "blue";
    if (_.includes(color, "green")) return "green";

    return "grey";
  };

  _listen_speech_recognition = (index, refInput) => {
    let photoconc = this.state.PHOTOCONC;
    const cursor = refInput.current.selectionStart;

    if (photoconc[index] !== undefined) {
      if (photoconc[index].NO_PHOTO === true) {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
          if (asRight) {
            if (recognition !== undefined) {
              if (this.state.SPEECH_LISTENING_CAVT) {
                recognition.start();
                recognition.onend = () => {
                  recognition.stop();

                  this.setState({ SPEECH_LISTENING_CAVT: false });
                };
              } else {
                recognition.stop();
              }

              if (this.state.SPEECH_LISTENING_CAPT) {
                recognition.start();
                recognition.onend = () => {
                  recognition.stop();

                  this.setState({ SPEECH_LISTENING_CAPT: false });
                };
              } else {
                recognition.stop();
              }

              const photoconc = this.state.PHOTOCONC;
              let finalTranscript = photoconc[index].NOTES;
              recognition.onresult = (event) => {
                for (let i = event.resultIndex; i < event.results.length; i++) {
                  const transcript = event.results[i][0].transcript;

                  if (event.results[i].isFinal) {
                    if (refInput.current !== null) {
                      finalTranscript = `${finalTranscript.substr(0, cursor).trim()} ${transcript} ${finalTranscript.substr(cursor).trim()}`;
                    } else {
                      finalTranscript += transcript;
                    }
                  }
                }

                if (photoconc[index] !== undefined) {
                  photoconc[index].NOTES = finalTranscript;
                }

                this.setState({ PHOTOCONC: photoconc, disabledForm: false });
              };

              recognition.onerror = (event) => {
                console.log("Erreur lors de la reconaissance vocale: " + event.error);
              };
            }
          } else {
            if (this.state.SPEECH_LISTENING_CAVT) this.setState({ SPEECH_LISTENING_CAVT: false });
            if (this.state.SPEECH_LISTENING_CAPT) this.setState({ SPEECH_LISTENING_CAPT: false });

            this.setState({ droit_util_ajout_photoconc: true });
          }
        });
      } else {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
          if (asRight) {
            if (recognition !== undefined) {
              if (this.state.SPEECH_LISTENING_CAVT) {
                recognition.start();
                recognition.onend = () => {
                  recognition.stop();

                  this.setState({ SPEECH_LISTENING_CAVT: false });
                };
              } else {
                recognition.stop();
              }

              if (this.state.SPEECH_LISTENING_CAPT) {
                recognition.start();
                recognition.onend = () => {
                  recognition.stop();

                  this.setState({ SPEECH_LISTENING_CAPT: false });
                };
              } else {
                recognition.stop();
              }

              const photoconc = this.state.PHOTOCONC;
              let finalTranscript = photoconc[index].NOTES;
              recognition.onresult = (event) => {
                for (let i = event.resultIndex; i < event.results.length; i++) {
                  const transcript = event.results[i][0].transcript;

                  if (event.results[i].isFinal) {
                    if (refInput.current !== null) {
                      finalTranscript = `${finalTranscript.substr(0, cursor).trim()} ${transcript} ${finalTranscript.substr(cursor).trim()}`;
                    } else {
                      finalTranscript += transcript;
                    }
                  }
                }

                if (photoconc[index] !== undefined) {
                  photoconc[index].NOTES = finalTranscript;
                }

                this.setState({ PHOTOCONC: photoconc, disabledForm: false });
              };

              recognition.onerror = (event) => {
                console.log("Erreur lors de la reconaissance vocale: " + event.error);
              };
            }
          } else {
            if (this.state.SPEECH_LISTENING_CAVT) this.setState({ SPEECH_LISTENING_CAVT: false });
            if (this.state.SPEECH_LISTENING_CAPT) this.setState({ SPEECH_LISTENING_CAPT: false });

            this.setState({ droit_util_modif_photoconc: true });
          }
        });
      }
    }
  };

  _handle_change_photoconc_notes = (index, refInput) => (event) => {
    let photoconc = this.state.PHOTOCONC;
    const value = event.target.value;
    let cursor;
    if (event.target !== undefined) cursor = event.target.selectionStart;

    if (photoconc[index] !== undefined) {
      if (photoconc[index].NO_PHOTO === true) {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
          if (asRight) {
            photoconc[index].NOTES = value;

            this.setState({ PHOTOCONC: photoconc, disabledForm: false }, () => {
              if (refInput.current !== null) refInput.current.selectionEnd = cursor;
            });
          } else {
            this.setState({ droit_util_ajout_photoconc: true });
          }
        });
      } else {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
          if (asRight) {
            photoconc[index].NOTES = value;

            this.setState({ PHOTOCONC: photoconc, disabledForm: false }, () => {
              if (refInput.current !== null) refInput.current.selectionEnd = cursor;
            });
          } else {
            this.setState({ droit_util_modif_photoconc: true });
          }
        });
      }
    }
  };

  _handle_change_photoconc_etat_monument_strasbourg = (index) => (event) => {
    event.preventDefault();

    const photoconc = this.state.PHOTOCONC;
    const value = event.target.value;

    if (photoconc[index] !== undefined) {
      if (photoconc[index].NO_PHOTO === true) {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
          if (asRight) {
            photoconc[index].MONUMENT_ETAT = value;

            this.setState({ photoconc_combo_selected_avt: value, PHOTOCONC: photoconc, disabledForm: false });
          } else {
            this.setState({ droit_util_ajout_photoconc: true });
          }
        });
      } else {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
          if (asRight) {
            photoconc[index].MONUMENT_ETAT = value;

            this.setState({ photoconc_combo_selected_avt: value, PHOTOCONC: photoconc, disabledForm: false });
          } else {
            this.setState({ droit_util_modif_photoconc: true });
          }
        });
      }
    }
  };

  _handle_change_photoconc_etat_monument = (event, newValue, index) => {
    event && event.preventDefault();

    if (event && event.key !== "Enter") {
      const photoconc = this.state.PHOTOCONC;

      if (photoconc[index] !== undefined) {
        if (photoconc[index].NO_PHOTO === true) {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
            if (asRight) {
              const photoconc = this.state.PHOTOCONC;
              photoconc[index].MONUMENT_ETAT = newValue.MONUMENT_ETAT;

              this.setState({ photoconc_combo_selected_apt: newValue, PHOTOCONC: photoconc, disabledForm: false });
            } else {
              this.setState({ droit_util_ajout_photoconc: true });
            }
          });
        } else {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
            if (asRight) {
              const photoconc = this.state.PHOTOCONC;
              photoconc[index].MONUMENT_ETAT = newValue.MONUMENT_ETAT;

              this.setState({ photoconc_combo_selected_apt: newValue, PHOTOCONC: photoconc, disabledForm: false });
            } else {
              this.setState({ droit_util_modif_photoconc: true });
            }
          });
        }
      }
    }
  };

  _handle_inputchange_photoconc_etat_monument = (event, newValue, index, refInput) => {
    event && event.preventDefault();

    if (event && event.key !== "Enter") {
      const photoconc = this.state.PHOTOCONC;
      let cursor;
      if (event.target !== undefined) cursor = event.target.selectionStart;

      if (photoconc[index] !== undefined) {
        if (photoconc[index].NO_PHOTO === true) {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
            if (asRight) {
              const result = _.filter(this.state.photoconc_pour_combo, (ppc) => {
                return ppc.MONUMENT_ETAT === newValue;
              });

              if (result.length === 1) {
                photoconc[index].MONUMENT_ETAT = result[0].MONUMENT_ETAT;

                this.setState({ photoconc_combo_selected_apt: result[0], PHOTOCONC: photoconc, disabledForm: false });
              } else {
                if (event !== null) photoconc[index].MONUMENT_ETAT = newValue;

                this.setState({ photoconc_combo_selected_apt: null, PHOTOCONC: photoconc, disabledForm: false }, () => {
                  if (refInput.current.children[1].children[0] !== null) refInput.current.children[1].children[0].selectionEnd = cursor;
                });
              }
            } else {
              this.setState({ droit_util_ajout_photoconc: true });
            }
          });
        } else {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
            if (asRight) {
              const result = _.filter(this.state.photoconc_pour_combo, (ppc) => {
                return ppc.MONUMENT_ETAT === newValue;
              });

              if (result.length === 1) {
                photoconc[index].MONUMENT_ETAT = result[0].MONUMENT_ETAT;

                this.setState({ photoconc_combo_selected_apt: result[0], PHOTOCONC: photoconc, disabledForm: false });
              } else {
                if (event !== null) photoconc[index].MONUMENT_ETAT = newValue;

                this.setState({ photoconc_combo_selected_apt: null, PHOTOCONC: photoconc, disabledForm: false }, () => {
                  if (refInput.current.children[1].children[0] !== null) refInput.current.children[1].children[0].selectionEnd = cursor;
                });
              }
            } else {
              this.setState({ droit_util_modif_photoconc: true });
            }
          });
        }
      }
    }
  };

  _handle_change_photoconc_etat_semelle_strasbourg = (index) => (event) => {
    event.preventDefault();

    const photoconc = this.state.PHOTOCONC;
    const value = event.target.value;

    if (photoconc[index] !== undefined) {
      if (photoconc[index].NO_PHOTO === true) {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
          if (asRight) {
            photoconc[index].SEMELLE_ETAT = value;

            this.setState({ photoconc_combo2_selected_avt: value, PHOTOCONC: photoconc, disabledForm: false });
          } else {
            this.setState({ droit_util_ajout_photoconc: true });
          }
        });
      } else {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
          if (asRight) {
            photoconc[index].SEMELLE_ETAT = value;

            this.setState({ photoconc_combo2_selected_avt: value, PHOTOCONC: photoconc, disabledForm: false });
          } else {
            this.setState({ droit_util_modif_photoconc: true });
          }
        });
      }
    }
  };

  _handle_change_photoconc_etat_semelle = (event, newValue, index) => {
    event && event.preventDefault();

    if (event && event.key !== "Enter") {
      const photoconc = this.state.PHOTOCONC;

      if (photoconc[index] !== undefined) {
        if (photoconc[index].NO_PHOTO === true) {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
            if (asRight) {
              photoconc[index].SEMELLE_ETAT = newValue.SEMELLE_ETAT;

              this.setState({ photoconc_combo2_selected_avt: newValue, PHOTOCONC: photoconc, disabledForm: false });
            } else {
              this.setState({ droit_util_ajout_photoconc: true });
            }
          });
        } else {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
            if (asRight) {
              photoconc[index].SEMELLE_ETAT = newValue.SEMELLE_ETAT;

              this.setState({ photoconc_combo2_selected_avt: newValue, PHOTOCONC: photoconc, disabledForm: false });
            } else {
              this.setState({ droit_util_modif_photoconc: true });
            }
          });
        }
      }
    }
  };

  _handle_inputchange_photoconc_etat_semelle = (event, newValue, index, refInput) => {
    event && event.preventDefault();

    if (event && event.key !== "Enter") {
      const photoconc = this.state.PHOTOCONC;
      let cursor;
      if (event.target !== undefined) cursor = event.target.selectionStart;

      if (photoconc[index] !== undefined) {
        if (photoconc[index].NO_PHOTO === true) {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
            if (asRight) {
              const result = _.filter(this.state.photoconc_pour_combo2, (ppc) => {
                return ppc.SEMELLE_ETAT === newValue;
              });

              if (result.length === 1) {
                photoconc[index].SEMELLE_ETAT = result[0].SEMELLE_ETAT;

                this.setState({ photoconc_combo2_selected_avt: result[0], PHOTOCONC: photoconc, disabledForm: false });
              } else {
                if (event !== null) photoconc[index].SEMELLE_ETAT = newValue;

                this.setState({ photoconc_combo2_selected_avt: null, PHOTOCONC: photoconc, disabledForm: false }, () => {
                  if (refInput.current.children[1].children[0] !== null) refInput.current.children[1].children[0].selectionEnd = cursor;
                });
              }
            } else {
              this.setState({ droit_util_ajout_photoconc: true });
            }
          });
        } else {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
            if (asRight) {
              const result = _.filter(this.state.photoconc_pour_combo2, (ppc) => {
                return ppc.SEMELLE_ETAT === newValue;
              });

              if (result.length === 1) {
                photoconc[index].SEMELLE_ETAT = result[0].SEMELLE_ETAT;

                this.setState({ photoconc_combo2_selected_avt: result[0], PHOTOCONC: photoconc, disabledForm: false });
              } else {
                if (event !== null) photoconc[index].SEMELLE_ETAT = newValue;

                this.setState({ photoconc_combo2_selected_avt: null, PHOTOCONC: photoconc, disabledForm: false }, () => {
                  if (refInput.current.children[1].children[0] !== null) refInput.current.children[1].children[0].selectionEnd = cursor;
                });
              }
            } else {
              this.setState({ droit_util_modif_photoconc: true });
            }
          });
        }
      }
    }
  };

  _handle_change_combo_monument = (index, cote) => (event) => {
    const photoconc = this.state.PHOTOCONC;
    const value = event.target.value;

    if (photoconc[index] !== undefined) {
      if (photoconc[index].NO_PHOTO === true) {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
          if (asRight) {
            photoconc[index][cote] = value;

            this.setState({ PHOTOCONC: photoconc, disabledForm: false });
          } else {
            this.setState({ droit_util_ajout_photoconc: true });
          }
        });
      } else {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
          if (asRight) {
            photoconc[index][cote] = value;

            this.setState({ PHOTOCONC: photoconc, disabledForm: false });
          } else {
            this.setState({ droit_util_modif_photoconc: true });
          }
        });
      }
    }
  };

  _handle_change_photoconc_agent = (event, newValue, index, pad) => {
    event && event.preventDefault();

    if (event && event.key !== "Enter") {
      const photoconc = this.state.PHOTOCONC;

      if (photoconc[index] !== undefined) {
        if (photoconc[index].NO_PHOTO === true) {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
            if (asRight) {
              photoconc[index].AGENT = `${newValue.NOM} ${newValue.PRENOM}`;

              this.setState({ selected_agent_avt: newValue, PHOTOCONC: photoconc, disabledForm: false }, () => {
                pad.on();
              });
            } else {
              this.setState({ droit_util_ajout_photoconc: true });
            }
          });
        } else {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
            if (asRight) {
              photoconc[index].AGENT = `${newValue.NOM} ${newValue.PRENOM}`;

              this.setState({ selected_agent_avt: newValue, PHOTOCONC: photoconc, disabledForm: false }, () => {
                pad.on();
              });
            } else {
              this.setState({ droit_util_modif_photoconc: true });
            }
          });
        }
      }
    }
  };

  _handle_inputchange_photoconc_agent = (event, newValue, index, pad, refInput) => {
    event && event.preventDefault();

    if (event && event.key !== "Enter") {
      const photoconc = this.state.PHOTOCONC;
      let cursor;
      if (event.target !== undefined) cursor = event.target.selectionStart;

      if (photoconc[index] !== undefined) {
        if (photoconc[index].NO_PHOTO === true) {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
            if (asRight) {
              const result = _.filter(this.state.agentterrain, (at) => {
                return `${at.NOM} ${at.PRENOM}` === newValue;
              });

              if (result.length === 1) {
                photoconc[index].AGENT = `${result[0].NOM} ${result[0].PRENOM}`;

                this.setState({ selected_agent_avt: result[0], PHOTOCONC: photoconc, disabledForm: false }, () => {
                  pad.on();
                });
              } else {
                if (event !== null) photoconc[index].AGENT = newValue;

                this.setState({ selected_agent_avt: null, PHOTOCONC: photoconc, disabledForm: false }, () => {
                  pad.on();
                  if (refInput.current !== null) refInput.current.selectionEnd = cursor;
                });
              }
            } else {
              this.setState({ droit_util_ajout_photoconc: true });
            }
          });
        } else {
          CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
            if (asRight) {
              const result = _.filter(this.state.agentterrain, (at) => {
                return `${at.NOM} ${at.PRENOM}` === newValue;
              });

              if (result.length === 1) {
                photoconc[index].AGENT = `${result[0].NOM} ${result[0].PRENOM}`;

                this.setState({ selected_agent_avt: result[0], PHOTOCONC: photoconc, disabledForm: false }, () => {
                  pad.on();
                });
              } else {
                if (event !== null) photoconc[index].AGENT = newValue;

                this.setState({ selected_agent_avt: null, PHOTOCONC: photoconc, disabledForm: false }, () => {
                  pad.on();
                  if (refInput.current !== null) refInput.current.selectionEnd = cursor;
                });
              }
            } else {
              this.setState({ droit_util_modif_photoconc: true });
            }
          });
        }
      }
    }
  };

  _handle_inputchange_photoconc_societe = (index, pad) => (event) => {
    const photoconc = this.state.PHOTOCONC;
    const value = event.target.value;

    if (photoconc[index] !== undefined) {
      if (photoconc[index].NO_PHOTO === true) {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
          if (asRight) {
            photoconc[index].SOCIETE = value;

            this.setState({ PHOTOCONC: photoconc, disabledForm: false }, () => {
              pad.on();
            });
          } else {
            this.setState({ droit_util_ajout_photoconc: true });
          }
        });
      } else {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
          if (asRight) {
            photoconc[index].SOCIETE = value;

            this.setState({ PHOTOCONC: photoconc, disabledForm: false }, () => {
              pad.on();
            });
          } else {
            this.setState({ droit_util_modif_photoconc: true });
          }
        });
      }
    }
  };

  _handle_change_photoconc = (index, buttonref) => (event) => {
    const photoconc = this.state.PHOTOCONC;

    if (photoconc[index] !== undefined) {
      if (photoconc[index].NO_PHOTO === true) {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITAJOUT").then((asRight) => {
          if (asRight) {
            buttonref.current.click();
          } else {
            this.setState({ droit_util_ajout_photoconc: true });
          }
        });
      } else {
        CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight2) => {
          if (asRight2) {
            buttonref.current.click();
          } else {
            this.setState({ droit_util_modif_photoconc: true });
          }
        });
      }
    }
  };

  _clear_pad = (type) => {
    this[type].clear();
  };

  _update_signature = () => {
    if (this.state.CONCESS !== "") {
      if (this.state.IS_ORACLE) {
        GetPhotoconcByI0cleunik(this.state.INTERVEN.I0CLEUNIK).then((photos) => {
          _.forEach(photos, (p) => {
            if (p.SIGNATURE_AGENT !== null) {
              if (p.CONSTAT_TRAVAUX === 1) {
                p.SIGNATURE_AGENT.arrayBuffer().then((buffer_signature_agent) => {
                  const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_agent)).toString("base64")}`;

                  if (!_.isEmpty(this.PAD_AVT)) this.PAD_AVT.fromDataURL(b64);
                  if (!_.isEmpty(this.PAD_AVT2)) this.PAD_AVT2.fromDataURL(b64);
                });
              }

              if (p.CONSTAT_TRAVAUX === 2) {
                p.SIGNATURE_AGENT.arrayBuffer().then((buffer_signature_agent) => {
                  const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_agent)).toString("base64")}`;

                  if (!_.isEmpty(this.PAD_APT)) this.PAD_APT.fromDataURL(b64);
                  if (!_.isEmpty(this.PAD_APT2)) this.PAD_APT2.fromDataURL(b64);
                });
              }
            }

            if (p.SIGNATURE_SOCIETE !== null) {
              if (p.CONSTAT_TRAVAUX === 1) {
                p.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
                  const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;

                  if (!_.isEmpty(this.PAD_SOC_AVT)) this.PAD_SOC_AVT.fromDataURL(b64);
                  if (!_.isEmpty(this.PAD_SOC_AVT2)) this.PAD_SOC_AVT2.fromDataURL(b64);
                });
              }

              if (p.CONSTAT_TRAVAUX === 2) {
                p.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
                  const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;

                  if (!_.isEmpty(this.PAD_SOC_APT)) this.PAD_SOC_APT.fromDataURL(b64);
                  if (!_.isEmpty(this.PAD_SOC_APT2)) this.PAD_SOC_APT2.fromDataURL(b64);
                });
              }
            }
          });
        });
      } else {
        GetPhotoconcByI0cleunik(this.state.INTERVEN.I0CLEUNIK).then((photos) => {
          _.forEach(photos, (p) => {
            if (p.SIGNATURE_AGENT !== null) {
              if (p.SIGNATURE_AGENT !== null) {
                if (p.CONSTAT_TRAVAUX === 1) {
                  p.SIGNATURE_AGENT.arrayBuffer().then((buffer_signature_agent) => {
                    const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_agent)).toString("base64")}`;

                    if (!_.isEmpty(this.PAD_AVT)) this.PAD_AVT.fromDataURL(b64);
                    if (!_.isEmpty(this.PAD_AVT2)) this.PAD_AVT2.fromDataURL(b64);
                  });
                }

                if (p.CONSTAT_TRAVAUX === 2) {
                  p.SIGNATURE_AGENT.arrayBuffer().then((buffer_signature_agent) => {
                    const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_agent)).toString("base64")}`;

                    if (!_.isEmpty(this.PAD_APT)) this.PAD_APT.fromDataURL(b64);
                    if (!_.isEmpty(this.PAD_APT2)) this.PAD_APT2.fromDataURL(b64);
                  });
                }
              }

              if (p.SIGNATURE_SOCIETE !== null) {
                if (p.CONSTAT_TRAVAUX === 1) {
                  p.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
                    const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;

                    if (!_.isEmpty(this.PAD_SOC_AVT)) this.PAD_SOC_AVT.fromDataURL(b64);
                    if (!_.isEmpty(this.PAD_SOC_AVT2)) this.PAD_SOC_AVT2.fromDataURL(b64);
                  });
                }

                if (p.CONSTAT_TRAVAUX === 2) {
                  p.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
                    const b64 = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;

                    if (!_.isEmpty(this.PAD_SOC_APT)) this.PAD_SOC_APT.fromDataURL(b64);
                    if (!_.isEmpty(this.PAD_SOC_APT2)) this.PAD_SOC_APT2.fromDataURL(b64);
                  });
                }
              }
            }
          });
        });
      }
    }
  };

  zoomOnImage = (photo, type) => (event) => {
    event.preventDefault();

    if (photo.NO_PHOTO === true) {
      this[type].current.click();
    } else {
      this.setState({ photoDialogOpen: true, photoDialogPhoto: photo.PHOTO });
    }
  };

  _handle_click_photographies_referente = (photo) => (event) => {
    event.preventDefault();

    CheckUserRight(this.ID_UTIL, "PHOTOCONC", "DROITMODIF").then((asRight) => {
      if (asRight) {
        let photos = [...this.state.PHOTOCONC];

        GetTemporaire("PHOTOCONC", this.state.IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", photo.IDPHOTOCONC).then((temporaire) => {
          if (temporaire !== undefined) {
            if (temporaire.DATE_DE_CREATION !== null && temporaire.DATE_TRAITE_DANS_GESCIME === null && temporaire.TYPE_D_ACTION === 1) {
              this.setState({
                photoconc: photos,
                dialog_traitement: true,
              });
            } else {
              for (let index = 0; index < photos.length; index++) {
                const element = photos[index];

                if (photo === element) {
                  element.PRINCIPAL = element.PRINCIPAL === 1 ? 0 : 1;
                } else {
                  element.PRINCIPAL = 0;
                }
              }

              this.setState({ PHOTOCONC: photos, disabledForm: false });
            }
          } else {
            for (let index = 0; index < photos.length; index++) {
              const element = photos[index];

              if (photo === element) {
                element.PRINCIPAL = element.PRINCIPAL === 1 ? 0 : 1;
              } else {
                element.PRINCIPAL = 0;
              }
            }

            this.setState({ PHOTOCONC: photos, disabledForm: false });
          }
        });
      } else {
        this.setState({ droit_util_modif_photoconc: true });
      }
    });
  };

  render() {
    console.log("RENDER MTravaux");

    const { width } = this.props;

    if (width === "sm") this._update_signature();
    if (width === "md") this._update_signature();

    if (this.state.REDIRECT !== null) {
      return <Redirect to={`/consulter/fiche-travaux/${this.state.REDIRECT}`} push />;
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} sm={11} md={8} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <form onSubmit={this._handle_submit_form_travaux}>
              <TextField select disabled fullWidth margin="normal" label="Cimetière" value={this.state.INTERVEN.TYPECIME !== 0 ? this.state.INTERVEN.TYPECIME : ""}>
                {this.state.CIMETIER.map((cimetier, index) => (
                  <MenuItem key={index} value={cimetier.NUMCIME}>
                    {cimetier.NOMCIME}
                  </MenuItem>
                ))}
              </TextField>

              {this.state.IS_CENDRE ? (
                <TextField select fullWidth disabled margin="normal" label="Equipement" value={this.state.CENDRE.NUMCENDRE}>
                  {this.state.CENDRE.map((cendre, index) => (
                    <MenuItem key={index} value={cendre.NUMCENDRE}>
                      {cendre.NOMCENDRE}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <div>
                  {this.state.SELECTED_SQUARE.includes("Provisoire") || this.state.SELECTED_SQUARE.includes("Non affect") ? (
                    <TextField
                      fullWidth
                      disabled
                      margin="normal"
                      label={this.state.PARAM.LIBELLEANCIENNUMPLAN !== "" ? this.state.PARAM.LIBELLEANCIENNUMPLAN : "Emplacement"}
                      value={this.state.CONCESS.EMPL}
                    />
                  ) : (
                    <div>
                      <TextField select fullWidth disabled margin="normal" label={this.state.SQUARE_LIBELLE} value={this.state.SELECTED_SQUARE}>
                        {this.state.SQUARE.map((square, index) => (
                          <MenuItem key={index} value={square}>
                            {square}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField select fullWidth disabled margin="normal" label={this.state.RANK_LIBELLE} value={this.state.SELECTED_RANK}>
                        {this.state.RANK.map((rank, index) => (
                          <MenuItem key={index} value={rank}>
                            {rank}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField select fullWidth disabled margin="normal" label={this.state.TOMB_LIBELLE} value={this.state.SELECTED_TOMB}>
                        {this.state.TOMB.map((tomb, index) => (
                          <MenuItem key={index} value={tomb}>
                            {tomb}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  )}
                </div>
              )}

              <TextField fullWidth disabled margin="normal" value={this.state.INTERVEN.EMPL_DEF_OU_CONCESSION !== null ? this.state.INTERVEN.EMPL_DEF_OU_CONCESSION : ""} />

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  minDate="01.01.1800"
                  minDateMessage="La date ne peut être inférieure au 1er janvier 1800"
                  maxDateMessage="La date n'est pas valide"
                  invalidDateMessage="Format de date invalide"
                  label="Début de l'intervention (JJ/MM/AAAA)"
                  format="dd/MM/yyyy"
                  inputProps={{
                    pattern: "(^(?:((?:0[1-9]|1[0-9]|2[0-9])/(?:0[1-9]|1[0-2])|(?:30)/(?!02)(?:0[1-9]|1[0-2])|31/(?:0[13578]|1[02]))/(?:18|19|20)[0-9]{2})$)",
                  }}
                  value={this.state.DATE_TRAVAUX !== "" ? this._cree_iso_date(this.state.DATE_TRAVAUX) : null}
                  onChange={this._handle_change_interven("DATE_TRAVAUX")}
                  animateYearScrolling
                  cancelLabel="annuler"
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <KeyboardTimePicker
                  fullWidth
                  margin="normal"
                  ampm={false}
                  label="Heure de début (HH:MM)"
                  format="HH:mm"
                  inputProps={{
                    pattern: "(^(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9])$)",
                  }}
                  mask="__:__"
                  value={this.state.HEURE_TRAVAUX !== "" ? this._cree_iso_time(this.state.HEURE_TRAVAUX) : null}
                  onChange={this._handle_change_interven("HEURE_TRAVAUX")}
                  cancelLabel="annuler"
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <KeyboardDatePicker
                  fullWidth
                  margin="normal"
                  minDate="01.01.1800"
                  minDateMessage="La date ne peut être inférieure au 1er janvier 1800"
                  maxDateMessage="La date n'est pas valide"
                  invalidDateMessage="Format de date invalide"
                  label="Fin de l'intervention (JJ/MM/AAAA)"
                  format="dd/MM/yyyy"
                  inputProps={{
                    pattern: "(^(?:((?:0[1-9]|1[0-9]|2[0-9])/(?:0[1-9]|1[0-2])|(?:30)/(?!02)(?:0[1-9]|1[0-2])|31/(?:0[13578]|1[02]))/(?:18|19|20)[0-9]{2})$)",
                  }}
                  value={this.state.DATE_TRAVAUX_FIN !== "" ? this._cree_iso_date(this.state.DATE_TRAVAUX_FIN) : null}
                  onChange={this._handle_change_interven("DATE_TRAVAUX_FIN")}
                  animateYearScrolling
                  cancelLabel="annuler"
                />
              </MuiPickersUtilsProvider>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                <KeyboardTimePicker
                  fullWidth
                  margin="normal"
                  ampm={false}
                  label="Heure de fin (HH:MM)"
                  format="HH:mm"
                  inputProps={{
                    pattern: "(^(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9])$)",
                  }}
                  mask="__:__"
                  value={this.state.HEURE_TRAVAUX_FIN !== "" ? this._cree_iso_time(this.state.HEURE_TRAVAUX_FIN) : null}
                  onChange={this._handle_change_interven("HEURE_TRAVAUX_FIN")}
                  cancelLabel="annuler"
                />
              </MuiPickersUtilsProvider>

              <TextField select fullWidth margin="normal" label="Suivi" value={this.state.INTERVEN.SUIVITRAV !== 0 ? this.state.INTERVEN.SUIVITRAV : ""} onChange={this._handle_change_combo_suivi}>
                <MenuItem value={1}>Inconnu</MenuItem>
                <MenuItem value={2}>En attente</MenuItem>
                <MenuItem value={3}>En cours</MenuItem>
                <MenuItem value={4}>Suspendu</MenuItem>
                <MenuItem value={5}>Terminé</MenuItem>
              </TextField>

              <TextField fullWidth disabled margin="normal" label="Intervenant" value={this._cree_chaine_nom_societe(this.state.INTERVEN.SUIVITRAV)} />

              <TextField fullWidth disabled margin="normal" label="Prestation" value={this._cree_chaine_typerest(this.state.INTERVEN.TYPEREST)} />

              <TextField fullWidth disabled margin="normal" label="Nature" value={this.state.NATINTERV.LIBINTERVENTION || ""} />

              <TextField fullWidth multiline disabled margin="normal" label="Notes" value={this.state.INTERVEN.TRAVAUX || ""} />

              {this.state.CONCESS !== "" ? (
                width === "xs" || width === "sm" ? (
                  <div>
                    <Table style={styles.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell style={styles.tablecells}>
                            <Typography variant="body2" style={{ color: this.state.constat_avt_en_cours_de_traiement ? "red" : "primary" }}>
                              Constat avant travaux {this.state.constat_avt_en_cours_de_traiement ? ` en cours de traitement` : ""}
                            </Typography>

                            <input
                              type="file"
                              ref={this.TARVAUX_1_ref}
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={this._handle_click_photo_travaux("PHOTO_AVT")}
                            />

                            <IconButton disabled={this.state.constat_avt_en_cours_de_traiement} component="span" onClick={this._handle_change_photoconc(0, this.TARVAUX_1_ref)}>
                              <PhotoCamera />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              ...styles.tablecells,
                              ...{ flexWrap: "wrap" },
                            }}
                          >
                            {_.filter(this.state.PHOTOCONC, (photo) => {
                              return photo.CONSTAT_TRAVAUX === 1;
                            }).map((photo, index) =>
                              photo.PHOTO !== "" ? (
                                <Card key={index} style={styles.photoconc_card}>
                                  <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                    {photo.NO_PHOTO === undefined || photo.NO_PHOTO === false ? (
                                      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <IconButton aria-label="Référente" color={photo.PRINCIPAL ? "primary" : "default"} onClick={this._handle_click_photographies_referente(photo)}>
                                          <StarRate />
                                        </IconButton>

                                        <div
                                          style={{ marginLeft: "-10px", fontSize: "smaller", userSelect: "none", color: photo.PRINCIPAL ? "#FF732F" : "rgba(0, 0, 0, 0.87)" }}
                                          onClick={this._handle_click_photographies_referente(photo)}
                                        >{`Référente`}</div>
                                      </div>
                                    ) : null}

                                    <Typography variant="body2">{photo.DATEAJOUT !== null ? `Prise le ${Cree_date_chaine(photo.DATEAJOUT)}` : "\u00A0"}</Typography>
                                  </CardActions>

                                  <CardMedia onClick={this.zoomOnImage(photo, "TARVAUX_1_ref")} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)}></CardMedia>
                                </Card>
                              ) : null
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0, paddingBottom: 0 }}>
                            <TextField
                              inputRef={this.textfield_etatlieu_avt}
                              fullWidth
                              multiline
                              margin="normal"
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              label="Etat des lieux"
                              rows={1}
                              rowsMax={4}
                              value={this.state.PHOTOCONC[0] !== undefined ? this.state.PHOTOCONC[0].NOTES : ""}
                              onChange={this._handle_change_photoconc_notes(0, this.textfield_etatlieu_avt)}
                              InputProps={{
                                endAdornment:
                                  SpeechRecognition !== undefined ? (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="speech_recognition"
                                        disabled={this.state.constat_avt_en_cours_de_traiement}
                                        onClick={() => {
                                          this.setState(
                                            {
                                              SPEECH_LISTENING_CAVT: !this.state.SPEECH_LISTENING_CAVT,
                                            },
                                            () => {
                                              this._listen_speech_recognition(0, this.textfield_etatlieu_avt);
                                            }
                                          );
                                        }}
                                      >
                                        {this.state.SPEECH_LISTENING_CAVT ? <Done /> : <Mic />}
                                      </IconButton>
                                    </InputAdornment>
                                  ) : null,
                              }}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                            {this.state.cas_strasbourg ? (
                              <TextField
                                select
                                fullWidth
                                margin="normal"
                                disabled={this.state.constat_avt_en_cours_de_traiement}
                                label="Etat du monument"
                                value={this.state.photoconc_combo_selected_avt}
                                onChange={this._handle_change_photoconc_etat_monument_strasbourg(0)}
                              >
                                <MenuItem value="" style={{ minHeight: 36 }} />
                                {this.state.photoconc_pour_combo.map((item, index) => (
                                  <MenuItem key={index} value={item.MONUMENT_ETAT}>
                                    {item.MONUMENT_ETAT}
                                  </MenuItem>
                                ))}
                              </TextField>
                            ) : (
                              <Autocomplete
                                fullWidth
                                disabled={this.state.constat_avt_en_cours_de_traiement}
                                freeSolo
                                disableClearable
                                openOnFocus
                                options={this.state.photoconc_pour_combo}
                                getOptionLabel={(option) => option.MONUMENT_ETAT || ""}
                                value={this.state.photoconc_combo_selected_avt}
                                inputValue={this.state.PHOTOCONC[0].MONUMENT_ETAT}
                                onChange={(event, newValue) => this._handle_change_photoconc_etat_monument(event, newValue, 0)}
                                onInputChange={(event, newValue) => this._handle_inputchange_photoconc_etat_monument(event, newValue, 0, this.textfield_monument_avt)}
                                renderInput={(params) => <TextField {...params} ref={this.textfield_monument_avt} margin="normal" label="Etat du monument" />}
                              />
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                            {this.state.cas_strasbourg ? (
                              <TextField
                                select
                                fullWidth
                                margin="normal"
                                disabled={this.state.constat_avt_en_cours_de_traiement}
                                label={this.state.PARAM.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"}
                                value={this.state.photoconc_combo2_selected_avt}
                                onChange={this._handle_change_photoconc_etat_semelle_strasbourg(0)}
                              >
                                <MenuItem value="" style={{ minHeight: 36 }} />
                                {this.state.photoconc_pour_combo2.map((item, index) => (
                                  <MenuItem key={index} value={item.SEMELLE_ETAT}>
                                    {item.SEMELLE_ETAT}
                                  </MenuItem>
                                ))}
                              </TextField>
                            ) : (
                              <Autocomplete
                                fullWidth
                                disabled={this.state.constat_avt_en_cours_de_traiement}
                                freeSolo
                                disableClearable
                                openOnFocus
                                options={this.state.photoconc_pour_combo2}
                                getOptionLabel={(option) => option.SEMELLE_ETAT || ""}
                                value={this.state.photoconc_combo2_selected_avt}
                                inputValue={this.state.PHOTOCONC[0].SEMELLE_ETAT}
                                onChange={(event, newValue) => this._handle_change_photoconc_etat_semelle(event, newValue, 0)}
                                onInputChange={(event, newValue) => this._handle_inputchange_photoconc_etat_semelle(event, newValue, 0, this.textfield_semelle_avt)}
                                renderInput={(params) => (
                                  <TextField {...params} ref={this.textfield_semelle_avt} margin="normal" label={this.state.PARAM.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"} />
                                )}
                              />
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              label="Monument à gauche"
                              value={this.state.PHOTOCONC[0] !== undefined ? this.state.PHOTOCONC[0].MONUMENT_GAUCHE : ""}
                              onChange={this._handle_change_combo_monument(0, "MONUMENT_GAUCHE")}
                            >
                              <MenuItem value="" style={{ minHeight: 36 }}></MenuItem>
                              <MenuItem value="RAS">RAS</MenuItem>
                              <MenuItem value="PB">Problème</MenuItem>
                            </TextField>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              label="Monument à droite"
                              value={this.state.PHOTOCONC[0] !== undefined ? this.state.PHOTOCONC[0].MONUMENT_DROITE : ""}
                              onChange={this._handle_change_combo_monument(0, "MONUMENT_DROITE")}
                            >
                              <MenuItem value="" style={{ minHeight: 36 }}></MenuItem>
                              <MenuItem value="RAS">RAS</MenuItem>
                              <MenuItem value="PB">Problème</MenuItem>
                            </TextField>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              label="Monument de devant"
                              value={this.state.PHOTOCONC[0] !== undefined ? this.state.PHOTOCONC[0].MONUMENT_DEVANT : ""}
                              onChange={this._handle_change_combo_monument(0, "MONUMENT_DEVANT")}
                            >
                              <MenuItem value="" style={{ minHeight: 36 }}></MenuItem>
                              <MenuItem value="RAS">RAS</MenuItem>
                              <MenuItem value="PB">Problème</MenuItem>
                            </TextField>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderTop: 0, paddingTop: 0 }}>
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              label="Monument de derrière"
                              value={this.state.PHOTOCONC[0] !== undefined ? this.state.PHOTOCONC[0].MONUMENT_DERRIERE : ""}
                              onChange={this._handle_change_combo_monument(0, "MONUMENT_DERRIERE")}
                            >
                              <MenuItem value="" style={{ minHeight: 36 }}></MenuItem>
                              <MenuItem value="RAS">RAS</MenuItem>
                              <MenuItem value="PB">Problème</MenuItem>
                            </TextField>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0 }}>
                            <Typography variant="body2">Signature gardien</Typography>

                            <Autocomplete
                              style={{ width: 200 }}
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              freeSolo
                              disableClearable
                              openOnFocus
                              options={this.state.agentterrain}
                              getOptionLabel={(option) => `${option.NOM} ${option.PRENOM}`}
                              value={this.state.selected_agent_avt}
                              inputValue={this.state.PHOTOCONC[0].AGENT}
                              onChange={(event, newValue) => this._handle_change_photoconc_agent(event, newValue, 0, this.PAD_AVT)}
                              onInputChange={(event, newValue) => this._handle_inputchange_photoconc_agent(event, newValue, 0, this.PAD_AVT, this.autocomplete_textinput_avt)}
                              renderInput={(params) => <TextField {...params} inputRef={this.autocomplete_textinput_avt} placeholder="Nom prénom" />}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderTop: 0 }}>
                            <div style={{ width: 260, height: 200, margin: "0 auto", position: "relative" }}>
                              <SignatureCanvas
                                ref={(ref) => {
                                  this.PAD_AVT = ref;
                                }}
                                clearOnResize={false}
                                canvasProps={{ style: { width: "100%", height: "100%", border: "1px solid", borderRadius: 10 } }}
                                onBegin={() => {
                                  const photoconc = this.state.PHOTOCONC;

                                  if (this.state.constat_avt_en_cours_de_traiement) {
                                    this.PAD_AVT.off();
                                  } else {
                                    if (photoconc[0].AGENT === "") {
                                      this._clear_pad("PAD_AVT");
                                      this.PAD_AVT.off();
                                      this.autocomplete_textinput_avt.current.focus();

                                      if (photoconc[0].AGENT !== "") this.PAD_AVT.on();
                                    }
                                  }
                                }}
                                onEnd={() => {
                                  const photoconc = this.state.PHOTOCONC;
                                  photoconc[0].SIGNATURE_AGENT = b64toBlob(this.PAD_AVT.toDataURL("image/png").replace("data:image/png;base64,", ""), "image/png");

                                  this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                                }}
                              />

                              <IconButton
                                aria-label="Netoyer"
                                color="secondary"
                                disabled={this.state.constat_avt_en_cours_de_traiement}
                                style={{ position: "absolute", top: 0, right: 0, float: "right" }}
                                onClick={() => {
                                  this._clear_pad("PAD_AVT");

                                  const photoconc = this.state.PHOTOCONC;
                                  photoconc[0].SIGNATURE_AGENT = null;

                                  this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                                }}
                              >
                                <Close />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0 }}>
                            <Typography variant="body2">Signature opérateur</Typography>

                            <TextField
                              style={{ width: 200 }}
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              inputRef={this.textinput_soc_avt}
                              placeholder="Nom"
                              defaultValue={this.state.PHOTOCONC[0].SOCIETE}
                              onChange={this._handle_inputchange_photoconc_societe(0, this.PAD_SOC_AVT)}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderTop: 0 }}>
                            <div style={{ width: 260, height: 200, margin: "0 auto", position: "relative" }}>
                              <SignatureCanvas
                                ref={(ref) => {
                                  this.PAD_SOC_AVT = ref;
                                }}
                                clearOnResize={false}
                                canvasProps={{ style: { width: "100%", height: "100%", border: "1px solid", borderRadius: 10 } }}
                                onBegin={() => {
                                  const photoconc = this.state.PHOTOCONC;

                                  if (this.state.constat_avt_en_cours_de_traiement) {
                                    this.PAD_SOC_AVT.off();
                                  } else {
                                    if (photoconc[0].SOCIETE === "") {
                                      this._clear_pad("PAD_SOC_AVT");
                                      this.PAD_SOC_AVT.off();
                                      this.textinput_soc_avt.current.focus();

                                      if (photoconc[0].SOCIETE !== "") this.PAD_SOC_AVT.on();
                                    }
                                  }
                                }}
                                onEnd={() => {
                                  const photoconc = this.state.PHOTOCONC;
                                  photoconc[0].SIGNATURE_SOCIETE = b64toBlob(this.PAD_SOC_AVT.toDataURL("image/png").replace("data:image/png;base64,", ""), "image/png");

                                  this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                                }}
                              />

                              <IconButton
                                aria-label="Netoyer"
                                color="secondary"
                                disabled={this.state.constat_avt_en_cours_de_traiement}
                                style={{ position: "absolute", top: 0, right: 0 }}
                                onClick={() => {
                                  this._clear_pad("PAD_SOC_AVT");

                                  const photoconc = this.state.PHOTOCONC;
                                  photoconc[0].SIGNATURE_SOCIETE = null;

                                  this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                                }}
                              >
                                <Close />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>

                    <Table style={styles.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell style={styles.tablecells}>
                            <Typography variant="body2" style={{ color: this.state.constat_apt_en_cours_de_traiement ? "red" : "primary" }}>
                              Constat après travaux {this.state.constat_apt_en_cours_de_traiement ? ` en cours de traitement` : ""}
                            </Typography>

                            <input
                              type="file"
                              ref={this.TARVAUX_2_ref}
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={this._handle_click_photo_travaux("PHOTO_APT")}
                            />

                            <IconButton disabled={this.state.constat_apt_en_cours_de_traiement} component="span" onClick={this._handle_change_photoconc(1, this.TARVAUX_2_ref)}>
                              <PhotoCamera />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{
                              ...styles.tablecells,
                              ...{ flexWrap: "wrap" },
                            }}
                          >
                            {_.filter(this.state.PHOTOCONC, (photo) => {
                              return photo.CONSTAT_TRAVAUX === 2;
                            }).map((photo, index) =>
                              photo.PHOTO !== "" ? (
                                <Card key={index} style={styles.photoconc_card}>
                                  <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                    {photo.NO_PHOTO === undefined || photo.NO_PHOTO === false ? (
                                      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                        <IconButton aria-label="Référente" color={photo.PRINCIPAL ? "primary" : "default"} onClick={this._handle_click_photographies_referente(photo)}>
                                          <StarRate />
                                        </IconButton>

                                        <div
                                          style={{ marginLeft: "-10px", fontSize: "smaller", userSelect: "none", color: photo.PRINCIPAL ? "#FF732F" : "rgba(0, 0, 0, 0.87)" }}
                                          onClick={this._handle_click_photographies_referente(photo)}
                                        >{`Référente`}</div>
                                      </div>
                                    ) : null}

                                    <Typography variant="body2">{photo.DATEAJOUT !== null ? `Prise le ${Cree_date_chaine(photo.DATEAJOUT)}` : "\u00A0"}</Typography>
                                  </CardActions>

                                  <CardMedia onClick={this.zoomOnImage(photo, "TARVAUX_2_ref")} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)}></CardMedia>
                                </Card>
                              ) : null
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0, paddingBottom: 0 }}>
                            <TextField
                              inputRef={this.textfield_etatlieu_apt}
                              fullWidth
                              multiline
                              margin="normal"
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              label="Etat des lieux"
                              rows={1}
                              rowsMax={4}
                              value={this.state.PHOTOCONC[1] !== undefined ? this.state.PHOTOCONC[1].NOTES : ""}
                              onChange={this._handle_change_photoconc_notes(1, this.textfield_etatlieu_apt)}
                              InputProps={{
                                endAdornment:
                                  SpeechRecognition !== undefined ? (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="speech_recognition"
                                        disabled={this.state.constat_apt_en_cours_de_traiement}
                                        onClick={() => {
                                          this.setState(
                                            {
                                              SPEECH_LISTENING_CAPT: !this.state.SPEECH_LISTENING_CAPT,
                                            },
                                            () => {
                                              this._listen_speech_recognition(1, this.textfield_etatlieu_apt);
                                            }
                                          );
                                        }}
                                      >
                                        {this.state.SPEECH_LISTENING_CAPT ? <Done /> : <Mic />}
                                      </IconButton>
                                    </InputAdornment>
                                  ) : null,
                              }}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                            {this.state.cas_strasbourg ? (
                              <TextField
                                select
                                fullWidth
                                margin="normal"
                                disabled={this.state.constat_apt_en_cours_de_traiement}
                                label="Etat du monument"
                                value={this.state.photoconc_combo_selected_apt}
                                onChange={this._handle_change_photoconc_etat_monument_strasbourg(1)}
                              >
                                <MenuItem value="" style={{ minHeight: 36 }} />
                                {this.state.photoconc_pour_combo.map((item, index) => (
                                  <MenuItem key={index} value={item.MONUMENT_ETAT}>
                                    {item.MONUMENT_ETAT}
                                  </MenuItem>
                                ))}
                              </TextField>
                            ) : (
                              <Autocomplete
                                fullWidth
                                disabled={this.state.constat_apt_en_cours_de_traiement}
                                freeSolo
                                disableClearable
                                openOnFocus
                                options={this.state.photoconc_pour_combo}
                                getOptionLabel={(option) => option.MONUMENT_ETAT || ""}
                                value={this.state.photoconc_combo_selected_apt}
                                inputValue={this.state.PHOTOCONC[1].MONUMENT_ETAT}
                                onChange={(event, newValue) => this._handle_change_photoconc_etat_monument(event, newValue, 1)}
                                onInputChange={(event, newValue) => this._handle_inputchange_photoconc_etat_monument(event, newValue, 1, this.textfield_monument_apt)}
                                renderInput={(params) => <TextField {...params} ref={this.textfield_monument_apt} margin="normal" label="Etat du monument" />}
                              />
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                            {this.state.cas_strasbourg ? (
                              <TextField
                                select
                                fullWidth
                                margin="normal"
                                disabled={this.state.constat_apt_en_cours_de_traiement}
                                label={this.state.PARAM.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"}
                                value={this.state.photoconc_combo2_selected_apt}
                                onChange={this._handle_change_photoconc_etat_semelle_strasbourg(1)}
                              >
                                <MenuItem value="" style={{ minHeight: 36 }} />
                                {this.state.photoconc_pour_combo2.map((item, index) => (
                                  <MenuItem key={index} value={item.SEMELLE_ETAT}>
                                    {item.SEMELLE_ETAT}
                                  </MenuItem>
                                ))}
                              </TextField>
                            ) : (
                              <Autocomplete
                                fullWidth
                                disabled={this.state.constat_apt_en_cours_de_traiement}
                                freeSolo
                                disableClearable
                                openOnFocus
                                options={this.state.photoconc_pour_combo2}
                                getOptionLabel={(option) => option.SEMELLE_ETAT || ""}
                                value={this.state.photoconc_combo2_selected_apt}
                                inputValue={this.state.PHOTOCONC[1].SEMELLE_ETAT}
                                onChange={(event, newValue) => this._handle_change_photoconc_etat_semelle(event, newValue, 1)}
                                onInputChange={(event, newValue) => this._handle_inputchange_photoconc_etat_semelle(event, newValue, 1, this.textfield_semelle_apt)}
                                renderInput={(params) => (
                                  <TextField {...params} ref={this.textfield_semelle_apt} margin="normal" label={this.state.PARAM.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"} />
                                )}
                              />
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              label="Monument à gauche"
                              value={this.state.PHOTOCONC[1] !== undefined ? this.state.PHOTOCONC[1].MONUMENT_GAUCHE : ""}
                              onChange={this._handle_change_combo_monument(1, "MONUMENT_GAUCHE")}
                            >
                              <MenuItem value="" style={{ minHeight: 36 }}></MenuItem>
                              <MenuItem value="RAS">RAS</MenuItem>
                              <MenuItem value="PB">Problème</MenuItem>
                            </TextField>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              label="Monument à droite"
                              value={this.state.PHOTOCONC[1] !== undefined ? this.state.PHOTOCONC[1].MONUMENT_DROITE : ""}
                              onChange={this._handle_change_combo_monument(1, "MONUMENT_DROITE")}
                            >
                              <MenuItem value="" style={{ minHeight: 36 }}></MenuItem>
                              <MenuItem value="RAS">RAS</MenuItem>
                              <MenuItem value="PB">Problème</MenuItem>
                            </TextField>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              label="Monument de devant"
                              value={this.state.PHOTOCONC[1] !== undefined ? this.state.PHOTOCONC[1].MONUMENT_DEVANT : ""}
                              onChange={this._handle_change_combo_monument(1, "MONUMENT_DEVANT")}
                            >
                              <MenuItem value="" style={{ minHeight: 36 }}></MenuItem>
                              <MenuItem value="RAS">RAS</MenuItem>
                              <MenuItem value="PB">Problème</MenuItem>
                            </TextField>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderTop: 0, paddingTop: 0 }}>
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              label="Monument de derrière"
                              value={this.state.PHOTOCONC[1] !== undefined ? this.state.PHOTOCONC[1].MONUMENT_DERRIERE : ""}
                              onChange={this._handle_change_combo_monument(1, "MONUMENT_DERRIERE")}
                            >
                              <MenuItem value="" style={{ minHeight: 36 }}></MenuItem>
                              <MenuItem value="RAS">RAS</MenuItem>
                              <MenuItem value="PB">Problème</MenuItem>
                            </TextField>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0 }}>
                            <Typography variant="body2">Signature gardien</Typography>

                            <Autocomplete
                              style={{ width: 200 }}
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              freeSolo
                              disableClearable
                              openOnFocus
                              options={this.state.agentterrain}
                              getOptionLabel={(option) => `${option.NOM} ${option.PRENOM}`}
                              value={this.state.selected_agent_apt}
                              inputValue={this.state.PHOTOCONC[1].AGENT}
                              onChange={(event, newValue) => this._handle_change_photoconc_agent(event, newValue, 1, this.PAD_APT)}
                              onInputChange={(event, newValue) => this._handle_inputchange_photoconc_agent(event, newValue, 1, this.PAD_APT, this.autocomplete_textinput_apt)}
                              renderInput={(params) => <TextField {...params} inputRef={this.autocomplete_textinput_apt} placeholder="Nom prénom" />}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderTop: 0 }}>
                            <div style={{ width: 260, height: 200, margin: "0 auto", position: "relative" }}>
                              <SignatureCanvas
                                ref={(ref) => {
                                  this.PAD_APT = ref;
                                }}
                                clearOnResize={false}
                                canvasProps={{ style: { width: "100%", height: "100%", border: "1px solid", borderRadius: 10 } }}
                                onBegin={() => {
                                  const photoconc = this.state.PHOTOCONC;

                                  if (this.state.constat_apt_en_cours_de_traiement) {
                                    this.PAD_APT.off();
                                  } else {
                                    if (photoconc[1].AGENT === "") {
                                      this._clear_pad("PAD_APT");
                                      this.PAD_APT.off();
                                      this.autocomplete_textinput_apt.current.focus();

                                      if (photoconc[1].AGENT !== "") this.PAD_APT.on();
                                    }
                                  }
                                }}
                                onEnd={() => {
                                  const photoconc = this.state.PHOTOCONC;
                                  photoconc[1].SIGNATURE_AGENT = b64toBlob(this.PAD_APT.toDataURL("image/png").replace("data:image/png;base64,", ""), "image/png");

                                  this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                                }}
                              />

                              <IconButton
                                aria-label="Netoyer"
                                color="secondary"
                                disabled={this.state.constat_apt_en_cours_de_traiement}
                                style={{ position: "absolute", top: 0, right: 0 }}
                                onClick={() => {
                                  this._clear_pad("PAD_APT");

                                  const photoconc = this.state.PHOTOCONC;
                                  photoconc[1].SIGNATURE_AGENT = null;

                                  this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                                }}
                              >
                                <Close />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderBottom: 0 }}>
                            <Typography variant="body2">Signature opérateur</Typography>

                            <TextField
                              style={{ width: 200 }}
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              inputRef={this.textinput_soc_apt}
                              placeholder="Nom"
                              defaultValue={this.state.PHOTOCONC[1].SOCIETE}
                              onChange={this._handle_inputchange_photoconc_societe(1, this.PAD_SOC_APT)}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell style={{ ...styles.tablecells, borderTop: 0 }}>
                            <div style={{ width: 260, height: 200, margin: "0 auto", position: "relative" }}>
                              <SignatureCanvas
                                ref={(ref) => {
                                  this.PAD_SOC_APT = ref;
                                }}
                                clearOnResize={false}
                                canvasProps={{ style: { width: "100%", height: "100%", border: "1px solid", borderRadius: 10 } }}
                                onBegin={() => {
                                  const photoconc = this.state.PHOTOCONC;

                                  if (this.state.constat_apt_en_cours_de_traiement) {
                                    this.PAD_SOC_APT.off();
                                  } else {
                                    if (photoconc[1].SOCIETE === "") {
                                      this._clear_pad("PAD_SOC_APT");
                                      this.PAD_SOC_APT.off();
                                      this.textinput_soc_apt.current.focus();

                                      if (photoconc[1].SOCIETE !== "") this.PAD_SOC_APT.on();
                                    }
                                  }
                                }}
                                onEnd={() => {
                                  const photoconc = this.state.PHOTOCONC;
                                  photoconc[1].SIGNATURE_SOCIETE = b64toBlob(this.PAD_SOC_APT.toDataURL("image/png").replace("data:image/png;base64,", ""), "image/png");

                                  this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                                }}
                              />

                              <IconButton
                                aria-label="Netoyer"
                                color="secondary"
                                disabled={this.state.constat_apt_en_cours_de_traiement}
                                style={{ position: "absolute", top: 0, right: 0 }}
                                onClick={() => {
                                  this._clear_pad("PAD_SOC_APT");

                                  const photoconc = this.state.PHOTOCONC;
                                  photoconc[1].SIGNATURE_SOCIETE = null;

                                  this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                                }}
                              >
                                <Close />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                ) : (
                  <Table style={styles.table}>
                    <TableHead>
                      <TableRow style={{ display: "flex" }}>
                        <TableCell style={{ ...styles.tablecells, ...{ flex: 1 } }}>
                          <Typography variant="body2" style={{ color: this.state.constat_avt_en_cours_de_traiement ? "red" : "primary" }}>
                            Constat avant travaux {this.state.constat_avt_en_cours_de_traiement ? ` en cours de traitement` : ""}
                          </Typography>

                          <input
                            type="file"
                            ref={this.TARVAUX_1B_ref}
                            disabled={this.state.constat_avt_en_cours_de_traiement}
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={this._handle_click_photo_travaux("PHOTO_AVT")}
                          />

                          <IconButton disabled={this.state.constat_avt_en_cours_de_traiement} component="span" onClick={this._handle_change_photoconc(0, this.TARVAUX_1B_ref)}>
                            <PhotoCamera />
                          </IconButton>
                        </TableCell>

                        <TableCell style={{ ...styles.tablecells, ...{ flex: 1 } }}>
                          <Typography variant="body2" style={{ color: this.state.constat_apt_en_cours_de_traiement ? "red" : "primary" }}>
                            Constat après travaux {this.state.constat_apt_en_cours_de_traiement ? ` en cours de traitement` : ""}
                          </Typography>

                          <input
                            type="file"
                            ref={this.TARVAUX_2B_ref}
                            disabled={this.state.constat_apt_en_cours_de_traiement}
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={this._handle_click_photo_travaux("PHOTO_APT")}
                          />

                          <IconButton disabled={this.state.constat_apt_en_cours_de_traiement} component="span" onClick={this._handle_change_photoconc(1, this.TARVAUX_2B_ref)}>
                            <PhotoCamera />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow style={{ display: "flex" }}>
                        <TableCell
                          style={{
                            ...styles.tablecells,
                            ...{ flex: 1, flexWrap: "wrap" },
                          }}
                        >
                          {_.filter(this.state.PHOTOCONC, (photo) => {
                            return photo.CONSTAT_TRAVAUX === 1;
                          }).map((photo, index) =>
                            photo.PHOTO !== "" ? (
                              <Card key={index} style={styles.photoconc_card}>
                                <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                  {photo.NO_PHOTO === undefined || photo.NO_PHOTO === false ? (
                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                      <IconButton aria-label="Référente" color={photo.PRINCIPAL ? "primary" : "default"} onClick={this._handle_click_photographies_referente(photo)}>
                                        <StarRate />
                                      </IconButton>

                                      <div
                                        style={{ marginLeft: "-10px", fontSize: "smaller", userSelect: "none", color: photo.PRINCIPAL ? "#FF732F" : "rgba(0, 0, 0, 0.87)" }}
                                        onClick={this._handle_click_photographies_referente(photo)}
                                      >{`Référente`}</div>
                                    </div>
                                  ) : null}

                                  <Typography variant="body2">{photo.DATEAJOUT !== null ? `Prise le ${Cree_date_chaine(photo.DATEAJOUT)}` : "\u00A0"}</Typography>
                                </CardActions>

                                <CardMedia onClick={this.zoomOnImage(photo, "TARVAUX_1B_ref")} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)}></CardMedia>
                              </Card>
                            ) : null
                          )}
                        </TableCell>

                        <TableCell
                          style={{
                            ...styles.tablecells,
                            ...{ flex: 1, flexWrap: "wrap" },
                          }}
                        >
                          {_.filter(this.state.PHOTOCONC, (photo) => {
                            return photo.CONSTAT_TRAVAUX === 2;
                          }).map((photo, index) =>
                            photo.PHOTO !== "" ? (
                              <Card key={index} style={styles.photoconc_card}>
                                <CardActions disableSpacing style={styles.photoconc_cardaction}>
                                  {photo.NO_PHOTO === undefined || photo.NO_PHOTO === false ? (
                                    <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                      <IconButton aria-label="Référente" color={photo.PRINCIPAL ? "primary" : "default"} onClick={this._handle_click_photographies_referente(photo)}>
                                        <StarRate />
                                      </IconButton>

                                      <div
                                        style={{ marginLeft: "-10px", fontSize: "smaller", userSelect: "none", color: photo.PRINCIPAL ? "#FF732F" : "rgba(0, 0, 0, 0.87)" }}
                                        onClick={this._handle_click_photographies_referente(photo)}
                                      >{`Référente`}</div>
                                    </div>
                                  ) : null}

                                  <Typography variant="body2">{photo.DATEAJOUT !== null ? `Prise le ${Cree_date_chaine(photo.DATEAJOUT)}` : "\u00A0"}</Typography>
                                </CardActions>

                                <CardMedia onClick={this.zoomOnImage(photo, "TARVAUX_2B_ref")} style={styles.photoconc_cardmedia} image={window.URL.createObjectURL(photo.PHOTO)}></CardMedia>
                              </Card>
                            ) : null
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ display: "flex" }}>
                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0, paddingBottom: 0 }}>
                          <TextField
                            inputRef={this.textfield_etatlieu_avt}
                            fullWidth
                            multiline
                            margin="normal"
                            disabled={this.state.constat_avt_en_cours_de_traiement}
                            label="Etat des lieux"
                            rows={1}
                            rowsMax={4}
                            value={this.state.PHOTOCONC[0] !== undefined ? this.state.PHOTOCONC[0].NOTES : ""}
                            onChange={this._handle_change_photoconc_notes(0, this.textfield_etatlieu_avt)}
                            InputProps={{
                              endAdornment:
                                SpeechRecognition !== undefined ? (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="speech_recognition"
                                      disabled={this.state.constat_avt_en_cours_de_traiement}
                                      onClick={() => {
                                        this.setState(
                                          {
                                            SPEECH_LISTENING_CAVT: !this.state.SPEECH_LISTENING_CAVT,
                                          },
                                          () => {
                                            this._listen_speech_recognition(0, this.textfield_etatlieu_avt);
                                          }
                                        );
                                      }}
                                    >
                                      {this.state.SPEECH_LISTENING_CAVT ? <Done /> : <Mic />}
                                    </IconButton>
                                  </InputAdornment>
                                ) : null,
                            }}
                          />
                        </TableCell>

                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0, paddingBottom: 0 }}>
                          <TextField
                            inputRef={this.textfield_etatlieu_apt}
                            fullWidth
                            multiline
                            margin="normal"
                            disabled={this.state.constat_apt_en_cours_de_traiement}
                            label="Etat des lieux"
                            rows={1}
                            rowsMax={4}
                            value={this.state.PHOTOCONC[1] !== undefined ? this.state.PHOTOCONC[1].NOTES : ""}
                            onChange={this._handle_change_photoconc_notes(1, this.textfield_etatlieu_apt)}
                            InputProps={{
                              endAdornment:
                                SpeechRecognition !== undefined ? (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="speech_recognition"
                                      disabled={this.state.constat_apt_en_cours_de_traiement}
                                      onClick={() => {
                                        this.setState(
                                          {
                                            SPEECH_LISTENING_CAPT: !this.state.SPEECH_LISTENING_CAPT,
                                          },
                                          () => {
                                            this._listen_speech_recognition(1, this.textfield_etatlieu_apt);
                                          }
                                        );
                                      }}
                                    >
                                      {this.state.SPEECH_LISTENING_CAPT ? <Done /> : <Mic />}
                                    </IconButton>
                                  </InputAdornment>
                                ) : null,
                            }}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ display: "flex" }}>
                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                          {this.state.cas_strasbourg ? (
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              label="Etat du monument"
                              value={this.state.photoconc_combo_selected_avt}
                              onChange={this._handle_change_photoconc_etat_monument_strasbourg(0)}
                            >
                              <MenuItem value="" style={{ minHeight: 36 }} />
                              {this.state.photoconc_pour_combo.map((item, index) => (
                                <MenuItem key={index} value={item.MONUMENT_ETAT}>
                                  {item.MONUMENT_ETAT}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            <Autocomplete
                              fullWidth
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              freeSolo
                              disableClearable
                              openOnFocus
                              options={this.state.photoconc_pour_combo}
                              getOptionLabel={(option) => option.MONUMENT_ETAT || ""}
                              value={this.state.photoconc_combo_selected_avt}
                              inputValue={this.state.PHOTOCONC[0].MONUMENT_ETAT}
                              onChange={(event, newValue) => this._handle_change_photoconc_etat_monument(event, newValue, 0)}
                              onInputChange={(event, newValue) => this._handle_inputchange_photoconc_etat_monument(event, newValue, 0, this.textfield_monument_avt)}
                              renderInput={(params) => <TextField {...params} ref={this.textfield_monument_avt} margin="normal" label="Etat du monument" />}
                            />
                          )}
                        </TableCell>

                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                          {this.state.cas_strasbourg ? (
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              label="Etat du monument"
                              value={this.state.photoconc_combo_selected_apt}
                              onChange={this._handle_change_photoconc_etat_monument_strasbourg(1)}
                            >
                              <MenuItem value="" style={{ minHeight: 36 }} />
                              {this.state.photoconc_pour_combo.map((item, index) => (
                                <MenuItem key={index} value={item.MONUMENT_ETAT}>
                                  {item.MONUMENT_ETAT}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            <Autocomplete
                              fullWidth
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              freeSolo
                              disableClearable
                              openOnFocus
                              options={this.state.photoconc_pour_combo}
                              getOptionLabel={(option) => option.MONUMENT_ETAT || ""}
                              value={this.state.photoconc_combo_selected_apt}
                              inputValue={this.state.PHOTOCONC[1].MONUMENT_ETAT}
                              onChange={(event, newValue) => this._handle_change_photoconc_etat_monument(event, newValue, 1)}
                              onInputChange={(event, newValue) => this._handle_inputchange_photoconc_etat_monument(event, newValue, 1, this.textfield_monument_apt)}
                              renderInput={(params) => <TextField {...params} ref={this.textfield_monument_apt} margin="normal" label="Etat du monument" />}
                            />
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ display: "flex" }}>
                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                          {this.state.cas_strasbourg ? (
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              label={this.state.PARAM.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"}
                              value={this.state.photoconc_combo2_selected_avt}
                              onChange={this._handle_change_photoconc_etat_semelle_strasbourg(0)}
                            >
                              <MenuItem value="" style={{ minHeight: 36 }} />
                              {this.state.photoconc_pour_combo2.map((item, index) => (
                                <MenuItem key={index} value={item.SEMELLE_ETAT}>
                                  {item.SEMELLE_ETAT}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            <Autocomplete
                              fullWidth
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              freeSolo
                              disableClearable
                              openOnFocus
                              options={this.state.photoconc_pour_combo2}
                              getOptionLabel={(option) => option.SEMELLE_ETAT || ""}
                              value={this.state.photoconc_combo2_selected_avt}
                              inputValue={this.state.PHOTOCONC[0].SEMELLE_ETAT}
                              onChange={(event, newValue) => this._handle_change_photoconc_etat_semelle(event, newValue, 0)}
                              onInputChange={(event, newValue) => this._handle_inputchange_photoconc_etat_semelle(event, newValue, 0, this.textfield_semelle_avt)}
                              renderInput={(params) => (
                                <TextField {...params} ref={this.textfield_semelle_avt} margin="normal" label={this.state.PARAM.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"} />
                              )}
                            />
                          )}
                        </TableCell>

                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                          {this.state.cas_strasbourg ? (
                            <TextField
                              select
                              fullWidth
                              margin="normal"
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              label={this.state.PARAM.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"}
                              value={this.state.photoconc_combo2_selected_apt}
                              onChange={this._handle_change_photoconc_etat_semelle_strasbourg(1)}
                            >
                              <MenuItem value="" style={{ minHeight: 36 }} />
                              {this.state.photoconc_pour_combo2.map((item, index) => (
                                <MenuItem key={index} value={item.SEMELLE_ETAT}>
                                  {item.SEMELLE_ETAT}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            <Autocomplete
                              fullWidth
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              freeSolo
                              disableClearable
                              openOnFocus
                              options={this.state.photoconc_pour_combo2}
                              getOptionLabel={(option) => option.SEMELLE_ETAT || ""}
                              value={this.state.photoconc_combo2_selected_apt}
                              inputValue={this.state.PHOTOCONC[1].SEMELLE_ETAT}
                              onChange={(event, newValue) => this._handle_change_photoconc_etat_semelle(event, newValue, 1)}
                              onInputChange={(event, newValue) => this._handle_inputchange_photoconc_etat_semelle(event, newValue, 1, this.textfield_semelle_apt)}
                              renderInput={(params) => (
                                <TextField {...params} ref={this.textfield_semelle_apt} margin="normal" label={this.state.PARAM.ETAT_MONUMENT_LIBELLE_SEMELLE || "Etat de la semelle"} />
                              )}
                            />
                          )}
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ display: "flex" }}>
                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                          <TextField
                            select
                            fullWidth
                            margin="normal"
                            disabled={this.state.constat_avt_en_cours_de_traiement}
                            label="Monument à gauche"
                            value={this.state.PHOTOCONC[0] !== undefined ? this.state.PHOTOCONC[0].MONUMENT_GAUCHE : ""}
                            onChange={this._handle_change_combo_monument(0, "MONUMENT_GAUCHE")}
                          >
                            <MenuItem style={{ minHeight: 36 }} value=""></MenuItem>
                            <MenuItem value="RAS">RAS</MenuItem>
                            <MenuItem value="PB">Problème</MenuItem>
                          </TextField>
                        </TableCell>

                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                          <TextField
                            select
                            fullWidth
                            margin="normal"
                            disabled={this.state.constat_apt_en_cours_de_traiement}
                            label="Monument à gauche"
                            value={this.state.PHOTOCONC[1] !== undefined ? this.state.PHOTOCONC[1].MONUMENT_GAUCHE : ""}
                            onChange={this._handle_change_combo_monument(1, "MONUMENT_GAUCHE")}
                          >
                            <MenuItem style={{ minHeight: 36 }} value=""></MenuItem>
                            <MenuItem value="RAS">RAS</MenuItem>
                            <MenuItem value="PB">Problème</MenuItem>
                          </TextField>
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ display: "flex" }}>
                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                          <TextField
                            select
                            fullWidth
                            margin="normal"
                            disabled={this.state.constat_avt_en_cours_de_traiement}
                            label="Monument à droite"
                            value={this.state.PHOTOCONC[0] !== undefined ? this.state.PHOTOCONC[0].MONUMENT_DROITE : ""}
                            onChange={this._handle_change_combo_monument(0, "MONUMENT_DROITE")}
                          >
                            <MenuItem style={{ minHeight: 36 }} value=""></MenuItem>
                            <MenuItem value="RAS">RAS</MenuItem>
                            <MenuItem value="PB">Problème</MenuItem>
                          </TextField>
                        </TableCell>

                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                          <TextField
                            select
                            fullWidth
                            margin="normal"
                            disabled={this.state.constat_apt_en_cours_de_traiement}
                            label="Monument à droite"
                            value={this.state.PHOTOCONC[1] !== undefined ? this.state.PHOTOCONC[1].MONUMENT_DROITE : ""}
                            onChange={this._handle_change_combo_monument(1, "MONUMENT_DROITE")}
                          >
                            <MenuItem style={{ minHeight: 36 }} value=""></MenuItem>
                            <MenuItem value="RAS">RAS</MenuItem>
                            <MenuItem value="PB">Problème</MenuItem>
                          </TextField>
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ display: "flex" }}>
                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                          <TextField
                            select
                            fullWidth
                            margin="normal"
                            disabled={this.state.constat_avt_en_cours_de_traiement}
                            label="Monument de devant"
                            value={this.state.PHOTOCONC[0] !== undefined ? this.state.PHOTOCONC[0].MONUMENT_DEVANT : ""}
                            onChange={this._handle_change_combo_monument(0, "MONUMENT_DEVANT")}
                          >
                            <MenuItem style={{ minHeight: 36 }} value=""></MenuItem>
                            <MenuItem value="RAS">RAS</MenuItem>
                            <MenuItem value="PB">Problème</MenuItem>
                          </TextField>
                        </TableCell>

                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0, borderTop: 0, paddingBottom: 0, paddingTop: 0 }}>
                          <TextField
                            select
                            fullWidth
                            margin="normal"
                            disabled={this.state.constat_apt_en_cours_de_traiement}
                            label="Monument de devant"
                            value={this.state.PHOTOCONC[1] !== undefined ? this.state.PHOTOCONC[1].MONUMENT_DEVANT : ""}
                            onChange={this._handle_change_combo_monument(1, "MONUMENT_DEVANT")}
                          >
                            <MenuItem style={{ minHeight: 36 }} value=""></MenuItem>
                            <MenuItem value="RAS">RAS</MenuItem>
                            <MenuItem value="PB">Problème</MenuItem>
                          </TextField>
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ display: "flex" }}>
                        <TableCell style={{ ...styles.tablecells, flex: 1, borderTop: 0, paddingTop: 0 }}>
                          <TextField
                            select
                            fullWidth
                            margin="normal"
                            disabled={this.state.constat_avt_en_cours_de_traiement}
                            label="Monument de derrière"
                            value={this.state.PHOTOCONC[0] !== undefined ? this.state.PHOTOCONC[0].MONUMENT_DERRIERE : ""}
                            onChange={this._handle_change_combo_monument(0, "MONUMENT_DERRIERE")}
                          >
                            <MenuItem style={{ minHeight: 36 }} value=""></MenuItem>
                            <MenuItem value="RAS">RAS</MenuItem>
                            <MenuItem value="PB">Problème</MenuItem>
                          </TextField>
                        </TableCell>

                        <TableCell style={{ ...styles.tablecells, flex: 1, borderTop: 0, paddingTop: 0 }}>
                          <TextField
                            select
                            fullWidth
                            margin="normal"
                            disabled={this.state.constat_apt_en_cours_de_traiement}
                            label="Monument de derrière"
                            value={this.state.PHOTOCONC[1] !== undefined ? this.state.PHOTOCONC[1].MONUMENT_DERRIERE : ""}
                            onChange={this._handle_change_combo_monument(1, "MONUMENT_DERRIERE")}
                          >
                            <MenuItem style={{ minHeight: 36 }} value=""></MenuItem>
                            <MenuItem value="RAS">RAS</MenuItem>
                            <MenuItem value="PB">Problème</MenuItem>
                          </TextField>
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ display: "flex" }}>
                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0 }}>
                          <Typography variant="body2">Signature gardien</Typography>

                          <Autocomplete
                            style={{ width: 200 }}
                            disabled={this.state.constat_avt_en_cours_de_traiement}
                            freeSolo
                            disableClearable
                            openOnFocus
                            options={this.state.agentterrain}
                            getOptionLabel={(option) => `${option.NOM} ${option.PRENOM}`}
                            value={this.state.selected_agent_avt}
                            inputValue={this.state.PHOTOCONC[0].AGENT || ""}
                            onChange={(event, newValue) => this._handle_change_photoconc_agent(event, newValue, 0, this.PAD_AVT2)}
                            onInputChange={(event, newValue) => this._handle_inputchange_photoconc_agent(event, newValue, 0, this.PAD_AVT2, this.autocomplete_textinput_avt2)}
                            renderInput={(params) => <TextField {...params} inputRef={this.autocomplete_textinput_avt2} placeholder="Nom prénom" />}
                          />
                        </TableCell>

                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0 }}>
                          <Typography variant="body2">Signature gardien</Typography>

                          <Autocomplete
                            style={{ width: 200 }}
                            disabled={this.state.constat_apt_en_cours_de_traiement}
                            freeSolo
                            disableClearable
                            openOnFocus
                            options={this.state.agentterrain}
                            getOptionLabel={(option) => `${option.NOM} ${option.PRENOM}`}
                            value={this.state.selected_agent_apt}
                            inputValue={this.state.PHOTOCONC[1].AGENT}
                            onChange={(event, newValue) => this._handle_change_photoconc_agent(event, newValue, 1, this.PAD_APT2)}
                            onInputChange={(event, newValue) => this._handle_inputchange_photoconc_agent(event, newValue, 1, this.PAD_APT2, this.autocomplete_textinput_apt2)}
                            renderInput={(params) => <TextField {...params} inputRef={this.autocomplete_textinput_apt2} placeholder="Nom prénom" />}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ display: "flex" }}>
                        <TableCell style={{ ...styles.tablecells, flex: 1, borderTop: 0, height: "fit-content" }}>
                          <div style={{ width: 260, height: 200, margin: "0 auto", position: "relative" }}>
                            <SignatureCanvas
                              ref={(ref) => {
                                this.PAD_AVT2 = ref;
                              }}
                              clearOnResize={false}
                              canvasProps={{ style: { width: "100%", height: "100%", border: "1px solid", borderRadius: 10 } }}
                              onBegin={(event) => {
                                const photoconc = this.state.PHOTOCONC;

                                if (this.state.constat_avt_en_cours_de_traiement) {
                                  this.PAD_AVT2.off();
                                } else {
                                  if (photoconc[0].AGENT === "") {
                                    if (event.preventDefault) {
                                      event.preventDefault();
                                    } else {
                                      event.returnValue = false;
                                    }

                                    this._clear_pad("PAD_AVT2");
                                    this.PAD_AVT2.off();
                                    this.autocomplete_textinput_avt2.current.focus();
                                    this.PAD_AVT2.on();
                                  }
                                }
                              }}
                              onEnd={() => {
                                const photoconc = this.state.PHOTOCONC;
                                photoconc[0].SIGNATURE_AGENT = b64toBlob(this.PAD_AVT2.toDataURL("image/png").replace("data:image/png;base64,", ""), "image/png");

                                this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                              }}
                            />

                            <IconButton
                              aria-label="Netoyer"
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              color="secondary"
                              style={{ position: "absolute", top: 0, right: 0 }}
                              onClick={() => {
                                this._clear_pad("PAD_AVT2");

                                const photoconc = this.state.PHOTOCONC;
                                photoconc[0].SIGNATURE_AGENT = null;

                                this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                              }}
                            >
                              <Close />
                            </IconButton>
                          </div>
                        </TableCell>

                        <TableCell style={{ ...styles.tablecells, flex: 1, borderTop: 0, height: "fit-content" }}>
                          <div style={{ width: 260, height: 200, margin: "0 auto", position: "relative" }}>
                            <SignatureCanvas
                              ref={(ref) => {
                                this.PAD_APT2 = ref;
                              }}
                              clearOnResize={false}
                              canvasProps={{ style: { width: "100%", height: "100%", border: "1px solid", borderRadius: 10 } }}
                              onBegin={(event) => {
                                const photoconc = this.state.PHOTOCONC;

                                if (this.state.constat_apt_en_cours_de_traiement) {
                                  this.PAD_APT2.off();
                                } else {
                                  if (photoconc[1].AGENT === "") {
                                    if (event.preventDefault) {
                                      event.preventDefault();
                                    } else {
                                      event.returnValue = false;
                                    }

                                    this._clear_pad("PAD_APT2");
                                    this.PAD_APT2.off();
                                    this.autocomplete_textinput_apt2.current.focus();
                                    this.PAD_APT2.on();
                                  }
                                }
                              }}
                              onEnd={() => {
                                const photoconc = this.state.PHOTOCONC;
                                photoconc[1].SIGNATURE_AGENT = b64toBlob(this.PAD_APT2.toDataURL("image/png").replace("data:image/png;base64,", ""), "image/png");

                                this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                              }}
                            />

                            <IconButton
                              aria-label="Netoyer"
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              color="secondary"
                              style={{ position: "absolute", top: 0, right: 0 }}
                              onClick={() => {
                                this._clear_pad("PAD_APT2");

                                const photoconc = this.state.PHOTOCONC;
                                photoconc[1].SIGNATURE_AGENT = null;

                                this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                              }}
                            >
                              <Close />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ display: "flex" }}>
                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0 }}>
                          <Typography variant="body2">Signature opérateur</Typography>

                          <TextField
                            style={{ width: 200 }}
                            disabled={this.state.constat_avt_en_cours_de_traiement}
                            inputRef={this.textinput_soc_avt2}
                            placeholder="Nom"
                            defaultValue={this.state.PHOTOCONC[0].SOCIETE}
                            onChange={this._handle_inputchange_photoconc_societe(0, this.PAD_SOC_AVT2)}
                          />
                        </TableCell>

                        <TableCell style={{ ...styles.tablecells, flex: 1, borderBottom: 0 }}>
                          <Typography variant="body2">Signature opérateur</Typography>

                          <TextField
                            style={{ width: 200 }}
                            disabled={this.state.constat_apt_en_cours_de_traiement}
                            inputRef={this.textinput_soc_apt2}
                            placeholder="Nom"
                            defaultValue={this.state.PHOTOCONC[1].SOCIETE}
                            onChange={this._handle_inputchange_photoconc_societe(1, this.PAD_SOC_APT2)}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow style={{ display: "flex" }}>
                        <TableCell style={{ ...styles.tablecells, flex: 1, borderTop: 0, height: "fit-content" }}>
                          <div style={{ width: 260, height: 200, margin: "0 auto", position: "relative" }}>
                            <SignatureCanvas
                              ref={(ref) => {
                                this.PAD_SOC_AVT2 = ref;
                              }}
                              clearOnResize={false}
                              canvasProps={{ style: { width: "100%", height: "100%", border: "1px solid", borderRadius: 10 } }}
                              onBegin={(event) => {
                                const photoconc = this.state.PHOTOCONC;

                                if (this.state.constat_avt_en_cours_de_traiement) {
                                  this.PAD_SOC_AVT2.off();
                                } else {
                                  if (photoconc[0].SOCIETE === "") {
                                    if (event.preventDefault) {
                                      event.preventDefault();
                                    } else {
                                      event.returnValue = false;
                                    }

                                    this._clear_pad("PAD_SOC_AVT2");
                                    this.PAD_SOC_AVT2.off();
                                    this.textinput_soc_avt2.current.focus();

                                    if (photoconc[0].SOCIETE !== "") this.PAD_SOC_AVT2.on();
                                  }
                                }
                              }}
                              onEnd={() => {
                                const photoconc = this.state.PHOTOCONC;
                                photoconc[0].SIGNATURE_SOCIETE = b64toBlob(this.PAD_SOC_AVT2.toDataURL("image/png").replace("data:image/png;base64,", ""), "image/png");

                                this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                              }}
                            />

                            <IconButton
                              aria-label="Netoyer"
                              disabled={this.state.constat_avt_en_cours_de_traiement}
                              color="secondary"
                              style={{ position: "absolute", top: 0, right: 0 }}
                              onClick={() => {
                                this._clear_pad("PAD_SOC_AVT2");

                                const photoconc = this.state.PHOTOCONC;
                                photoconc[0].SIGNATURE_SOCIETE = null;

                                this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                              }}
                            >
                              <Close />
                            </IconButton>
                          </div>
                        </TableCell>

                        <TableCell style={{ ...styles.tablecells, flex: 1, borderTop: 0, height: "fit-content" }}>
                          <div style={{ width: 260, height: 200, margin: "0 auto", position: "relative" }}>
                            <SignatureCanvas
                              ref={(ref) => {
                                this.PAD_SOC_APT2 = ref;
                              }}
                              clearOnResize={false}
                              canvasProps={{ style: { width: "100%", height: "100%", border: "1px solid", borderRadius: 10 } }}
                              onBegin={(event) => {
                                const photoconc = this.state.PHOTOCONC;

                                if (this.state.constat_apt_en_cours_de_traiement) {
                                  this.PAD_SOC_APT2.off();
                                } else {
                                  if (photoconc[1].SOCIETE === "") {
                                    if (event.preventDefault) {
                                      event.preventDefault();
                                    } else {
                                      event.returnValue = false;
                                    }

                                    this._clear_pad("PAD_SOC_APT2");
                                    this.PAD_SOC_APT2.off();
                                    this.textinput_soc_apt2.current.focus();

                                    if (photoconc[1].SOCIETE !== "") this.PAD_SOC_APT2.on();
                                  }
                                }
                              }}
                              onEnd={() => {
                                const photoconc = this.state.PHOTOCONC;
                                photoconc[1].SIGNATURE_SOCIETE = b64toBlob(this.PAD_SOC_APT2.toDataURL("image/png").replace("data:image/png;base64,", ""), "image/png");

                                this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                              }}
                            />

                            <IconButton
                              aria-label="Netoyer"
                              disabled={this.state.constat_apt_en_cours_de_traiement}
                              color="secondary"
                              style={{ position: "absolute", top: 0, right: 0 }}
                              onClick={() => {
                                this._clear_pad("PAD_SOC_APT2");

                                const photoconc = this.state.PHOTOCONC;
                                photoconc[1].SIGNATURE_SOCIETE = null;

                                this.setState({ PHOTOCONC: photoconc, disabledForm: false });
                              }}
                            >
                              <Close />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                )
              ) : null}

              <div style={styles.submit_div}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Annuler
                </Button>

                <Button variant="contained" color="primary" type="submit" disabled={this.state.disabledForm}>
                  Enregistrer
                </Button>
              </div>
            </form>

            <Dialog open={this.state.dialog_traitement} onClose={() => this.setState({ dialog_traitement: false })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle onClose={() => this.setState({ dialog_traitement: false })} id="alert-dialog-title">
                <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ dialog_traitement: false })}>
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Modification impossible
                  <br />
                  Photographie en cours de traitement dans Gescime
                </DialogContentText>
              </DialogContent>
            </Dialog>

            <Dialog open={this.state.errorMessage !== ""} onClose={() => this.setState({ errorMessage: "" })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle onClose={() => this.setState({ errorMessage: "" })} id="alert-dialog-title">
                <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ errorMessage: "" })}>
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText style={{ marginTop: 12 }} id="alert-dialog-description">
                  {this.state.errorMessage}
                </DialogContentText>
              </DialogContent>
            </Dialog>

            {this.state.photoDialogPhoto !== null ? (
              <Dialog
                fullScreen
                open={this.state.photoDialogOpen}
                onClose={() => {
                  this.setState({ photoDialogOpen: false });
                }}
                TransitionComponent={Transition}
              >
                <AppBar className={styles.appBar}>
                  <Toolbar>
                    <Typography variant="h6" style={styles.typography}></Typography>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        this.setState({ photoDialogOpen: false });
                      }}
                      aria-label="close"
                    >
                      <Close />
                    </IconButton>
                  </Toolbar>
                </AppBar>

                <Card style={styles.photoconc_card_dialog} elevation={0}>
                  <CardMedia style={styles.photoconc_cardmedia_dialog} image={window.URL.createObjectURL(this.state.photoDialogPhoto)} />
                </Card>
              </Dialog>
            ) : null}

            <DialogAccessRight state={this.state.droit_util_ajout_photoconc} onChange={(value) => this.setState({ droit_util_ajout_photoconc: value })} />
            <DialogAccessRight state={this.state.droit_util_modif_photoconc} onChange={(value) => this.setState({ droit_util_modif_photoconc: value })} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withWidth()(withCookies(withRouter(MTravaux)));
