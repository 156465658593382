import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { format } from "date-fns";
import frLocale from "date-fns/locale/fr";

import { IsOracle } from "./../../../Helpers/Test/IsOracle";
import { GetIntervenById } from "./../../../Helpers/Get/Interven";
import { GetCimetierById } from "./../../../Helpers/Get/Cimetier";
import { GetLieuById } from "../../../Helpers/Get/Lieu";
import { GetConcessById } from "../../../Helpers/Get/Concess";
import { GetSocieteById } from "./../../../Helpers/Get/Societe";
import { GetNatintervByIdInterven } from "./../../../Helpers/Get/Natinterv";
import { GetIntervenDefuntByIdInterven } from "./../../../Helpers/Get/IntervenDefunt";
import { GetAllDefunt } from "./../../../Helpers/Get/Defunt";
import { GetCendreByIdLieu } from "./../../../Helpers/Get/Cendre";
import { GetZoneByTypezone } from "../../../Helpers/Get/Zone";
import { GetPlanById } from "./../../../Helpers/Get/Plan";
import { GetZoneById } from "./../../../Helpers/Get/Zone";

import SousPlan from "./../../SousPlan";
import PlanGeneral from "./../../PlanGeneral";
import CTFTPhotographieConstat from "./CTFicheTravaux/CTFTPhotographieConstat";
import { GetTemporaireForColor } from "./../../../Helpers/Get/Temporaire";
import { GetParam } from "./../../../Helpers/Get/Param";
import { Close } from "@material-ui/icons";
import { CheckUserRight } from "./../../../Helpers/Get/LigneAction";
import { DialogAccessRight } from "./../../Global/DialogAccessRight";
import PlanOperateurFiche from "./../../PlanOperateurFiche";

var _ = require("lodash");

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
};

const ButtonModifier = React.forwardRef((props, ref) => {
  return (
    <Button
      variant="contained"
      innerRef={ref}
      color="primary"
      disabled={props.en_cours_de_traitement}
      onClick={() => {
        CheckUserRight(props.id_util, "INTERVEN", "DROITMODIF").then((asRight) => {
          props.onChange(asRight);
        });
      }}
    >
      Modifier
    </Button>
  );
});

class CTFicheTravaux extends Component {
  constructor(props) {
    super(props);

    this.DEFUNT = [];
    this.MODIFIER_ref = React.createRef();

    this.state = {
      IS_ORACLE: false,
      ID_UTIL: atob(this.props.cookies.get(encodeURIComponent(btoa("ID_UTIL")))),
      PARAM: "",
      CIMETIER: "",
      LIEU: "",
      CENDRE: "",
      CONCESS: "",
      SOCIETE: "",
      NATINTERV: "",
      INTERVEN_DEFUNT: [],
      INTERVEN: "",
      SOUS_PLAN: false,
      REDIRECT: null,
      champ_modifie: [],
      DIALOG_SANS_CONCESSION: false,
      droit_util_modif_interven: false,
    };
  }

  componentDidMount = () => {
    IsOracle().then((oracle) => {
      this.setState({ IS_ORACLE: oracle });
    });

    GetParam().then((param) => {
      this.setState({ PARAM: param });
    });

    if (this.props.id_travaux !== undefined) {
      GetAllDefunt().then((defunts) => {
        this.DEFUNT = defunts;
      });

      GetIntervenById(this.props.id_travaux).then((interven) => {
        this.props.changeTitle(interven.EMPL_DEF_OU_CONCESSION);

        GetCimetierById(interven.TYPECIME).then((cimetier) => {
          GetLieuById(interven.LICLEUNIK).then((lieu) => {
            GetCendreByIdLieu(interven.LICLEUNIK).then((cendre) => {
              if (cendre !== undefined) {
                GetZoneByTypezone(cendre.NUMCENDRE).then((zone) => {
                  GetPlanById(cimetier.NUMPLAN).then((plan) => {
                    this.setState({
                      CENDRE: cendre,
                      ZONE: zone,
                      PLAN: plan,
                    });
                  });
                });
              } else {
                if (lieu !== undefined) {
                  GetZoneById(lieu.SITUATION).then((zone) => {
                    GetPlanById(cimetier.NUMPLAN).then((plan) => {
                      this.setState({
                        CENDRE: cendre,
                        ZONE: zone,
                        PLAN: plan,
                      });
                    });
                  });
                }
              }
            });

            GetConcessById(interven.COCLEUNIK).then((concess) => {
              if (concess === undefined) {
                this.setState({ DIALOG_SANS_CONCESSION: true });
              }

              GetSocieteById(interven.SOCLEUNIK).then((societe) => {
                GetNatintervByIdInterven(interven.I0CLEUNIK).then((natinterv) => {
                  GetIntervenDefuntByIdInterven(interven.I0CLEUNIK).then((intervendefunt) => {
                    this.setState({
                      CIMETIER: cimetier,
                      LIEU: lieu,
                      CONCESS: concess,
                      SOCIETE: societe,
                      NATINTERV: natinterv,
                      INTERVEN_DEFUNT: intervendefunt,
                      INTERVEN: interven,
                    });
                  });
                });
              });
            });
          });
        });

        let field = {};
        const temporaire = GetTemporaireForColor("INTERVEN", "I0CLEUNIK", this.props.id_travaux);
        temporaire.then((temp) => {
          _.forEach(temp, (t) => {
            let color = "red";

            if (t.DATE_D_ENVOI_A_GESCIME !== null) {
              color = "blue";
            }

            if (t.DATE_D_IMPORT_DANS_GESCIME !== null) {
              color = "green";
            }

            if (t.DATE_TRAITE_DANS_GESCIME !== null) {
              color = "primary";
            }

            var element_temp = t.TABLE_MODIFIE_JSON;
            element_temp = _.omit(element_temp, ["id", "I0CLEUNIK"]);

            if (this.state.IS_ORACLE) {
              for (var key in element_temp) {
                if (key === "DATETRAVAUX" || key === "DATEFINTRAVAUX") {
                  var prevDate = t.ETAT_INITIAL[key === "DATETRAVAUX" ? "DATETRAVAUX" : "DATETRAVAUX_FIN"].split("T")[0];

                  field[key] = "primary";
                  if (prevDate !== element_temp[key]) field[key] = color;
                } else if (key === "HEUREARRIVEE" || key === "HEUREDEPART") {
                  var prevHeure = t.ETAT_INITIAL[key === "HEUREARRIVEE" ? "DATETRAVAUX" : "DATETRAVAUX_FIN"].split("T")[1].split(":");
                  prevHeure.pop();
                  prevHeure = _.join(prevHeure, ":");
                  field[key] = "primary";
                  if (prevHeure !== element_temp[key]) field[key] = color;
                } else {
                  field[key] = color;
                }
              }
            } else {
              for (key in element_temp) {
                if (key === "DATETRAVAUX" || key === "DATETRAVAUX_FIN") {
                  prevDate = t.ETAT_INITIAL[key].split("T")[0];

                  field[key] = "primary";
                  if (prevDate !== element_temp[key]) field[key] = color;
                } else if (key === "HEUREDEBUT" || key === "HEUREDEPART") {
                  prevHeure = t.ETAT_INITIAL[key === "HEUREDEBUT" ? "DATETRAVAUX" : "DATETRAVAUX_FIN"].split("T")[1].split(":");
                  prevHeure = _.join(prevHeure, ":");

                  field[key] = "primary";
                  if (prevHeure !== element_temp[key]) field[key] = color;
                } else {
                  field[key] = color;
                }
              }
            }
          });

          this.setState({
            champ_modifie: field,
          });
        });
      });
    }
  };

  _cree_chaine_emplacement = () => {
    var empl = "";

    if (this.state.CENDRE !== undefined && this.state.CENDRE !== "") {
      empl = this.state.CENDRE.NOMCENDRE;
    } else {
      empl = _.filter([
        _.filter([this.state.LIEU.EMPLACEMENT.TYPECAR, this.state.LIEU.EMPLACEMENT.CARRE]).join(" "),
        _.filter([this.state.LIEU.EMPLACEMENT.TYPERG, this.state.LIEU.EMPLACEMENT.RANG]).join(" "),
        _.filter([this.state.LIEU.EMPLACEMENT.TYPEFOS, this.state.LIEU.EMPLACEMENT.TOMBE]).join(" "),
      ]).join(" - ");

      const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

      if (reg.test(empl)) {
        const TYPESEC = this.state.LIEU.EMPLACEMENT.TYPECAR.split("/")[0];
        const SECTION = this.state.LIEU.EMPLACEMENT.CARRE.split("/")[0];
        const TYPECAR = this.state.LIEU.EMPLACEMENT.TYPECAR.split("/")[1];
        const CARRE = this.state.LIEU.EMPLACEMENT.CARRE.split("/")[1];

        empl = _.filter([
          _.filter([TYPESEC, SECTION]).join(" "),
          _.filter([TYPECAR, CARRE]).join(" "),
          _.filter([this.state.LIEU.EMPLACEMENT.TYPERG, this.state.LIEU.EMPLACEMENT.RANG]).join(" "),
          _.filter([this.state.LIEU.EMPLACEMENT.TYPEFOS, this.state.LIEU.EMPLACEMENT.TOMBE]).join(" "),
        ]).join(" - ");
      }
    }

    return empl;
  };

  _cree_chaine_suivi_travaux = (suivi) => {
    switch (suivi) {
      case 2:
        return "En attente";
      case 3:
        return "En cours";
      case 4:
        return "Suspendu";
      case 5:
        return "Terminé";
      default:
        return "Inconnu";
    }
  };

  _cree_chaine_nom_societe = () => {
    let nom = "";

    if (this.state.INTERVEN.INTERVENANT_NOM !== "") {
      nom = this.state.INTERVEN.INTERVENANT_NOM;
    } else {
      if (this.state.SOCIETE !== "" && this.state.SOCIETE !== undefined) {
        nom = this.state.SOCIETE.NOMSOCIETE;
      }
    }

    return nom;
  };

  _cree_chaine_typerest = () => {
    const natinterv = this.state.NATINTERV;

    let type = "";
    for (let index = 0; index < natinterv.length; index++) {
      const element = natinterv[index];

      switch (element.TYPEREST) {
        case 2:
          if (!type.includes("Opération funéraire")) type += "Opération funéraire, ";
          break;
        case 3:
          if (!type.includes("Autre")) type += "Autre, ";
          break;
        default:
          if (!type.includes("Travaux")) type += "Travaux, ";
          break;
      }
    }

    return type.trim().slice(0, -1);
  };

  _cree_chaine_libintervention = () => {
    const natinterv = this.state.NATINTERV;

    let lib = "";
    for (let index = 0; index < natinterv.length; index++) {
      const element = natinterv[index];

      if (!lib.includes(element.LIBINTERVENTION)) lib += `${element.LIBINTERVENTION}, `;
    }

    return lib.trim().slice(0, -1);
  };

  _complete_table_defunt = (intervendefunt, index) => {
    let DEFUNT = this.DEFUNT.filter((defunt) => {
      return defunt.D0CLEUNIK === intervendefunt.D0CLEUNIK;
    });

    if (DEFUNT.length === 0) {
      return (
        <TableRow key={index}>
          <TableCell></TableCell>
          <TableCell align="right"></TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow key={index}>
        <TableCell>{`${DEFUNT[0].ETAT_CIVIL.NOMDEF} ${DEFUNT[0].ETAT_CIVIL.PRENOMDEF}`}</TableCell>
        <TableCell align="right">{DEFUNT[0].ETAT_CIVIL.DATEDECES !== "" && new Date(DEFUNT[0].ETAT_CIVIL.DATEDECES).toLocaleDateString()}</TableCell>
      </TableRow>
    );
  };

  _changePlan = (event) => {
    event.preventDefault();

    this.setState({ SOUS_PLAN: !this.state.SOUS_PLAN });
  };

  render() {
    console.log("RENDER FICHE-TRAVAUX");

    if (this.state.REDIRECT !== null) {
      return <Redirect to={this.state.REDIRECT} push />;
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} md={8} style={styles.grid_item}>
          <Paper style={styles.paper}>
            {this.state.INTERVEN === "" ? <CircularProgress style={{ position: "absolute", left: "50%", right: "50%", top: "50%", bottom: "50%" }} /> : null}

            {this.state.INTERVEN !== "" ? (
              <div>
                {this.state.LIEU !== undefined && this.state.LIEU.SITUATION !== 255 ? (
                  this.state.PLAN !== undefined ? (
                    this.state.PLAN.PLAN_V4 === 1 ? (
                      this.state.PLAN.IMAGEPL !== "" ? (
                        <div>
                          <Paper elevation={0} style={styles.paper}>
                            <PlanOperateurFiche
                              id_lieu={this.state.INTERVEN.LICLEUNIK}
                              cimetier={this.state.CIMETIER}
                              plan={this.state.PLAN}
                              sousplan={this.state.SOUS_PLAN}
                              change_plang={() => {
                                this.setState({
                                  SOUS_PLAN: !this.state.SOUS_PLAN,
                                });
                              }}
                              from_button={this.state.FROM_BUTTON}
                              change_from_button={() => {
                                this.setState({
                                  FROM_BUTTON: false,
                                });
                              }}
                              reverse={this.state.REVERSE}
                            />
                          </Paper>

                          <Paper
                            elevation={0}
                            style={{
                              ...styles.paper,
                              ...{
                                display: "flex",
                                justifyContent: "space-between",
                              },
                            }}
                          >
                            <ButtonModifier
                              ref={this.MODIFIER_ref}
                              id_util={this.state.ID_UTIL}
                              onChange={(value) => {
                                if (value) {
                                  this.setState({
                                    REDIRECT: `/modifier/travaux/${this.state.INTERVEN.I0CLEUNIK}`,
                                  });
                                } else {
                                  this.setState({ droit_util_modif_interven: true });
                                }
                              }}
                            />

                            {this.state.ZONE !== undefined ? (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  this.setState({
                                    SOUS_PLAN: !this.state.SOUS_PLAN,
                                    FROM_BUTTON: true,
                                  });
                                }}
                              >
                                {this.state.SOUS_PLAN ? "Voir le plan général" : "Voir le sous-plan"}
                              </Button>
                            ) : null}
                          </Paper>
                        </div>
                      ) : (
                        <Paper
                          elevation={0}
                          style={{
                            ...styles.paper,
                            ...{
                              display: "flex",
                              justifyContent: "space-between",
                            },
                          }}
                        >
                          <ButtonModifier
                            ref={this.MODIFIER_ref}
                            id_util={this.state.ID_UTIL}
                            onChange={(value) => {
                              if (value) {
                                this.setState({
                                  REDIRECT: `/modifier/travaux/${this.state.INTERVEN.I0CLEUNIK}`,
                                });
                              } else {
                                this.setState({ droit_util_modif_interven: true });
                              }
                            }}
                          />
                        </Paper>
                      )
                    ) : this.state.PLAN.IMAGEPL !== "" && this.state.ZONE !== undefined ? (
                      <div>
                        <Paper elevation={0} style={styles.paper}>
                          <svg
                            width="100%"
                            height="100%"
                            viewBox={this.state.SOUS_PLAN ? `0 0 1024 768` : this.state.IS_ORACLE ? `0 0 1024 617` : `0 0 1024 768`}
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                          >
                            {this.state.SOUS_PLAN ? <SousPlan id_lieu={this.state.INTERVEN.LICLEUNIK} /> : <PlanGeneral id_lieu={this.state.INTERVEN.LICLEUNIK} afficheSousPlan={this._changePlan} />}
                          </svg>
                        </Paper>

                        <Paper
                          elevation={0}
                          style={{
                            ...styles.paper,
                            ...{
                              display: "flex",
                              justifyContent: "space-between",
                            },
                          }}
                        >
                          <ButtonModifier
                            ref={this.MODIFIER_ref}
                            id_util={this.state.ID_UTIL}
                            onChange={(value) => {
                              if (value) {
                                this.setState({
                                  REDIRECT: `/modifier/travaux/${this.state.INTERVEN.I0CLEUNIK}`,
                                });
                              } else {
                                this.setState({ droit_util_modif_interven: true });
                              }
                            }}
                          />

                          {this.state.ZONE !== undefined ? (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                this.setState({
                                  SOUS_PLAN: !this.state.SOUS_PLAN,
                                });
                              }}
                            >
                              {this.state.SOUS_PLAN ? "Voir le plan général" : "Voir le sous-plan"}
                            </Button>
                          ) : null}
                        </Paper>
                      </div>
                    ) : (
                      <Paper
                        elevation={0}
                        style={{
                          ...styles.paper,
                          ...{
                            display: "flex",
                            justifyContent: "space-between",
                          },
                        }}
                      >
                        <ButtonModifier
                          ref={this.MODIFIER_ref}
                          id_util={this.state.ID_UTIL}
                          onChange={(value) => {
                            if (value) {
                              this.setState({
                                REDIRECT: `/modifier/travaux/${this.state.INTERVEN.I0CLEUNIK}`,
                              });
                            } else {
                              this.setState({ droit_util_modif_interven: true });
                            }
                          }}
                        />

                        {this.state.ZONE !== undefined ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.setState({
                                SOUS_PLAN: !this.state.SOUS_PLAN,
                              });
                            }}
                          >
                            {this.state.SOUS_PLAN ? "Voir le plan général" : "Voir le sous-plan"}
                          </Button>
                        ) : null}
                      </Paper>
                    )
                  ) : (
                    <Paper
                      elevation={0}
                      style={{
                        ...styles.paper,
                        ...{
                          display: "flex",
                          justifyContent: "space-between",
                        },
                      }}
                    >
                      <ButtonModifier
                        ref={this.MODIFIER_ref}
                        id_util={this.state.ID_UTIL}
                        onChange={(value) => {
                          if (value) {
                            this.setState({
                              REDIRECT: `/modifier/travaux/${this.state.INTERVEN.I0CLEUNIK}`,
                            });
                          } else {
                            this.setState({ droit_util_modif_interven: true });
                          }
                        }}
                      />

                      {this.state.ZONE !== undefined ? (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            this.setState({
                              SOUS_PLAN: !this.state.SOUS_PLAN,
                            });
                          }}
                        >
                          {this.state.SOUS_PLAN ? "Voir le plan général" : "Voir le sous-plan"}
                        </Button>
                      ) : null}
                    </Paper>
                  )
                ) : (
                  <Paper
                    elevation={0}
                    style={{
                      ...styles.paper,
                      ...{
                        display: "flex",
                        justifyContent: "space-between",
                      },
                    }}
                  >
                    <ButtonModifier
                      ref={this.MODIFIER_ref}
                      id_util={this.state.ID_UTIL}
                      onChange={(value) => {
                        if (value) {
                          this.setState({
                            REDIRECT: `/modifier/travaux/${this.state.INTERVEN.I0CLEUNIK}`,
                          });
                        } else {
                          this.setState({ droit_util_modif_interven: true });
                        }
                      }}
                    />

                    {this.state.ZONE !== undefined ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.setState({
                            SOUS_PLAN: !this.state.SOUS_PLAN,
                          });
                        }}
                      >
                        {this.state.SOUS_PLAN ? "Voir le plan général" : "Voir le sous-plan"}
                      </Button>
                    ) : null}
                  </Paper>
                )}

                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{
                          color: _.has(this.state.champ_modifie, "TYPECIME") ? _.get(this.state.champ_modifie, "TYPECIME") : "primary",
                        }}
                      >
                        Cimetière
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: _.has(this.state.champ_modifie, "TYPECIME") ? _.get(this.state.champ_modifie, "TYPECIME") : "primary",
                        }}
                      >
                        {this.state.CIMETIER.NOMCIME}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          color: _.has(this.state.champ_modifie, "LICLEUNIK") ? _.get(this.state.champ_modifie, "LICLEUNIK") : "primary",
                        }}
                      >
                        {this.state.LIEU !== undefined
                          ? this.state.LIEU.EMPLACEMENT.CARRE.includes("Provisoire") || this.state.LIEU.EMPLACEMENT.CARRE.includes("Non affect")
                            ? this.state.PARAM.LIBELLEANCIENNUMPLAN !== ""
                              ? this.state.PARAM.LIBELLEANCIENNUMPLAN
                              : "Emplacement"
                            : "Emplacement"
                          : "Emplacement"}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: _.has(this.state.champ_modifie, "LICLEUNIK") ? _.get(this.state.champ_modifie, "LICLEUNIK") : "primary",
                        }}
                      >
                        {this._cree_chaine_emplacement()}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          color: _.has(this.state.champ_modifie, "EMPL_DEF_OU_CONCESSION") ? _.get(this.state.champ_modifie, "EMPL_DEF_OU_CONCESSION") : "primary",
                        }}
                      >
                        Concession
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: _.has(this.state.champ_modifie, "EMPL_DEF_OU_CONCESSION") ? _.get(this.state.champ_modifie, "EMPL_DEF_OU_CONCESSION") : "primary",
                        }}
                      >
                        {this.state.CONCESS !== undefined ? this.state.CONCESS.FAMILLE : ""}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          color: _.has(this.state.champ_modifie, "DATETRAVAUX") ? _.get(this.state.champ_modifie, "DATETRAVAUX") : "primary",
                        }}
                      >
                        Début de l'intervention
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: _.has(this.state.champ_modifie, "DATETRAVAUX") ? _.get(this.state.champ_modifie, "DATETRAVAUX") : "primary",
                        }}
                      >
                        {format(new Date(this.state.INTERVEN.DATETRAVAUX), "dd/MM/yyyy", {
                          locale: frLocale,
                        }) !== "01/01/1900"
                          ? format(new Date(this.state.INTERVEN.DATETRAVAUX), "dd/MM/yyyy", {
                              locale: frLocale,
                            })
                          : null}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          color: this.state.IS_ORACLE
                            ? _.has(this.state.champ_modifie, "HEUREARRIVEE")
                              ? _.get(this.state.champ_modifie, "HEUREARRIVEE")
                              : "primary"
                            : _.has(this.state.champ_modifie, "HEUREDEBUT")
                            ? _.get(this.state.champ_modifie, "HEUREDEBUT")
                            : "primary",
                        }}
                      >
                        Heure de début
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: this.state.IS_ORACLE
                            ? _.has(this.state.champ_modifie, "HEUREARRIVEE")
                              ? _.get(this.state.champ_modifie, "HEUREARRIVEE")
                              : "primary"
                            : _.has(this.state.champ_modifie, "HEUREDEBUT")
                            ? _.get(this.state.champ_modifie, "HEUREDEBUT")
                            : "primary",
                        }}
                      >
                        {format(new Date(this.state.INTERVEN.DATETRAVAUX), "HH:mm:ss", {
                          locale: frLocale,
                        }) !== "00:00:01"
                          ? format(new Date(this.state.INTERVEN.DATETRAVAUX), "HH:mm", {
                              locale: frLocale,
                            })
                          : null}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          color: this.state.IS_ORACLE
                            ? _.has(this.state.champ_modifie, "DATEFINTRAVAUX")
                              ? _.get(this.state.champ_modifie, "DATEFINTRAVAUX")
                              : "primary"
                            : _.has(this.state.champ_modifie, "DATETRAVAUX_FIN")
                            ? _.get(this.state.champ_modifie, "DATETRAVAUX_FIN")
                            : "primary",
                        }}
                      >
                        Fin de l'intervention
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: this.state.IS_ORACLE
                            ? _.has(this.state.champ_modifie, "DATEFINTRAVAUX")
                              ? _.get(this.state.champ_modifie, "DATEFINTRAVAUX")
                              : "primary"
                            : _.has(this.state.champ_modifie, "DATETRAVAUX_FIN")
                            ? _.get(this.state.champ_modifie, "DATETRAVAUX_FIN")
                            : "primary",
                        }}
                      >
                        {format(new Date(this.state.INTERVEN.DATETRAVAUX_FIN), "dd/MM/yyyy", {
                          locale: frLocale,
                        }) !== "01/01/1900"
                          ? format(new Date(this.state.INTERVEN.DATETRAVAUX_FIN), "dd/MM/yyyy", {
                              locale: frLocale,
                            })
                          : null}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          color: _.has(this.state.champ_modifie, "HEUREDEPART") ? _.get(this.state.champ_modifie, "HEUREDEPART") : "primary",
                        }}
                      >
                        Heure de fin
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: _.has(this.state.champ_modifie, "HEUREDEPART") ? _.get(this.state.champ_modifie, "HEUREDEPART") : "primary",
                        }}
                      >
                        {format(new Date(this.state.INTERVEN.DATETRAVAUX_FIN), "HH:mm:ss", {
                          locale: frLocale,
                        }) !== "00:00:01"
                          ? format(new Date(this.state.INTERVEN.DATETRAVAUX_FIN), "HH:mm", {
                              locale: frLocale,
                            })
                          : null}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          color: _.has(this.state.champ_modifie, "SUIVITRAV")
                            ? _.get(this.state.champ_modifie, "SUIVITRAV")
                            : _.has(this.state.champ_modifie, "SUIVI_TRAVAUX")
                            ? _.get(this.state.champ_modifie, "SUIVI_TRAVAUX")
                            : "primary",
                        }}
                      >
                        Suivi
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: _.has(this.state.champ_modifie, "SUIVITRAV")
                            ? _.get(this.state.champ_modifie, "SUIVITRAV")
                            : _.has(this.state.champ_modifie, "SUIVI_TRAVAUX")
                            ? _.get(this.state.champ_modifie, "SUIVI_TRAVAUX")
                            : "primary",
                        }}
                      >
                        {this._cree_chaine_suivi_travaux(this.state.INTERVEN.SUIVITRAV)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell
                        style={{
                          color: _.has(this.state.champ_modifie, "SOCLEUNIK") ? _.get(this.state.champ_modifie, "SOCLEUNIK") : "primary",
                        }}
                      >
                        Intervenant
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{
                          color: _.has(this.state.champ_modifie, "SOCLEUNIK") ? _.get(this.state.champ_modifie, "SOCLEUNIK") : "primary",
                        }}
                      >
                        {this._cree_chaine_nom_societe(this.state.INTERVEN.SUIVITRAV)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>Notes</TableCell>
                      <TableCell align="right" style={{ whiteSpace: "pre-wrap" }}>
                        {this.state.INTERVEN.TRAVAUX}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <hr
                  style={{
                    height: 3,
                    borderBottom: "1px solid grey",
                    borderTop: "1px solid grey",
                    margin: "24px 0",
                  }}
                />

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Prestation</TableCell>
                      <TableCell align="right">Nature</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell>{this._cree_chaine_typerest()}</TableCell>
                      <TableCell align="right">{this._cree_chaine_libintervention()}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                {this.state.INTERVEN_DEFUNT.length > 0 ? (
                  <div style={{ marginTop: 24 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Défunt</TableCell>
                          <TableCell align="right">Date de décès</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>{this.state.INTERVEN_DEFUNT.map((intervendefunt, index) => this._complete_table_defunt(intervendefunt, index))}</TableBody>
                    </Table>
                  </div>
                ) : null}

                {this.state.CONCESS !== undefined ? (
                  <div>
                    <hr
                      style={{
                        height: 3,
                        borderBottom: "1px solid grey",
                        borderTop: "1px solid grey",
                        margin: "24px 0",
                      }}
                    />

                    <CTFTPhotographieConstat
                      id_concess={this.state.CONCESS.COCLEUNIK}
                      id_intervention={this.state.INTERVEN.I0CLEUNIK}
                      date_travaux={new Date(this.state.INTERVEN.DATETRAVAUX)}
                      champ_modifie={this.state.champ_modifie}
                      MODIFIER_ref={this.MODIFIER_ref}
                    />
                  </div>
                ) : (
                  <Dialog
                    open={this.state.DIALOG_SANS_CONCESSION}
                    onClose={() => this.setState({ DIALOG_SANS_CONCESSION: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle onClose={() => this.setState({ DIALOG_SANS_CONCESSION: false })} id="alert-dialog-title">
                      <Typography variant="h6" component="p">
                        Attention
                      </Typography>

                      <IconButton aria-label="close" style={styles.closeButton} onClick={() => this.setState({ DIALOG_SANS_CONCESSION: false })}>
                        <Close />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Cette intervention n'est pas rattachée à une concession.
                        <br />
                        Il ne sera pas possible de renseigner les constats de travaux.
                      </DialogContentText>
                    </DialogContent>
                  </Dialog>
                )}
              </div>
            ) : null}

            <DialogAccessRight state={this.state.droit_util_modif_interven} onChange={(value) => this.setState({ droit_util_modif_interven: value })} />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withCookies(CTFicheTravaux);
