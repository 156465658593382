import { CreeTemporaire } from "../Function";

import AddTemporaire from "./../Add/Temporaire";
import AddPhotoconc from "./../Add/Photoconc";
import UpdatePhotoconc from "./../Update/Photoconc";
import { GetTemporaireNonEnvoye } from "../Get/Temporaire";
import UpdateTemporaire from "./../Update/Temporaire";

import no_photo from "../../Images/no_photo.json";
import { differenceObject } from "./../Test/Difference";
import { GetPhotoconcByI0cleunik } from "./../Get/Photoconc";

var _ = require("lodash");

function mise_a_jours_champs_pour_gescime(photo, oracle) {
  if (oracle) {
    for (var key in photo) {
      if (key === "IDPHOTOCONC") {
        photo.IDEVOL_ETAT = photo[key];
        delete photo[key];
      }

      if (key === "DATEAJOUT") {
        photo.DATE_EVOL = photo[key];
        delete photo[key];
      }

      if (key === "PRINCIPAL") {
        photo.REFERENTE = photo[key];
        delete photo[key];
      }

      if (key === "NOTES") {
        photo.ETAT_EVOL = photo[key];
        delete photo[key];
      }
    }
  }

  return photo;
}

async function signature_forme_gescime(photo, oracle) {
  if (photo.SIGNATURE_AVANT_AGENT !== undefined && photo.SIGNATURE_AVANT_AGENT !== null) {
    return photo.SIGNATURE_AVANT_AGENT.arrayBuffer().then((buffer_signature_avant_agent) => {
      photo.SIGNATURE_AVANT_AGENT = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_avant_agent)).toString("base64")}`;

      if (photo.SIGNATURE_AVANT_SOCIETE !== undefined && photo.SIGNATURE_AVANT_SOCIETE !== null) {
        return photo.SIGNATURE_AVANT_SOCIETE.arrayBuffer().then((buffer_signature_avant_societe) => {
          photo.SIGNATURE_AVANT_SOCIETE = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_avant_societe)).toString("base64")}`;

          return mise_a_jours_champs_pour_gescime(photo, oracle);
        });
      }
    });
  } else {
    if (photo.SIGNATURE_AVANT_SOCIETE !== undefined && photo.SIGNATURE_AVANT_SOCIETE !== null) {
      return photo.SIGNATURE_AVANT_SOCIETE.arrayBuffer().then((buffer_signature_avant_societe) => {
        photo.SIGNATURE_AVANT_SOCIETE = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_avant_societe)).toString("base64")}`;

        return mise_a_jours_champs_pour_gescime(photo, oracle);
      });
    } else {
      return mise_a_jours_champs_pour_gescime(photo, oracle);
    }
  }
}

async function photoconc_forme_gescime(photo, oracle) {
  if (photo.PHOTO !== "") {
    return await photo.PHOTO.arrayBuffer().then((buffer_photo) => {
      photo.PHOTO = `data:image/jpeg;base64,${Buffer.from(new Uint8Array(buffer_photo)).toString("base64")}`;

      if (photo.SIGNATURE_AGENT !== undefined && photo.SIGNATURE_AGENT !== null) {
        return photo.SIGNATURE_AGENT.arrayBuffer().then((buffer_signature_agent) => {
          photo.SIGNATURE_AGENT = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_agent)).toString("base64")}`;

          if (photo.SIGNATURE_SOCIETE !== undefined && photo.SIGNATURE_SOCIETE !== null) {
            return photo.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
              photo.SIGNATURE_SOCIETE = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;

              return mise_a_jours_champs_pour_gescime(photo, oracle);
            });
          } else {
            return mise_a_jours_champs_pour_gescime(photo, oracle);
          }
        });
      } else {
        if (photo.SIGNATURE_SOCIETE !== undefined && photo.SIGNATURE_SOCIETE !== null) {
          return photo.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
            photo.SIGNATURE_SOCIETE = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;

            return mise_a_jours_champs_pour_gescime(photo, oracle);
          });
        } else {
          return mise_a_jours_champs_pour_gescime(photo, oracle);
        }
      }
    });
  } else {
    if (photo.SIGNATURE_AGENT !== undefined && photo.SIGNATURE_AGENT !== null) {
      return await photo.SIGNATURE_AGENT.arrayBuffer().then((buffer_signature_agent) => {
        photo.SIGNATURE_AGENT = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_agent)).toString("base64")}`;

        if (photo.SIGNATURE_SOCIETE !== undefined && photo.SIGNATURE_SOCIETE !== null) {
          return photo.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
            photo.SIGNATURE_SOCIETE = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;

            return mise_a_jours_champs_pour_gescime(photo, oracle);
          });
        } else {
          return mise_a_jours_champs_pour_gescime(photo, oracle);
        }
      });
    } else {
      if (photo.SIGNATURE_SOCIETE !== undefined && photo.SIGNATURE_SOCIETE !== null) {
        return photo.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_signature_societe) => {
          photo.SIGNATURE_SOCIETE = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_signature_societe)).toString("base64")}`;

          return mise_a_jours_champs_pour_gescime(photo, oracle);
        });
      } else {
        return mise_a_jours_champs_pour_gescime(photo, oracle);
      }
    }
  }
}

export function ModifiePhotos_ConstatAbandon(PHOTOCONC_A_MODIFIER, ORACLE) {
  const sauvegarde = [...PHOTOCONC_A_MODIFIER];

  return new Promise((resolve, reject) => {
    if (PHOTOCONC_A_MODIFIER[0].length === 0) return resolve(true);

    _.forEach(PHOTOCONC_A_MODIFIER, (pam, i) => {
      _.set(pam, "ABANDON_1", false);
      _.set(pam, "ABANDON_2", false);

      UpdatePhotoconc(pam).then(() => {
        GetTemporaireNonEnvoye("PHOTOCONC", ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", pam.IDPHOTOCONC).then((temporaire) => {
          if (temporaire !== undefined) {
            _.set(temporaire.TABLE_MODIFIE_JSON, "ABANDON_1", false);
            _.set(temporaire.TABLE_MODIFIE_JSON, "ABANDON_2", false);

            UpdateTemporaire(temporaire).then(() => {
              if (i === sauvegarde.length - 1) return resolve(true);
            });
          } else {
            if (i === sauvegarde.length - 1) return resolve(true);
          }
        });
      });
    });
  });
}

function _PhotoBase64(PHOTOCONC) {
  if (PHOTOCONC === undefined)
    return new Promise((resolve, reject) => {
      return resolve("");
    });

  const p1 = new Promise((resolve, reject) => {
    if (PHOTOCONC.PHOTO !== "") {
      PHOTOCONC.PHOTO.arrayBuffer().then((buffer_photo) => {
        if (`${Buffer.from(new Uint8Array(buffer_photo)).toString("base64")}` === no_photo.image) {
          PHOTOCONC.PHOTO = "";
        } else {
          PHOTOCONC.PHOTO = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_photo)).toString("base64")}`;
        }

        if (PHOTOCONC.SIGNATURE_AGENT !== undefined && PHOTOCONC.SIGNATURE_AGENT !== null) {
          PHOTOCONC.SIGNATURE_AGENT.arrayBuffer().then((buffer_photo2) => {
            PHOTOCONC.SIGNATURE_AGENT = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_photo2)).toString("base64")}`;

            if (PHOTOCONC.SIGNATURE_SOCIETE !== null) {
              PHOTOCONC.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_photo3) => {
                PHOTOCONC.SIGNATURE_SOCIETE = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_photo3)).toString("base64")}`;

                return resolve(PHOTOCONC);
              });
            } else {
              return resolve(PHOTOCONC);
            }
          });
        } else if (PHOTOCONC.SIGNATURE_SOCIETE !== undefined && PHOTOCONC.SIGNATURE_SOCIETE !== null) {
          PHOTOCONC.SIGNATURE_SOCIETE.arrayBuffer().then((buffer_photo3) => {
            PHOTOCONC.SIGNATURE_SOCIETE = `data:image/png;base64,${Buffer.from(new Uint8Array(buffer_photo3)).toString("base64")}`;

            return resolve(PHOTOCONC);
          });
        } else {
          return resolve(PHOTOCONC);
        }
      });
    }
  });

  return p1.then((value) => {
    return value;
  });
}

function Creation_chaine_libelle(paa, oracle) {
  if (paa.CONSTAT_TRAVAUX > 0) {
    return "du constat de travaux";
  } else if (paa.ABANDON_1 === true || paa.ABANDON_2 === true) {
    return "du constat d’abandon";
  } else {
    return `de la photographie ${oracle ? paa.IDEVOL_ETAT : paa.IDPHOTOCONC}.jpg`;
  }
}

export function AjoutePhotos(PHOTOCONC_A_AJOUTER, PHOTOCONC_SAUVEGARDE, IS_ORACLE, IDCLIENT, ID_TABLETTE, ID_UTIL) {
  const sauvegarde = _.cloneDeep(PHOTOCONC_A_AJOUTER);
  const sauvegarde_s = _.cloneDeep(PHOTOCONC_SAUVEGARDE);

  return new Promise((resolve, reject) => {
    if (PHOTOCONC_A_AJOUTER.length === 0) return resolve(true);

    _.forEach(PHOTOCONC_A_AJOUTER, (paa, i) => {
      const PHOTOB64 = _PhotoBase64(_.cloneDeep(paa));

      PHOTOB64.then((photo64) => {
        const PHOTOSAUVEB64 = _PhotoBase64(_.cloneDeep(PHOTOCONC_SAUVEGARDE[i]));

        PHOTOSAUVEB64.then((photosauve64) => {
          let changed_photoconc = !_.isEqual(photo64, photosauve64);

          if (changed_photoconc) {
            let diff = differenceObject(photo64, photosauve64);

            diff = _.assign(
              {
                id: photo64.id,
                I0CLEUNIK: photo64.I0CLEUNIK,
                COCLEUNIK: photo64.COCLEUNIK,
                CONSTAT_TRAVAUX: photo64.CONSTAT_TRAVAUX,
                SIGNATURE_AGENT: photo64.SIGNATURE_AGENT,
                SIGNATURE_SOCIETE: photo64.SIGNATURE_SOCIETE,
              },
              diff
            );

            GetPhotoconcByI0cleunik(paa.I0CLEUNIK).then((photos) => {
              if (photos.length === 2) {
                let photo = _.filter(photos, (p) => {
                  return p.CONSTAT_TRAVAUX === 1;
                })[0];

                if (diff.CONSTAT_TRAVAUX === 2) {
                  if (photo !== undefined) {
                    if (photo.SIGNATURE_AGENT !== null) _.set(diff, "SIGNATURE_AVANT_AGENT", photo.SIGNATURE_AGENT);
                    if (photo.SIGNATURE_SOCIETE !== null) _.set(diff, "SIGNATURE_AVANT_SOCIETE", photo.SIGNATURE_SOCIETE);
                  }
                }
              }

              if (diff.id === undefined) {
                if (paa.DATEAJOUT === null) _.set(paa, "PHOTO", "");
                if (paa.NOTES === null || paa.NOTES === undefined) _.set(paa, "NOTES", "");
                if (diff.I0CLEUNIK === null || diff.I0CLEUNIK === undefined) _.set(diff, "I0CLEUNIK", "0");

                if (
                  paa.AGENT !== "" ||
                  paa.DATEAJOUT !== null ||
                  paa.MONUMENT_DERRIERE !== "RAS" ||
                  paa.MONUMENT_DEVANT !== "RAS" ||
                  paa.MONUMENT_DROITE !== "RAS" ||
                  paa.MONUMENT_ETAT !== "" ||
                  paa.MONUMENT_GAUCHE !== "RAS" ||
                  paa.NOTES !== "" ||
                  paa.PHOTO !== "" ||
                  paa.SEMELLE_ETAT !== "" ||
                  paa.SIGNATURE_AGENT !== null ||
                  paa.SIGNATURE_SOCIETE !== null ||
                  paa.SOCIETE !== ""
                ) {
                  const ajoute_photo = AddPhotoconc(paa);
                  ajoute_photo.then((id) => {
                    _.set(paa, "id", id);
                    _.set(paa, "IDPHOTOCONC", -id);

                    const modifie_photo = UpdatePhotoconc(paa);
                    modifie_photo.then(() => {
                      GetTemporaireNonEnvoye("PHOTOCONC", IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", paa.IDPHOTOCONC).then((temporaire) => {
                        photoconc_forme_gescime(paa, IS_ORACLE).then((paa) => {
                          if (paa.SIGNATURE_AGENT === null) delete paa["SIGNATURE_AGENT"];
                          if (paa.SIGNATURE_SOCIETE === null) delete paa["SIGNATURE_SOCIETE"];

                          if (paa.I0CLEUNIK !== null) {
                            GetPhotoconcByI0cleunik(paa.I0CLEUNIK).then((photos) => {
                              if (photos.length === 2) {
                                let photo = _.filter(photos, (p) => {
                                  return p.CONSTAT_TRAVAUX === 1;
                                })[0];

                                if (paa.CONSTAT_TRAVAUX === 2) {
                                  if (photo.SIGNATURE_AGENT !== null) _.set(paa, "SIGNATURE_AVANT_AGENT", photo.SIGNATURE_AGENT);
                                  if (photo.SIGNATURE_SOCIETE !== null) _.set(paa, "SIGNATURE_AVANT_SOCIETE", photo.SIGNATURE_SOCIETE);
                                }
                              }

                              signature_forme_gescime(paa, IS_ORACLE).then((paam) => {
                                if (temporaire !== undefined) {
                                  var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, paam);
                                  temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;

                                  UpdateTemporaire(temporaire).then(() => {
                                    if (i === sauvegarde.length - 1) return resolve(true);
                                  });
                                } else {
                                  const temporaire = CreeTemporaire(IDCLIENT, ID_TABLETTE, ID_UTIL, `Ajout ${Creation_chaine_libelle(paa, IS_ORACLE)}`, "PHOTOCONC", 1, paam, null);

                                  AddTemporaire(temporaire).then(() => {
                                    if (i === sauvegarde.length - 1) return resolve(true);
                                  });
                                }
                              });
                            });
                          } else {
                            if (temporaire !== undefined) {
                              var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, paa);

                              temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;
                              UpdateTemporaire(temporaire).then(() => {
                                if (i === sauvegarde.length - 1) return resolve(true);
                              });
                            } else {
                              const temporaire = CreeTemporaire(IDCLIENT, ID_TABLETTE, ID_UTIL, `Ajout ${Creation_chaine_libelle(paa, IS_ORACLE)}`, "PHOTOCONC", 1, paa, null);

                              AddTemporaire(temporaire).then(() => {
                                if (i === sauvegarde.length - 1) return resolve(true);
                              });
                            }
                          }
                        });
                      });
                    });
                  });
                } else {
                  if (i === sauvegarde.length - 1) return resolve(true);
                }
              } else {
                const modifie_photo = UpdatePhotoconc(paa);
                modifie_photo.then(() => {
                  GetTemporaireNonEnvoye("PHOTOCONC", IS_ORACLE ? "IDEVOL_ETAT" : "IDPHOTOCONC", paa.IDPHOTOCONC).then((temporaire) => {
                    if (diff.SIGNATURE_AGENT === null) delete diff["SIGNATURE_AGENT"];
                    if (diff.SIGNATURE_SOCIETE === null) delete diff["SIGNATURE_SOCIETE"];

                    if (paa.I0CLEUNIK !== null) {
                      GetPhotoconcByI0cleunik(paa.I0CLEUNIK).then((photos) => {
                        if (photos.length === 2) {
                          let photo = _.filter(photos, (p) => {
                            return p.CONSTAT_TRAVAUX === 1;
                          })[0];

                          if (diff.CONSTAT_TRAVAUX === 2) {
                            if (photo.SIGNATURE_AGENT !== null) _.set(diff, "SIGNATURE_AVANT_AGENT", photo.SIGNATURE_AGENT);
                            if (photo.SIGNATURE_SOCIETE !== null) _.set(diff, "SIGNATURE_AVANT_SOCIETE", photo.SIGNATURE_SOCIETE);
                          }
                        }

                        signature_forme_gescime(diff, IS_ORACLE).then((diffm) => {
                          if (temporaire !== undefined) {
                            var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, diffm);
                            temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;
                            temporaire.ETAT_INITIAL = _.filter(sauvegarde_s, (s) => {
                              return s.id === paa.id;
                            })[0];

                            UpdateTemporaire(temporaire).then(() => {
                              if (i === sauvegarde.length - 1) return resolve(true);
                            });
                          } else {
                            if (IS_ORACLE) {
                              if (diffm.IDEVOL_ETAT === undefined) _.set(diffm, "IDEVOL_ETAT", paa.IDPHOTOCONC);
                            } else {
                              if (diffm.IDPHOTOCONC === undefined) _.set(diffm, "IDPHOTOCONC", paa.IDPHOTOCONC);
                            }

                            const temporaire = CreeTemporaire(
                              IDCLIENT,
                              ID_TABLETTE,
                              ID_UTIL,
                              `Modification ${Creation_chaine_libelle(paa, IS_ORACLE)}`,
                              "PHOTOCONC",
                              2,
                              diffm,
                              _.filter(sauvegarde_s, (s) => {
                                return s.id === paa.id;
                              })[0]
                            );

                            AddTemporaire(temporaire).then(() => {
                              if (i === sauvegarde.length - 1) return resolve(true);
                            });
                          }
                        });
                      });
                    } else {
                      if (temporaire !== undefined) {
                        var miseajour_temporaire = _.assign({}, temporaire.TABLE_MODIFIE_JSON, diff);
                        miseajour_temporaire = mise_a_jours_champs_pour_gescime(miseajour_temporaire, IS_ORACLE);

                        temporaire.TABLE_MODIFIE_JSON = miseajour_temporaire;
                        temporaire.ETAT_INITIAL = _.filter(sauvegarde_s, (s) => {
                          return s.id === paa.id;
                        })[0];

                        UpdateTemporaire(temporaire).then(() => {
                          if (i === sauvegarde.length - 1) return resolve(true);
                        });
                      } else {
                        const temporaire = CreeTemporaire(
                          IDCLIENT,
                          ID_TABLETTE,
                          ID_UTIL,
                          `Modification ${Creation_chaine_libelle(paa, IS_ORACLE)}`,
                          "PHOTOCONC",
                          2,
                          diff,
                          _.filter(sauvegarde_s, (s) => {
                            return s.id === paa.id;
                          })[0]
                        );

                        AddTemporaire(temporaire).then(() => {
                          if (i === sauvegarde.length - 1) return resolve(true);
                        });
                      }
                    }
                  });
                });
              }
            });
          } else {
            if (i === sauvegarde.length - 1) return resolve(true);
          }
        });
      });
    });
  });
}
