import db from "../Db";

import { GetCouleurEmpl } from "../Get/Cimetier";
import LieuHistoAcces from "./../../Components/Global/Object/LieuHistoAcces";

class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

export async function CalculateCoordinatesPlanG(ORACLE, ZONE, NUMCIME, ZOOM, update) {
  const zoclenunik = ZONE.map((z) => {
    return z.ZOCLEUNIK;
  });

  if (!db.isOpen()) {
    db.open();
  }

  if (update) {
    const licleunik = LieuHistoAcces.getLieu().map((l) => {
      return l.LICLEUNIK;
    });

    return await db.LIEU.where("CIMEL")
      .equals(NUMCIME)
      .and((item) => licleunik.includes(item.LICLEUNIK))
      .toArray()
      .then(async (lieux) => {
        return await CoordinatePlanG(lieux, ORACLE, NUMCIME, ZOOM);
      });
  } else {
    return await db.LIEU.where("CIMEL")
      .equals(NUMCIME)
      .and((item) => zoclenunik.includes(item.SITUATION))
      .toArray()
      .then(async (lieux) => {
        return await CoordinatePlanG(lieux, ORACLE, NUMCIME, ZOOM);
      });
  }
}

async function CoordinatePlanG(LIEU, ORACLE, NUMCIME, ZOOM) {
  return await Promise.all(
    LIEU.map(async (lieu) => {
      const couleur = await GetCouleurEmpl(lieu);

      let tombe = lieu.EMPLACEMENT.TOMBE;

      while (tombe.charAt(0) === "0") {
        tombe = tombe.slice(1);
      }

      let IMG_Width, IMG_Height, BDD_Width, BDD_Height;
      let x = [],
        y = [];

      if (ORACLE) {
        IMG_Width = 1024;
        IMG_Height = 617;
        BDD_Width = 780;
        BDD_Height = 470;
      } else {
        IMG_Width = 1024;
        IMG_Height = 768;
        BDD_Width = 640;
        BDD_Height = 480;
      }

      let x1 = lieu.EMPLACEMENT.H1 * (IMG_Width / BDD_Width);
      let x2 = lieu.EMPLACEMENT.H2 * (IMG_Width / BDD_Width);
      let x3 = lieu.EMPLACEMENT.H3 * (IMG_Width / BDD_Width);
      let x4 = lieu.EMPLACEMENT.H4 * (IMG_Width / BDD_Width);
      let y1 = lieu.EMPLACEMENT.V1 * (IMG_Height / BDD_Height);
      let y2 = lieu.EMPLACEMENT.V2 * (IMG_Height / BDD_Height);
      let y3 = lieu.EMPLACEMENT.V3 * (IMG_Height / BDD_Height);
      let y4 = lieu.EMPLACEMENT.V4 * (IMG_Height / BDD_Height);

      x1 = x1 * 1000;
      x2 = x2 * 1000;
      x3 = x3 * 1000;
      x4 = x4 * 1000;
      y1 = y1 * 1000;
      y2 = y2 * 1000;
      y3 = y3 * 1000;
      y4 = y4 * 1000;

      let p1 = new Point(x1, y1);
      let p2 = new Point(x2, y2);
      let p3 = new Point(x3, y3);
      let p4 = new Point(x4, y4);

      let points = { p1, p2, p3, p4 };
      for (let p in points) {
        let point = points[p];

        x.push(point.x);
        y.push(point.y);
      }

      let XMin = Math.min.apply(null, x);
      let XMax = Math.max.apply(null, x);
      let YMin = Math.min.apply(null, y);
      let YMax = Math.max.apply(null, y);

      let border = (2 / ZOOM).toFixed(2);

      let taillePolice = 6;
      if (ORACLE === 0) {
        if (lieu.TAILLEPOLICEDULIEU === 2) {
          taillePolice = 7;
        }

        if (lieu.TAILLEPOLICEDULIEU === 3) {
          taillePolice = 8;
        }
      } else {
        taillePolice = lieu.TAILLEPOLICEDULIEU;
      }

      let tailleTexte = (taillePolice / ZOOM).toFixed(2);
      if (tailleTexte < 0.2) {
        tailleTexte = 0.2;
      }

      let text = {};
      let textX, textY;
      let tspanY = [],
        tspanText = [];

      if (tombe !== "") {
        textX = (XMax - XMin) / 2 + XMin;
        textY = (YMax - YMin) / 2 + YMin + (parseFloat(tailleTexte) * 1000) / 2;

        text.X = (textX / 1000).toFixed(3);
        text.Y = (textY / 1000).toFixed(3);
        text.SIZE = tailleTexte;
        text.TOMBE = tombe;

        if (lieu.POSNUM === 6) {
          textY = (YMax - YMin) / 2 + YMin;
          text.TRANSFORM = { Y: (textY / 1000).toFixed(3) };
        } else {
          if (tombe.includes("-") && lieu.AFFMULTILIGNE === 1) {
            let polyHeight = YMax / 1000 - YMin / 1000;
            let tombeArr = tombe.split("-");
            let textHeight = parseFloat(tailleTexte) * tombeArr.length;

            for (let j = 0; j < tombeArr.length; j++) {
              let ty = p1.y / 1000 + (textHeight / tombeArr.length) * j + (polyHeight - textHeight) / 2 + parseFloat(tailleTexte);

              tspanY.push(ty.toFixed(3));
              tspanText.push(tombeArr[j]);
            }

            text.TSPAN = { Y: tspanY, TEXT: tspanText };
          }
        }
      }

      return {
        NUMCIME: NUMCIME,
        POINTS: {
          P1: {
            X: (p1.x / 1000).toFixed(3),
            Y: (p1.y / 1000).toFixed(3),
          },
          P2: {
            X: (p2.x / 1000).toFixed(3),
            Y: (p2.y / 1000).toFixed(3),
          },
          P3: {
            X: (p3.x / 1000).toFixed(3),
            Y: (p3.y / 1000).toFixed(3),
          },
          P4: {
            X: (p4.x / 1000).toFixed(3),
            Y: (p4.y / 1000).toFixed(3),
          },
          BORDER: border,
        },
        LICLEUNIK: lieu.LICLEUNIK,
        COULEUR: couleur,
        TEXT: text,
        GELE: lieu.EMPLACEMENT.GELE,
        MONUMENTPRESENT: lieu.EMPLACEMENT.MONUMENTPRESENT,
        PASSE_PIED: lieu.EMPLACEMENT.PASSE_PIED,
        POSITIONSTELE: lieu.EMPLACEMENT.POSITIONSTELE,
      };
    })
  ).then((value) => {
    return value;
  });
}
