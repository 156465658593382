import { handleError } from "../Function";
import { GetAllInterven } from "../Get/Interven";
import { GetPhotoconcByI0cleunik } from "../Get/Photoconc";
import UpdatePhotoconc from "../Update/Photoconc";

var _ = require("lodash");

export function DeletePhotoForInterven() {
  return new Promise((resolve, reject) => {
    GetAllInterven().then((allInterven) => {
      allInterven = _.filter(allInterven, ["SUIVITRAV", 5]);

      if (allInterven.length === 0) resolve("");

      for (let i = 0; i < allInterven.length; i++) {
        const interven = allInterven[i];

        GetPhotoconcByI0cleunik(interven.I0CLEUNIK.toString()).then((photos) => {
          if (photos.length > 0) {
            for (let j = 0; j < photos.length; j++) {
              let photo = photos[j];

              if (photo.PRINCIPAL === 0) {
                photo.PHOTO = "";
                if (photo.SIGNATURE_AGENT !== undefined) photo.SIGNATURE_AGENT = null;
                if (photo.SIGNATURE_SOCIETE !== undefined) photo.SIGNATURE_SOCIETE = null;

                UpdatePhotoconc(photo)
                  .then(() => {
                    if (i === allInterven.length - 1) resolve("");
                  })
                  .catch((Err) => handleError(Err));
              } else {
                if (i === allInterven.length - 1) resolve("");
              }
            }
          } else {
            if (i === allInterven.length - 1) resolve("");
          }
        });
      }
    });
  });
}
