import db from "../Db";

import { GetAllCimetier } from "./../Get/Cimetier";
import { GetAllZone, GetFirstZone } from "./../Get/Zone";
import { CalculateCoordinatesPlanG } from "./../Calculate/CoordinatesPlanG";
import { IsOracle } from "./../Test/IsOracle";
import { bulkAddWithProgressForSvg, handleError } from "../Function";
import { GetSvgByIdLieu } from "../Get/Svg";
import LieuHistoAcces from "./../../Components/Global/Object/LieuHistoAcces";

var _ = require("lodash");

export async function CreateSvg(update) {
  const _cimetiers = await GetAllCimetier(0);
  const _oracle = await IsOracle();
  let index = 0;

  return await Promise.all(
    _cimetiers.map(async (cimetier) => {
      let zoom;
      const _firstZone = await GetFirstZone(cimetier.NUMCIME, 1, _oracle);

      if (_firstZone !== undefined) {
        let current = 1;
        let largeur = _firstZone.COORDONNEES.X2 - _firstZone.COORDONNEES.X1;
        let x2 = largeur;

        while (x2 < 640) {
          current++;
          x2 = x2 + largeur;
        }

        zoom = current;

        const _zone = await GetAllZone(cimetier.NUMCIME, 1);
        const drops = await CalculateCoordinatesPlanG(_oracle, _zone, cimetier.NUMCIME, zoom, update);

        return db
          .open()
          .then(() => {
            if (update) {
              let index = 0;

              _.forEach(drops, (d) => {
                GetSvgByIdLieu(d.LICLEUNIK).then((svg) => {
                  if (svg !== undefined) {
                    _.set(d, "id", svg.id);

                    db.open()
                      .then(() => {
                        db.SVG.put(d).catch((err) => {
                          return handleError(err);
                        });
                      })
                      .catch((err) => {
                        return handleError(err);
                      });
                  } else {
                    db.SVG.add(d).catch((err) => {
                      return handleError(err);
                    });
                  }
                });

                index++;
                if (index === drops.length - 1) {
                  LieuHistoAcces.resetLieu();

                  return "";
                }
              });
            } else {
              return bulkAddWithProgressForSvg(db.SVG, drops, 1000).then((res) => {
                console.log(`la sauvegarde des données svg pour le cimetière ${index + 1} est terminé`);

                index++;

                return "";
              });
            }
          })
          .catch((err) => {
            handleError(err);
          });
      } else {
        return "";
      }
    })
  ).then((value) => {
    value = value.map((v) => (v === undefined ? "" : v));

    if (_.uniq(value).length === 1) return "";
  });
}
