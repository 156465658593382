import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { withCookies } from "react-cookie";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  MenuItem,
  TableContainer,
  TablePagination,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { ExpandMore, LastPage, FirstPage, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";

import { GetAllInterven } from "./../../Helpers/Get/Interven";
import { GetAllConcess } from "./../../Helpers/Get/Concess";
import { GetAllCimetier } from "./../../Helpers/Get/Cimetier";
import { GetAllLieu } from "./../../Helpers/Get/Lieu";
import Opposition from "./../Global/OppositionTablette";
import { GetAllSociete } from "./../../Helpers/Get/Societe";

var _ = require("lodash");

const styles = {
  grid: {
    alignItems: "stretch",
    flex: 1,
    overflow: "auto",
  },
  grid_item: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
    paddingTop: 10,
  },
  paper: {
    flex: 1,
    padding: 16,
  },
  linkToSheet: {
    "&:hover": {
      backgroundColor: "red",
    },
  },
};

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div style={{ display: "flex" }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        <FirstPage />
      </IconButton>

      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        <LastPage />
      </IconButton>
    </div>
  );
}

class CTravaux extends Component {
  static propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xs"]).isRequired,
  };

  constructor(props) {
    super(props);

    this.CONCESS = [];
    this.LIEU = [];

    this.state = {
      INTERVEN: [],
      SELECTED_INTERVEN: [],
      CIRCULARLOADER: true,
      CIMETIER: [],
      SOCIETE: [],
      SELECTED_CIMETIER: 0,
      SELECTED_SUIVI: 3,
      REDIRECT: null,
      listeInterven: 0,
      rowsPerPage: 10,
      currentPage: 0,
    };

    props.changeTitle(props.title);
  }

  componentDidMount = () => {
    let cleApi = this.props.cookies.get(encodeURIComponent(btoa("APIKEY")));
    Opposition(cleApi);

    GetAllConcess().then((concess) => {
      this.CONCESS = concess;
    });

    GetAllCimetier().then((cimetier) => {
      this.setState({ CIMETIER: cimetier });
    });

    GetAllLieu().then((lieu) => {
      this.LIEU = lieu;
    });

    GetAllInterven().then((interven) => {
      let interv = _.orderBy(interven, "DATETRAVAUX", "desc");

      let currentInterv = _.filter(interv, (i) => {
        return i.SUIVITRAV === this.state.SELECTED_SUIVI;
      });

      this.setState({
        INTERVEN: interv,
        SELECTED_INTERVEN: currentInterv,
        CIRCULARLOADER: false,
      });
    });

    GetAllSociete().then((societe) => {
      this.setState({ SOCIETE: societe });
    });
  };

  _cree_chaine_famille = (id) => {
    let CONCESS = _.find(this.CONCESS, { COCLEUNIK: id });

    if (CONCESS !== undefined) {
      return CONCESS.FAMILLE;
    } else {
      return "";
    }
  };

  _cree_chaine_suivi_travaux = (suivi) => {
    switch (suivi) {
      case 2:
        return "En attente";
      case 3:
        return "En cours";
      case 4:
        return "Suspendu";
      case 5:
        return "Terminé";
      default:
        return "Inconnu";
    }
  };

  _cree_chaine_intervenant_type = (interven) => {
    switch (interven.INTERVENANT_TYPE) {
      case 2:
        let nom = "";

        if (interven.INTERVENANT_NOM !== "") {
          nom = interven.INTERVENANT_NOM;
        } else {
          let soc = _.find(this.state.SOCIETE, { SOCLEUNIK: interven.SOCLEUNIK });

          if (soc !== undefined) nom = soc.NOMSOCIETE;
        }

        return nom;
      case 3:
        return "Particulier";
      default:
        return "Collectivité";
    }
  };

  _cree_chaine_emplacement = (interven) => {
    var empl = "";

    let LIEU = _.find(this.LIEU, ["LICLEUNIK", interven.LICLEUNIK]);

    if (LIEU !== undefined && LIEU !== "") {
      if (LIEU.EMPLACEMENT.CARRE.includes("Provisoire") || LIEU.EMPLACEMENT.CARRE.includes("Non affect")) {
        let CONCESS = _.find(this.CONCESS, ["COCLEUNIK", interven.COCLEUNIK]);

        if (CONCESS !== undefined && CONCESS !== "") {
          empl = CONCESS.EMPL;
        }
      } else {
        empl = _.filter([
          _.filter([LIEU.EMPLACEMENT.TYPECAR, LIEU.EMPLACEMENT.CARRE]).join(" "),
          _.filter([LIEU.EMPLACEMENT.TYPERG, LIEU.EMPLACEMENT.RANG]).join(" "),
          _.filter([LIEU.EMPLACEMENT.TYPEFOS, LIEU.EMPLACEMENT.TOMBE]).join(" "),
        ]).join(" - ");

        const reg = /^(.*)\/(.*) (\d+)\/(\d+)(.*)$/;

        if (reg.test(empl)) {
          const TYPESEC = LIEU.EMPLACEMENT.TYPECAR.split("/")[0];
          const SECTION = LIEU.EMPLACEMENT.CARRE.split("/")[0];
          const TYPECAR = LIEU.EMPLACEMENT.TYPECAR.split("/")[1];
          const CARRE = LIEU.EMPLACEMENT.CARRE.split("/")[1];

          empl = _.filter([
            _.filter([TYPESEC, SECTION]).join(" "),
            _.filter([TYPECAR, CARRE]).join(" "),
            _.filter([LIEU.EMPLACEMENT.TYPERG, LIEU.EMPLACEMENT.RANG]).join(" "),
            _.filter([LIEU.EMPLACEMENT.TYPEFOS, LIEU.EMPLACEMENT.TOMBE]).join(" "),
          ]).join(" - ");
        }
      }
    }

    return empl;
  };

  _handle_change_combo_listeInterven = (event) => {
    let interv = this.state.INTERVEN;

    if (event.target.value !== 0) {
      interv = _.filter(interv, (i) => {
        return new Date(i.DATETRAVAUX) === new Date(Date.now());
      });
    }

    if (this.state.SELECTED_CIMETIER !== 0) {
      interv = _.filter(interv, (i) => {
        return i.TYPECIME === this.state.SELECTED_CIMETIER;
      });
    }

    interv = _.filter(interv, (i) => {
      return i.SUIVITRAV === this.state.SELECTED_SUIVI;
    });

    this.setState({
      SELECTED_INTERVEN: interv,
      CIRCULARLOADER: false,
      listeInterven: event.target.value,
      currentPage: 0,
    });
  };

  _handle_change_combo_cimetier = (event) => {
    let interv = this.state.INTERVEN;

    if (event.target.value !== 0) {
      interv = _.filter(interv, (i) => {
        return i.TYPECIME === event.target.value;
      });
    }

    if (this.state.listeInterven !== 0) {
      interv = _.filter(interv, (i) => {
        return new Date(i.DATETRAVAUX) === new Date(Date.now());
      });
    }

    interv = _.filter(interv, (i) => {
      return i.SUIVITRAV === this.state.SELECTED_SUIVI;
    });

    this.setState({
      SELECTED_INTERVEN: interv,
      CIRCULARLOADER: false,
      SELECTED_CIMETIER: event.target.value,
      currentPage: 0,
    });
  };

  _handle_change_combo_suivi = (event) => {
    let interv = this.state.INTERVEN;

    interv = _.filter(interv, (i) => {
      return i.SUIVITRAV === event.target.value;
    });

    if (this.state.listeInterven !== 0) {
      interv = _.filter(interv, (i) => {
        return new Date(i.DATETRAVAUX) === new Date(Date.now());
      });
    }

    if (this.state.SELECTED_CIMETIER !== 0) {
      interv = _.filter(interv, (i) => {
        return i.TYPECIME === this.state.SELECTED_CIMETIER;
      });
    }

    this.setState({
      SELECTED_INTERVEN: interv,
      CIRCULARLOADER: false,
      SELECTED_SUIVI: event.target.value,
      currentPage: 0,
    });
  };

  _handle_change_tablepage = (event, newPage) => {
    this.setState({ currentPage: newPage });
  };

  _handle_change_tablerowperpage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, currentPage: 0 });
  };

  render() {
    console.log("RENDER TRAVAUX");

    const { width } = this.props;

    if (this.state.REDIRECT !== null) {
      return <Redirect to={`/consulter/fiche-travaux/${this.state.REDIRECT}`} push />;
    }

    return (
      <Grid container alignItems="baseline" justify="center" style={styles.grid}>
        <Grid item xs={11} style={styles.grid_item}>
          <Paper style={styles.paper}>
            <Typography variant="h6" gutterBottom style={{ marginBottom: 24 }}>
              Liste des interventions
            </Typography>

            <TextField select fullWidth margin="normal" label="Interventions" value={this.state.listeInterven} onChange={this._handle_change_combo_listeInterven}>
              <MenuItem value={0}>Toutes les interventions</MenuItem>
              <MenuItem value={1}>Interventions du jour</MenuItem>
            </TextField>

            <TextField select fullWidth margin="normal" label="Cimetière" value={this.state.SELECTED_CIMETIER} onChange={this._handle_change_combo_cimetier}>
              <MenuItem value={0}>Indifférent</MenuItem>
              {this.state.CIMETIER.map((cimetier, index) => (
                <MenuItem key={index} value={cimetier.NUMCIME}>
                  {cimetier.NOMCIME}
                </MenuItem>
              ))}
            </TextField>

            <TextField select fullWidth margin="normal" label="Interventions" value={this.state.SELECTED_SUIVI} onChange={this._handle_change_combo_suivi}>
              <MenuItem value={1}>Inconnu</MenuItem>
              <MenuItem value={2}>En attente</MenuItem>
              <MenuItem value={3}>En cours</MenuItem>
              <MenuItem value={4}>Suspendu</MenuItem>
              <MenuItem value={5}>Terminé</MenuItem>
            </TextField>

            {this.state.CIRCULARLOADER ? <CircularProgress style={{ position: "absolute", left: "50%", right: "50%", top: "50%", bottom: "50%" }} /> : null}

            {width === "xs" || width === "sm" ? (
              <TableContainer component={Paper} style={{ marginTop: 24 }}>
                {this.state.SELECTED_INTERVEN.slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage).map(
                  (interven, index) => (
                    <Accordion key={index} elevation={0}>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="body2" gutterBottom style={{ flex: 1 }}>
                          {this._cree_chaine_famille(interven.COCLEUNIK)}
                        </Typography>

                        <Typography
                          variant="body2"
                          gutterBottom
                          className="HoverLink"
                          onClick={(event) => {
                            event.preventDefault();

                            this.setState({
                              REDIRECT: interven.I0CLEUNIK,
                            });
                          }}
                        >
                          Voir la fiche
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails
                        onClick={() =>
                          this.setState({
                            REDIRECT: interven.I0CLEUNIK,
                          })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>Date d'exécution</TableCell>
                              <TableCell align="right">{!_.includes(interven.DATETRAVAUX, "1900-01-01") ? `${new Date(interven.DATETRAVAUX).toLocaleDateString("fr-FR")}` : null}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>Nature</TableCell>
                              <TableCell align="right">{interven.EMPL_NATURE}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>Suivi</TableCell>
                              <TableCell align="right">{this._cree_chaine_suivi_travaux(interven.SUIVITRAV)}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>Intervenant</TableCell>
                              <TableCell align="right">{this._cree_chaine_intervenant_type(interven)}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>Cimetière</TableCell>
                              <TableCell align="right">
                                {
                                  _.find(this.state.CIMETIER, {
                                    id: interven.TYPECIME,
                                  }).NOMCIME
                                }
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>Emplacement</TableCell>
                              <TableCell align="right">{this._cree_chaine_emplacement(interven)}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </AccordionDetails>
                    </Accordion>
                  )
                )}
              </TableContainer>
            ) : (
              <TableContainer style={{ marginTop: 24 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date d'exécution</TableCell>
                      <TableCell>Famille</TableCell>
                      <TableCell>Nature</TableCell>
                      <TableCell>Suivi</TableCell>
                      <TableCell>Intervenant</TableCell>
                      <TableCell>Cimetière</TableCell>
                      <TableCell>Emplacement</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.state.SELECTED_INTERVEN.slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage).map(
                      (interven, index) => (
                        <TableRow
                          key={index}
                          hover={true}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.setState({
                              REDIRECT: interven.I0CLEUNIK,
                            })
                          }
                        >
                          <TableCell>{!_.includes(interven.DATETRAVAUX, "1900-01-01") ? `${new Date(interven.DATETRAVAUX).toLocaleDateString("fr-FR")}` : ""}</TableCell>

                          <TableCell>{this._cree_chaine_famille(interven.COCLEUNIK)}</TableCell>

                          <TableCell>{interven.EMPL_NATURE}</TableCell>
                          <TableCell>{this._cree_chaine_suivi_travaux(interven.SUIVITRAV)}</TableCell>

                          <TableCell>{this._cree_chaine_intervenant_type(interven)}</TableCell>

                          <TableCell>
                            {
                              _.find(this.state.CIMETIER, {
                                NUMCIME: interven.TYPECIME,
                              }).NOMCIME
                            }
                          </TableCell>

                          <TableCell>{this._cree_chaine_emplacement(interven)}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <TablePagination
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              colSpan={3}
              count={this.state.SELECTED_INTERVEN.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.currentPage}
              labelRowsPerPage="Nombre de lignes"
              labelDisplayedRows={({ from, to, count }) =>
                width !== "xs" && width !== "sm" ? (count > 0 ? `De ${from} à ${to === -1 ? count : to} sur ${count !== -1 ? count : to} résultats` : `Aucun résultat`) : null
              }
              onChangePage={this._handle_change_tablepage}
              onChangeRowsPerPage={this._handle_change_tablerowperpage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withWidth()(withCookies(CTravaux));
