var LieuHistoAcces = (function () {
  var lieu = [];

  var getLieu = function () {
    return lieu;
  };

  var setLieu = function (currentLieu) {
    lieu.push(currentLieu);
  };

  var resetLieu = function () {
    lieu = [];
  };

  return {
    getLieu: getLieu,
    setLieu: setLieu,
    resetLieu: resetLieu,
  };
})();

export default LieuHistoAcces;
